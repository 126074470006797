import { useState, ChangeEventHandler, RefObject } from "react";
import { RefCallBack } from "react-hook-form";
import { TextInput } from "./TextInput";
import Icon from "../Icon";
import PasswordStrengthIndicator from "../PasswordStrengthIndicator/PasswordStrengthIndicator";

export interface PasswordInputProps {
  id?: string;
  label?: string;
  placeholder?: string;
  hint?: string;
  required?: boolean;
  disabled?: boolean;
  description?: string;
  validationError?: string;
  value?: string;
  inputRef?: RefObject<HTMLInputElement> | RefCallBack;
  withIndicator?: boolean;
  dataPrAt?: string;
  invalid?: boolean;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

/** Text input for passwords */
export const PasswordInput = ({
  id,
  label = "",
  placeholder = "",
  hint = "",
  required = false,
  disabled = false,
  description = "",
  validationError = undefined,
  value = "",
  inputRef,
  withIndicator,
  dataPrAt,
  invalid,
  onChange = () => {},
}: PasswordInputProps) => {
  const [type, setType] = useState<"password" | "text">("password");

  return (
    <>
      <TextInput
        id={id}
        dataPrAt={dataPrAt}
        inputRef={inputRef}
        type={type}
        label={label}
        hint={hint}
        placeholder={placeholder}
        required={required}
        disabled={disabled}
        description={description}
        validationError={validationError}
        invalid={invalid}
        value={value}
        onChange={onChange}
        iconLabel={type === "password" ? "show password" : "hide password"}
        renderIcon={() =>
          type === "password" ? (
            <Icon name="eye" size={24} />
          ) : (
            <Icon name="eye-off" size={24} />
          )
        }
        iconPosition="right"
        onIconClick={
          disabled
            ? undefined
            : () => {
                type === "password" ? setType("text") : setType("password");
              }
        }
      />
      {withIndicator && <PasswordStrengthIndicator value={value} />}
    </>
  );
};
export default PasswordInput;
