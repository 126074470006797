import { useEffect, useRef } from "react";
import classNames from "classnames";
import { Calendar } from "primereact/calendar";
import Tooltip from "../Tooltip/Tooltip";
import Icon from "../Icon";

import inputStyles from "../Inputs/Input.module.css";
import styles from "./DateTime.module.css";

export interface TimeInputProps {
  /** Moment.js compatible date format. It is converted to primereact format to use with Calendar. */
  dateFormat?: string;
  label?: string;
  placeholder?: string;
  hint?: string;
  required?: boolean;
  disabled?: boolean;
  description?: string;
  validationError?: string;
  invalid?: boolean;
  value?: Date;
  yearNavigator?: boolean;
  monthNavigator?: boolean;
  minDate?: Date;
  maxDate?: Date;
  yearRange?: string;
  readonly?: boolean;
  onChange?: (date: Date | null) => void;
  showTime?: boolean;
  style?: any;
}

// Note: add new mappings if you need
// https://momentjs.com/docs/#/displaying/format/
// https://primefaces.org/primereact/showcase/#/calendar
const momentToPrimereactFormatMapping: any = {
  "YYYY-MM-DD": "yy-mm-dd", // e.g. 2021-05-25
  "MM-DD-YYYY": "mm-dd-yy", // e.g. 05-25-2021
  "DD-MM-YYYY": "dd-mm-yy", // e.g. 25-05-2021
};

/** Date picker with ability to enter time. */
export const DatePicker = ({
  dateFormat = "YYYY-MM-DD",
  label = "",
  placeholder = "",
  hint = undefined,
  required = false,
  disabled = false,
  description = "",
  validationError = undefined,
  invalid = false,
  value = undefined,
  yearNavigator = false,
  monthNavigator = false,
  yearRange,
  readonly = false,
  minDate = undefined,
  maxDate = undefined,
  showTime = false,
  style = {},
  onChange = () => {},
}: TimeInputProps) => {
  placeholder = placeholder || dateFormat;

  const inputRef = useRef<HTMLInputElement>(null);
  const calendarRef = useRef<Calendar>(null);
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        // because of glitch PrimeReact outside click we do it manually
        !calendarRef.current
          ?.getOverlay()
          ?.contains(event.target as HTMLElement) &&
        !calendarRef.current
          ?.getElement()
          ?.contains(event.target as HTMLElement)
      ) {
        calendarRef.current?.hide();
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [calendarRef.current]);

  // validate format
  if (!momentToPrimereactFormatMapping[dateFormat]) {
    throw new Error(
      `Date format ${dateFormat} not allowed. Available formats: ${Object.keys(
        momentToPrimereactFormatMapping
      )}`
    );
  }
  const primereactDateFormat = momentToPrimereactFormatMapping[dateFormat];

  return (
    <div
      className={classNames({
        [inputStyles.inputContainer]: true,
        [styles.DatePickerContainer]: true,
      })}
    >
      {/* label row */}
      <div className={inputStyles.labelRow}>
        {required && <span className={inputStyles.requiredIcon}>*</span>}
        <label>{label}</label>
        {hint ? (
          <Tooltip
            trigger={() => (
              <span
                className={classNames({
                  [inputStyles.labelHint]: true,
                })}
              >
                <Icon name="information-circle" size={16} />
              </span>
            )}
            position="right center"
            text={hint}
          />
        ) : null}
      </div>

      {/* input row */}
      <div
        className={classNames({
          [inputStyles.inputRow]: true,
          [inputStyles.inputRowIconLeft]: true,
        })}
      >
        {/* left icon */}
        <span
          className={classNames({
            [inputStyles.inputIcon]: true,
            [inputStyles.inputIconLeft]: true,
            [inputStyles.inputIconClickable]: false,
            [inputStyles.inputIconDisabled]: disabled,
          })}
          aria-label="Pick date"
        >
          <Icon name="calendar" size={24} color="Grey-400" />
        </span>

        {/* input */}
        <Calendar
          ref={calendarRef}
          inputRef={inputRef}
          style={style}
          showTime={showTime}
          disabled={disabled}
          placeholder={placeholder}
          dateFormat={primereactDateFormat}
          value={value}
          monthNavigator={monthNavigator}
          yearNavigator={yearNavigator}
          readOnlyInput={readonly}
          minDate={minDate}
          maxDate={maxDate}
          yearRange={yearRange}
          inputClassName={classNames({
            [inputStyles.inputValidationError]: invalid || !!validationError,
          })}
          onChange={(e) => {
            // null means invalid time
            let nextDate: Date | null;
            if (e.value === null) {
              nextDate = null;
            } else {
              nextDate = e.value as Date;
            }
            onChange(nextDate);
          }}
        />
      </div>

      {/* Other */}
      <div className={inputStyles.description}>{description}</div>
      {validationError ? (
        <div className={inputStyles.validationError}>{validationError}</div>
      ) : null}
    </div>
  );
};
export default DatePicker;
