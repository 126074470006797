import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { observer } from "mobx-react-lite";
import moment from "moment";
import { getErrorKey } from "../../../../components/Errors/ErrorAlert";
import { createInteraction } from "../../../../core/api/interactions/interactions";
import { PeriodicInteractionDetails } from "../../../../core/api/interactions/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import useUser from "../../../../core/user/useUser";
import { notify } from "../../../../ui-lib/components/Alerts/Toast";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";
import { useTimezones } from "../../../../core/api/administrators/administrators";
import PrimeModal from "../../../../ui-lib/components/PrimeModal/PrimeModal";
import { arrayToWeeks, useActionTypeOptions, useSummary } from "./helpers";
import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import Dropdown from "../../../../ui-lib/components/Dropdown/Dropdown";
import NumberInput from "../../../../ui-lib/components/Inputs/NumberInput";
import { InteractionTimeControl } from "./InteractionTimeControl";
import Checkbox from "../../../../ui-lib/components/Checkbox/Checkbox";
import DaySelector from "../../../../ui-lib/components/Tags/DaySelector";
import { omit } from "lodash";
import RadioGroup from "../../../../ui-lib/components/Radio/RadioGroup";

export type PeriodicInteractionFormValues = Omit<
  PeriodicInteractionDetails,
  | "onMonday"
  | "onTuesday"
  | "onWednesday"
  | "onThursday"
  | "onFriday"
  | "onSaturday"
  | "onSunday"
> & { days: number[] };

const CreateInteractionModal = ({
  isOpen,
  objectId,
  onClose,
  onAdd,
}: {
  isOpen: boolean;
  objectId: string;
  onClose: () => void;
  onAdd: () => void;
}) => {
  const t = useTranslations();
  const { authenticatedRequest, config } = useUser();
  const { data: timezones, isLoading: isTimezoesLoading } = useTimezones();
  const actionTypeOptions = useActionTypeOptions();
  const [formTitle, setFormTitle] = useState(actionTypeOptions[0].title);

  const {
    control,
    formState: { isSubmitting, errors, isValid },
    watch,
    setValue,
    getValues,
    trigger,
    handleSubmit,
  } = useForm<PeriodicInteractionFormValues>({
    defaultValues: {
      type: actionTypeOptions.length > 0 ? actionTypeOptions[0].id : undefined,
      days: [],
      hour: undefined,
      minute: undefined,
      timezone: config?.timezone.timezoneName ?? undefined,
    },
  });

  const timezoneItems = timezones
    ? timezones.map((timezone) => timezone.timezoneName)
    : [];
  const summary = useSummary({ formValues: watch(), isValid });
  const interactionType = watch("type");

  const onSubmit = async (interactionDetails: PeriodicInteractionDetails) => {
    try {
      await createInteraction(
        objectId,
        interactionDetails,
        authenticatedRequest
      );
      notify({
        message: t("Messages:create_interaction_success"),
      });
      onAdd();
      onClose();
    } catch (error) {
      const errorKey = getErrorKey(error);
      notify({
        message: t(`Errors:${errorKey}`),
        variant: "error",
      });
    }
  };

  return (
    <PrimeModal
      withHeader
      withFooter
      header={t("Objects:interaction_create")}
      contentStyle={{ width: "848px" }}
      isOpen={isOpen}
      onClose={onClose}
      loading={isSubmitting}
      submitBtn={{
        text: t("Objects:interaction_create"),
        onClick: handleSubmit(async (formValues) => {
          const values = omit(
            {
              ...arrayToWeeks(formValues.days),
              ...formValues,
            },
            "days"
          );

          await onSubmit(values as PeriodicInteractionDetails);
        }),
      }}
      cancelBtn={{ text: t("Common:cancel"), onClick: onClose }}
    >
      <p style={{ color: "var(--Grey-600)" }}>{formTitle}</p>

      <Spacer size={32} />
      {isTimezoesLoading ? (
        <LoadingSpinner theme="primary" />
      ) : (
        <form
          onSubmit={handleSubmit(async (formValues) => {
            const values = omit(
              {
                ...arrayToWeeks(formValues.days),
                ...formValues,
              },
              "days"
            );

            await onSubmit(values as PeriodicInteractionDetails);
          })}
        >
          <Controller
            name="type"
            control={control}
            render={({ field }) => {
              const selectedItem = actionTypeOptions.find(
                (i) => i.id === field.value
              );

              return (
                <RadioGroup
                  label={t("Objects:periodic_interactions_type_label")}
                  name={field.name}
                  items={actionTypeOptions}
                  selectedValue={selectedItem?.value}
                  onChange={(value) => {
                    const item = actionTypeOptions.find(
                      (o) => o.value === value
                    );

                    if (item) {
                      setValue(field.name, item.id);
                      trigger(field.name);
                      setFormTitle(item.title);
                      if (item.id === 2 || item.id === 100) {
                        setValue("maxResponseTime", 1);
                      }
                    }
                  }}
                />
              );
            }}
          />

          <Spacer size={16} />

          <Row align="center">
            <Column
              align="start"
              style={{
                width: "100%",
                flex: "auto",
              }}
            >
              <Controller
                name="days"
                control={control}
                rules={{ validate: (value) => !!value.length }}
                render={({ field }) => (
                  <DaySelector
                    label={t("Common:days")}
                    values={field.value}
                    setValues={(updated) => {
                      setValue(field.name, updated);
                      trigger("days");
                    }}
                    validationError={errors.days && t("Errors:input_format")}
                  />
                )}
              />
            </Column>
            <Spacer size={32} />
            <Column
              align="start"
              style={{
                width: "100%",
                flex: "auto",
              }}
            >
              <Controller
                name="continueNextWeek"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    label={t("Objects:periodic_interactions_repeat_label")}
                    checked={field.value}
                    onChange={field.onChange}
                    style={{ padding: 0 }}
                  />
                )}
              />
            </Column>
          </Row>

          <Spacer size={16} />

          <Row align="start">
            <InteractionTimeControl watch={watch} setValue={setValue} />
            {interactionType !== 2 && interactionType !== 100 && (
              <>
                <Spacer size={32} />
                <Controller
                  name="maxResponseTime"
                  control={control}
                  rules={{ required: true, min: 1, max: 240 }}
                  render={({ field }) => (
                    <NumberInput
                      required
                      label={t(
                        `Objects:${
                          getValues("type") === 1
                            ? "camera_be_view"
                            : "periodic_interactions_max_response"
                        }`
                      )}
                      placeholder="1...240"
                      pinnedText="min"
                      pinnedTextPosition="right"
                      value={`${field.value}`}
                      onChange={(e, value) => {
                        setValue("maxResponseTime", +value);
                        trigger("maxResponseTime");
                      }}
                      validationError={
                        errors.maxResponseTime && t("Errors:input_format")
                      }
                    />
                  )}
                />
              </>
            )}
          </Row>

          <Spacer size={16} />

          <Row align="start">
            <Column style={{ width: "100%" }}>
              <Controller
                name="timezone"
                control={control}
                rules={{ required: true }}
                render={({ field }) => {
                  const dropdownItems = timezoneItems.map((timezone) => {
                    const formattedTimezone = moment()
                      .tz(timezone)
                      .format()
                      .split("+");
                    return {
                      id: timezone,
                      name: `${timezone} ${
                        formattedTimezone.length > 1
                          ? `+${formattedTimezone[1]}`
                          : ""
                      }`,
                      isSelected: timezone === field.value,
                    };
                  });

                  return (
                    <Dropdown
                      required
                      title={t("Objects:periodic_interactions_timezone")}
                      width="100%"
                      selectedItem={dropdownItems.find((i) => i.isSelected)}
                      items={dropdownItems}
                      onSelectItem={(item) => {
                        setValue(field.name, item.id as string);
                        trigger(field.name);
                      }}
                      validationError={
                        errors.timezone && t("Errors:input_field_required")
                      }
                    />
                  );
                }}
              />
            </Column>
            <Spacer size={32} />
            <Column style={{ width: "100%" }} />
          </Row>

          {summary && (
            <>
              <Spacer size={16} />
              <span className="small" style={{ color: "var(--Grey-600)" }}>
                {summary}
              </span>
            </>
          )}
        </form>
      )}
    </PrimeModal>
  );
};

export default observer(CreateInteractionModal);
