import { Controller } from "react-hook-form";
import moment from "moment";
import { Row, Spacer } from "../../../components/Layout/Layout";
import {
  useObjectForm,
  useObjectFormRef,
} from "../../../core/SaveObjectContext/hooks";
import { IResellerDetails } from "../../../core/api/sellers/types";
import {
  validateEmailIfExists,
  validateEmergencyPhoneNumberIfExists,
  validatePhoneNumberIfExists,
  validatePriceValue,
  validateSnsArn,
} from "../../../core/helpers/validation";

import TextInput from "../../../ui-lib/components/Inputs/TextInput";
import useTranslations from "../../../core/i18n/useTranslations";
import Checkbox from "../../../ui-lib/components/Checkbox/Checkbox";
import Divider from "../../../ui-lib/components/Divider/Divider";

export const ResellerGeneralForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues?: IResellerDetails;
  onSubmit: (values: IResellerDetails) => Promise<void>;
}) => {
  const t = useTranslations();
  const formRef = useObjectFormRef();

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    trigger,
    setValue,
    watch,
  } = useObjectForm<IResellerDetails>({
    defaultValues,
    shouldUnregister: true,
  });

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit(async (values) => {
        await onSubmit(values);
        reset(values);
      })}
      style={{ width: "100%", maxWidth: "768px" }}
    >
      <Row>
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <TextInput
              required
              inputRef={field.ref}
              label={t("Common:name_label")}
              value={field.value}
              onChange={(e) => {
                setValue(field.name, e.target.value);
                trigger(field.name);
              }}
              validationError={errors?.name && t("Errors:input_field_required")}
            />
          )}
        />
        <Spacer size={32} />
        <Controller
          name="organizationNumber"
          control={control}
          render={({ field }) => (
            <TextInput
              label={t("Organizations:organization_number")}
              placeholder={t("Common:digit_number")}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </Row>

      <Spacer size={32} />
      <div>
        <span style={{ color: "var(--Grey-800)", fontWeight: 600 }}>
          {t("Common:label_registered_at")}
        </span>{" "}
        <span>
          {moment(defaultValues?.registrationTime).format("DD MMMM YYYY")}
        </span>
      </div>
      <Spacer size={32} />

      <Row align="start">
        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            <TextInput
              label={t("Filters:Street")}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Spacer size={32} />
        <Controller
          name="postalCode"
          control={control}
          rules={{ maxLength: 10 }}
          render={({ field }) => (
            <TextInput
              inputRef={field.ref}
              label={t("Common:labels_postal_code")}
              placeholder="000 00"
              value={field.value}
              onChange={field.onChange}
              validationError={
                errors.postalCode &&
                t("Errors:length_to_long").replace("{0}", "10")
              }
            />
          )}
        />
      </Row>

      <Spacer size={16} />

      <Row>
        <Controller
          name="city"
          control={control}
          render={({ field }) => (
            <TextInput
              label={t("Common:labels_city")}
              placeholder={t("Common:enter_city")}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Spacer size={32} />
        <Controller
          name="country"
          control={control}
          render={({ field }) => (
            <TextInput
              label={t("Common:labels_country")}
              placeholder={t("Common:enter_country")}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </Row>

      <Spacer size={16} />

      <Row>
        <Controller
          name="branding"
          control={control}
          render={({ field }) => (
            <TextInput
              label={t("Common:branding")}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Spacer size={32} />
        <Controller
          name="brand"
          control={control}
          render={({ field }) => (
            <TextInput
              label={t("Common:brand")}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </Row>

      <Spacer size={16} />

      <Row align="start">
        <Controller
          name="currency"
          rules={{ maxLength: 3 }}
          control={control}
          render={({ field }) => (
            <TextInput
              inputRef={field.ref}
              label={t("Common:currency")}
              value={field.value}
              onChange={field.onChange}
              validationError={
                errors.currency &&
                t("Errors:length_to_long").replace("{0}", "3")
              }
            />
          )}
        />
        <Spacer size={32} />
        <Controller
          name="defaultPrice"
          control={control}
          rules={{ validate: (e) => validatePriceValue(e?.toString()) }}
          render={({ field }) => (
            <TextInput
              inputRef={field.ref}
              label={t("Administrator:default_price_lable")}
              value={field.value?.toString()}
              onChange={({ currentTarget }) => {
                if (!currentTarget.value) {
                  setValue(field.name, null);
                } else {
                  field.onChange(currentTarget.value);
                }
              }}
              validationError={errors.defaultPrice && t("Errors:price_format")}
            />
          )}
        />
      </Row>

      <Spacer size={16} />

      <Row align="start">
        <Controller
          name="integrationSnsArn"
          control={control}
          rules={{ validate: validateSnsArn }}
          render={({ field }) => (
            <TextInput
              inputRef={field.ref}
              label={t("Common:labels_sns_arn")}
              placeholder={t("Organizations:sns_arn_placeholder")}
              value={field.value}
              onChange={(val) => {
                if (!val.currentTarget.value.length) {
                  setValue("integrationAdditionalInfo", null);
                }
                field.onChange(val.currentTarget.value);
              }}
              validationError={
                errors.integrationSnsArn && t("Errors:sns_arn_wrong_format")
              }
            />
          )}
        />
        <Spacer size={32} />
        <Controller
          name="integrationAdditionalInfo"
          control={control}
          render={({ field }) => (
            <TextInput
              label={t(
                "AlarmReceptions:advanced_settings_integrationadditionalinfo_label"
              )}
              value={field.value ?? ""}
              onChange={(val) => {
                if (!watch("integrationSnsArn")) {
                  setValue("integrationAdditionalInfo", "");
                }
                field.onChange(val.currentTarget.value);
              }}
              disabled={!watch("integrationSnsArn")}
            />
          )}
        />
      </Row>

      <Spacer size={32} />

      <Row align="start" type="left">
        <Controller
          name="forceRcAlarmTrigger"
          control={control}
          render={({ field }) => (
            <Checkbox
              label={t("Administrator:force_alarm_trigger")}
              checked={field.value}
              onChange={field.onChange}
              style={{ padding: 0, width: "100%" }}
            />
          )}
        />
        <Spacer size={32} />
        <Controller
          name="settingConnectServiceDefault"
          control={control}
          render={({ field }) => (
            <Checkbox
              label={t("Administrator:setting_connect_service_default")}
              checked={field.value}
              onChange={field.onChange}
              style={{ padding: 0, width: "100%" }}
            />
          )}
        />
      </Row>

      <Spacer size={32} />
      <Divider />
      <Spacer size={32} />

      <h2>{t("Common:contact_information")}</h2>
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Administrator:contact_information_description")}
      </p>

      <Spacer size={16} />

      <Row>
        <Controller
          name="contactPerson"
          control={control}
          render={({ field }) => (
            <TextInput
              label={t("Common:contact_person")}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Spacer size={32} />
        <Controller
          name="emailAddress"
          control={control}
          rules={{ validate: validateEmailIfExists }}
          render={({ field }) => (
            <TextInput
              inputRef={field.ref}
              label={t("Common:email")}
              placeholder={t("Common:placeholder_email")}
              value={field.value}
              onChange={field.onChange}
              validationError={
                errors.emailAddress && t("Errors:input_email_format")
              }
            />
          )}
        />
      </Row>

      <Spacer size={16} />

      <Row align="start">
        <Controller
          name="telephoneNumber1"
          control={control}
          rules={{
            validate: (value) =>
              validateEmergencyPhoneNumberIfExists(value) ||
              validatePhoneNumberIfExists(value),
          }}
          render={({ field }) => (
            <TextInput
              inputRef={field.ref}
              label={t("Common:labels_phone_number")}
              placeholder={t("Common:input_phone_description")}
              value={field.value}
              onChange={field.onChange}
              validationError={
                errors.telephoneNumber1 &&
                t("Errors:ValidationHint_TelephoneNumber")
              }
            />
          )}
        />
        <Spacer size={32} />
        <Controller
          name="telephoneNumber2"
          control={control}
          rules={{
            validate: (value) =>
              validateEmergencyPhoneNumberIfExists(value) ||
              validatePhoneNumberIfExists(value),
          }}
          render={({ field }) => (
            <TextInput
              inputRef={field.ref}
              label={`${t("Common:labels_phone_number")} 2`}
              placeholder={t("Common:input_phone_description")}
              value={field.value}
              onChange={field.onChange}
              validationError={
                errors.telephoneNumber2 &&
                t("Errors:ValidationHint_TelephoneNumber")
              }
            />
          )}
        />
      </Row>

      <Spacer size={32} />
      <Divider />
      <Spacer size={32} />

      <h2>{t("Menu:Support")}</h2>
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Administrator:support_description")}
      </p>

      <Spacer size={16} />

      <Row align="start">
        <Controller
          name="supportPhone"
          control={control}
          rules={{
            validate: (value) =>
              validateEmergencyPhoneNumberIfExists(value) ||
              validatePhoneNumberIfExists(value),
          }}
          render={({ field }) => (
            <TextInput
              inputRef={field.ref}
              label={t("Common:support_phone_number_lable")}
              placeholder={t("Common:input_phone_description")}
              value={field.value}
              onChange={field.onChange}
              validationError={
                errors.supportPhone &&
                t("Errors:ValidationHint_TelephoneNumber")
              }
            />
          )}
        />
        <Spacer size={32} />
        <Controller
          name="supportEmail"
          control={control}
          rules={{ validate: validateEmailIfExists }}
          render={({ field }) => (
            <TextInput
              inputRef={field.ref}
              label={t("Common:support_email_lable")}
              placeholder={t("Common:placeholder_email")}
              value={field.value}
              onChange={field.onChange}
              validationError={
                errors.supportEmail && t("Errors:input_email_format")
              }
            />
          )}
        />
      </Row>

      <Spacer size={16} />

      <Row align="start">
        <Controller
          name="supportTechEmail"
          control={control}
          rules={{ validate: validateEmailIfExists }}
          render={({ field }) => (
            <TextInput
              inputRef={field.ref}
              label={t("Common:support_technical_email")}
              placeholder={t("Common:placeholder_email")}
              value={field.value}
              onChange={field.onChange}
              validationError={
                errors.supportTechEmail && t("Errors:input_email_format")
              }
            />
          )}
        />
        <Spacer size={32} />
        <Controller
          name="supportPhoneEmergency"
          control={control}
          rules={{
            validate: (value) =>
              validateEmergencyPhoneNumberIfExists(value) ||
              validatePhoneNumberIfExists(value),
          }}
          render={({ field }) => (
            <TextInput
              inputRef={field.ref}
              label={t("Common:support_telephone_emergency")}
              placeholder={t("Common:input_phone_description")}
              value={field.value}
              onChange={field.onChange}
              validationError={
                errors.supportPhoneEmergency &&
                t("Errors:ValidationHint_TelephoneNumber")
              }
            />
          )}
        />
      </Row>
      <Spacer size={36} />
    </form>
  );
};
