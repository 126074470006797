import { useState, useRef, useEffect } from "react";
import moment from "moment";
import classNames from "classnames";
import { Calendar } from "primereact/calendar";
import Tooltip from "../Tooltip/Tooltip";
import Icon from "../Icon";
import inputStyles from "../Inputs/Input.module.css";
import DateTimeHelper from "../../../core/helpers/dateTimeHelper";

import styles from "./DateTime.module.css";

export interface TimeInputProps {
  hourFormat?: "24" | "12";
  label?: string;
  placeholder?: string;
  hint?: string;
  required?: boolean;
  disabled?: boolean;
  description?: string;
  validationError?: string;
  invalid?: boolean;
  value?: string;
  onChange?: (time?: string, dateWithTime?: Date) => void;
  css?: any;
}

/** Time picker with ability to enter time. Returns time as string. */
export const TimePicker = ({
  hourFormat = "24",
  label = "",
  placeholder = "",
  hint = undefined,
  required = false,
  disabled = false,
  description = "",
  validationError = undefined,
  invalid = false,
  value = "",
  onChange = () => {},
  css,
}: TimeInputProps) => {
  const timeFormat = hourFormat === "24" ? "HH:mm" : "hh:mm a";
  placeholder = placeholder || timeFormat;

  const inputRef = useRef<HTMLInputElement>(null);
  const calendarRef = useRef<Calendar>(null);

  const [isInputActive, setIsInputActive] = useState(false);
  const [inputData, setInputData] = useState<Date>();

  useEffect(() => {
    // convert passed value to date fro input
    if (value && value !== "") {
      const timeComponents = DateTimeHelper.getTimeComponentsFromTimeString(
        value,
        timeFormat
      );
      const inputDate = moment(new Date())
        .set({
          hour: timeComponents.hours,
          minutes: timeComponents.minutes,
          seconds: 0,
          milliseconds: 0,
        })
        .toDate();
      setInputData(inputDate);
    } else {
      setInputData(undefined);
    }
  }, [value]);

  return (
    <div
      className={classNames({
        [inputStyles.inputContainer]: true,
        [styles.timePickerContainer]: true,
      })}
    >
      {/* label row */}
      <div className={inputStyles.labelRow}>
        {required && <span className={inputStyles.requiredIcon}>*</span>}
        <label>{label}</label>
        {hint ? (
          <Tooltip
            trigger={() => (
              <span
                className={classNames({
                  [inputStyles.labelHint]: true,
                })}
              >
                <Icon name="information-circle" size={16} />
              </span>
            )}
            position="right center"
            text={hint}
          />
        ) : null}
      </div>

      {/* input row */}
      <div
        className={classNames({
          [inputStyles.inputRow]: true,
          [styles.inputValidationError]: invalid,
          [inputStyles.inputRowIconLeft]: true,
          [inputStyles.inputRowIconRight16]: true,
        })}
      >
        {/* left icon */}
        <span
          className={classNames({
            [inputStyles.inputIcon]: true,
            [inputStyles.inputIconLeft]: true,
            [inputStyles.inputIconClickable]: false,
            [inputStyles.inputIconDisabled]: disabled,
          })}
          aria-label="Pick time"
        >
          <Icon name="clock" size={24} color="grey" />
        </span>

        {/* right icon */}
        <button
          tabIndex={-1}
          className={classNames({
            [inputStyles.inputIcon]: true,
            [inputStyles.inputIcon16]: true,
            [inputStyles.inputIconRight]: true,
            [inputStyles.inputIconClickable]: true,
            [inputStyles.inputIconDisabled]: disabled,
          })}
          aria-label="Pick time"
          onClick={() =>
            isInputActive
              ? calendarRef.current?.hide()
              : calendarRef.current?.show()
          }
          data-cy="timepicker_dropdown_btn"
        >
          <Icon
            name={isInputActive ? "chevron-up" : "chevron-down"}
            size={16}
            color="grey"
          />
        </button>

        {/* input */}
        <Calendar
          ref={calendarRef}
          timeOnly
          showTime
          style={css}
          disabled={disabled}
          hourFormat={hourFormat}
          showSeconds={false}
          showMillisec={false}
          placeholder={placeholder}
          value={inputData}
          inputRef={inputRef}
          inputClassName={classNames({
            [inputStyles.inputValidationError]: !!validationError,
          })}
          onChange={(e) => {
            // null means invalid time
            if (!e.value) {
              onChange(undefined, undefined);
            } else {
              const nextDate = e.value as Date;
              const nextTime = moment(nextDate).format(timeFormat);

              onChange(nextTime, nextDate);
              setInputData(e.value);
            }
          }}
          onShow={() => {
            setIsInputActive(true);
            inputRef.current?.focus();
          }}
          onHide={() => setIsInputActive(false)}
          onBlur={() => calendarRef.current?.hide()}
        />
      </div>

      {/* Other */}
      <div className={inputStyles.description}>{description}</div>
      {validationError ? (
        <div className={inputStyles.validationError}>{validationError}</div>
      ) : null}
    </div>
  );
};
export default TimePicker;
