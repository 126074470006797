import { useEffect, useRef } from "react";
import classNames from "classnames";
import Icon from "../Icon";
import { Row, Spacer } from "../../../components/Layout/Layout";
import styles from "./PageHeader.module.css";
import { breakpoints } from "../../../core/hooks/dimensionProvider";
import ResponsiveLayout from "../../../components/ResponsiveLayout";
import usePageState from "../../../core/pagestate/usePageState";
import useTranslations from "../../../core/i18n/useTranslations";

export interface PageHeaderProps {
  title?: any;
  subTitle?: string;
  icon?: string;
  children?: React.ReactNode;
  pageTitle?: boolean;
  status?: string;
}
const PageHeader = ({
  title,
  subTitle,
  icon,
  children,
  pageTitle = false,
  status,
}: PageHeaderProps) => {
  const isMounted = useRef(false);
  const t = useTranslations();
  const pageState = usePageState();

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  return (
    <>
      <Row type="fill" className={styles.pageHeader}>
        <ResponsiveLayout
          breakPoint={breakpoints.tablet}
          renderMobile={() => (
            <>
              <Spacer size={10} />
              <Icon
                name="bars"
                size={24}
                color="Grey-500"
                className="clickable"
                onClick={() => {
                  if (isMounted.current)
                    pageState.setShowNavigation(!pageState.showNavigation);
                }}
              />
            </>
          )}
          renderDesktop={() => null}
        />
        {icon && (
          <Icon
            name={icon}
            size={24}
            color="Primary-700"
            className={styles.pageIcon}
          />
        )}
        {title && pageTitle && (
          <h1 className={styles.pageTitle} style={{ width: "100%" }}>
            {title}
          </h1>
        )}
        {title && !pageTitle && (
          <div
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <h2
              style={!icon ? { margin: "24px" } : undefined}
              className={styles.pageTitle}
            >
              {title}
            </h2>
            {status && (
              <h2
                style={{
                  color: "#cc0000",
                  marginLeft: "8px",
                  margin: !icon ? "24px" : "",
                }}
              >
                ({t(status)})
              </h2>
            )}
          </div>
        )}

        <Row
          type="right"
          className={styles.actionContainer}
          style={{ width: "100%" }}
        >
          {children}
        </Row>
      </Row>
      {subTitle && (
        <>
          <Row
            type="fill"
            className={classNames({
              [styles.pageHeader]: true,
              [styles.pageSubtitle]: true,
            })}
          >
            <p>{subTitle}</p>
          </Row>
          <Spacer size={10} />
        </>
      )}
    </>
  );
};
export default PageHeader;
