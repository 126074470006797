import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import moment from "moment";
import { toSafeInteger } from "lodash";
import DateFilterDropdown, {
  useDateDropdownFilter,
} from "../../components/DateFilter/DateFilterDropdown";
import ExportObjectsSettings from "../../components/ExportModal/ExportObjectsSettings";
import TableSearch from "../../components/FilterSearch/TableSearch";
import { Column, Row, Spacer } from "../../components/Layout/Layout";
import {
  BaseColumnsProps,
  BaseTableDataResponse,
  FilterData,
  getTableDataWithoutHook,
  postSelectedTableColumns,
  useBaseFilters,
  useTableColumns,
  useTableData,
} from "../../core/api/common_table";
import { useOrganizationsTree } from "../../core/api/organizations/organizations";
import { sortParamsToString } from "../../core/helpers/helpers";
import { useOrganisationFilter } from "../../core/hooks/filters/useOrganisationFilter";
import { usePageFilter } from "../../core/hooks/filters/usePageFilter";
import { useSearchFilter } from "../../core/hooks/filters/useSearchFilter";
import { useSortParams } from "../../core/hooks/filters/useSortParams";
import {
  useStoredTableState,
  storeTableState,
} from "../../core/hooks/filters/useStoredTableState";
import useTranslations from "../../core/i18n/useTranslations";
import usePageState from "../../core/pagestate/usePageState";
import Button from "../../ui-lib/components/Button/Button";
import Divider from "../../ui-lib/components/Divider/Divider";
import Tree from "../../ui-lib/components/Hierarchy/Tree";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import PageHeader from "../../ui-lib/components/PageHeader/PageHeader";
import {
  ABSENCE_ON_OBJECT,
  CustomFilters,
  LINKED_OBJECT_ID,
} from "../../ui-lib/components/Tables/CustomFilters";
import styles from "../Layout.module.css";
import { AddObject, ConfigurationAddObjectActions } from "./AddObject";
import { ObjectsTable } from "./ObjectsTable";
import {
  MoreState,
  useMoreFilter,
} from "../../core/hooks/filters/useMoreFilter";
import { searchStringToFilterMap } from "../../ui-lib/utils/customFilters";
import { ClearFilter } from "../../ui-lib/components/Tables/CleatFilters";
import { urlSearchParser } from "../../ui-lib/utils/urlSearchParser";
import useUser from "../../core/user/useUser";
import { useCustomersFilter } from "../../core/hooks/filters/useCustomersFilter";
import { Switch } from "../../ui-lib/components/Switch/Switch";
import TableDataControlButton from "../../ui-lib/components/Tables/TableDataControlButton";
import PrimeModal from "../../ui-lib/components/PrimeModal/PrimeModal";
import { ExactMatchTogller } from "../../components/ExactMatchTogller";
import { Role, RoleFeature } from "../../components/RoleFeature";

export const PAGE_ID = "adminportal/objects";
const PAGE_URL = "api/v2/users";

// This component is massive and complex, we should probably split it up. Also it has many similarities with AlarmHistory.
const Objects = () => {
  const user = useUser();
  const t = useTranslations();
  const pageState = usePageState();
  const { data: treeData } = useOrganizationsTree();

  const history = useHistory();
  const tableState = useStoredTableState(PAGE_ID);
  const searchState = urlSearchParser(tableState);

  const addObjectModalRef = useRef<ConfigurationAddObjectActions>(null);

  const viserPermission = user.config?.show.includes("SensioHideFields");

  // Table settings
  const { searchFilter, setSearchFilter, resetSearchFilter } =
    useSearchFilter(searchState);
  const {
    dateType,
    setDateType,
    dateFilter,
    setDateFilter,
    resetDateSettings,
  } = useDateDropdownFilter({ searchState });
  const { sortParams, setSortParams } = useSortParams(searchState);
  const { pageFilter, setPageFilter, resetPageFilter } =
    usePageFilter(searchState);
  const { customersFilter, setCustomersFilter, resetCustomersFilter } =
    useCustomersFilter(searchState);
  const { resetMoreFilter } = useMoreFilter(searchState);
  const organisationFilter = useOrganisationFilter(customersFilter, treeData);

  const [exportModalOpen, setExportModalOpen] = useState(false);

  // Table columns
  const [selectedColumns, setSelectedColumns] = useState<BaseColumnsProps>();
  const { data: allObjectsColumns, isLoading: allColumnsLoading } =
    useTableColumns(PAGE_URL, false);
  const { data: selectedObjectsColumns, isLoading: selectedColumnsLoading } =
    useTableColumns(PAGE_URL, true);

  // Custom filters
  const [customFilters, setCustomFilters] = useState<FilterData[]>();
  const [, setUnsavedCustomFilters] = useState<MoreState>({});
  const clearTableSelection = useRef<Function>();
  const { data: objectsFilters } = useBaseFilters(PAGE_URL);

  const specialFilters = [
    {
      id: 110,
      keyName: "UserQueryFilterPostalCode",
      name: "PostalCode",
    },
    {
      id: 109,
      keyName: "UserQueryFilterAddressStreet",
      name: "Street",
    },
  ];
  const filterTabs = [
    {
      id: 0,
      isSelected: ![109, 110].includes(toSafeInteger(searchFilter.id)),
      name: "Normal",
    },
    {
      id: 1,
      isSelected: [109, 110].includes(toSafeInteger(searchFilter.id)),
      name: "Special",
    },
  ];

  const availableFilters = useCallback(() => {
    const otherFilters: FilterData[] = [];
    if (searchFilter?.id) otherFilters.push(searchFilter as FilterData);
    if (organisationFilter) otherFilters.push(organisationFilter);

    const allFilters: FilterData[] = [...otherFilters];
    if (customFilters) allFilters.push(...customFilters);

    clearTableSelection.current?.();

    return {
      // 4 - is max amount of filters we can pass to back-end
      amount: 4 - otherFilters.length,
      available:
        objectsFilters?.filter(
          (el) => !allFilters.find((item) => item.id === el.id)
        ) || [],
      notAvailable: allFilters,
    };
  }, [searchFilter, customFilters, objectsFilters, customersFilter])();

  // Data fetch
  const [tableData, setTableData] = useState<BaseTableDataResponse>();

  const fetchParams = {
    start: dateFilter.start
      ? moment(dateFilter.start).format("YYYY-MM-DDTHH:mm:ssZ")
      : "",
    end: dateFilter.end
      ? moment(dateFilter.end).format("YYYY-MM-DDTHH:mm:ssZ")
      : "",
    sort: sortParamsToString(sortParams),
    page: pageFilter?.page,
    pageSize: pageFilter?.pageSize,
    customer: organisationFilter,
    filter: customFilters,
    organizationIds: [],
    // uncomment when it will be available on back-end
    // exact: searchFilter.exact,
  };

  const { data, isLoading, isError } = useTableData(
    fetchParams,
    PAGE_URL,
    searchFilter
  );

  const tableParams = {
    filter: customFilters,
    customer: organisationFilter,
    sort: sortParamsToString(sortParams),
    customers: customersFilter.customers,
    start: dateFilter.start?.toISOString(),
    end: dateFilter.end?.toISOString(),
  };

  const resetTablePage = () => {
    setPageFilter({
      page: 1,
      pageSize: pageFilter.pageSize,
    });
  };

  useEffect(() => {
    setCustomFilters(searchStringToFilterMap(tableState ?? ""));
  }, []);

  // Need for the UI not rerender table after setState
  useLayoutEffect(() => {
    if (data) {
      setTableData(data);
    }
  }, [data]);

  const reFetchData = async () => {
    const { data: updated } = await getTableDataWithoutHook(
      fetchParams,
      PAGE_URL,
      searchFilter
    );

    if (updated) {
      setTableData(updated);
    }
  };

  // Effects
  const onClear = () => {
    resetSearchFilter();
    resetDateSettings();
    resetPageFilter();
    resetCustomersFilter();
    resetMoreFilter();
    setCustomFilters(undefined);
    setUnsavedCustomFilters({});
    history.push({ search: "page=1&pageSize=10" });
  };

  useEffect(() => {
    if (selectedObjectsColumns && !selectedColumns)
      setSelectedColumns(selectedObjectsColumns);
  }, [selectedObjectsColumns, selectedColumns]);

  useEffect(() => {
    if ([109, 110].includes(toSafeInteger(searchFilter.id))) {
      pageState.setIsSpecialSearch(true);
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem(
      "prevPath",
      JSON.stringify({
        path: history.location.pathname,
        pathName: t("Menu:objects"),
      })
    );
  }, []);

  const onSelectedColumnChange = async (columns: BaseColumnsProps) => {
    await postSelectedTableColumns(PAGE_URL, columns);
    setSelectedColumns(columns);

    await reFetchData();
  };

  storeTableState(PAGE_ID);

  const isFiltersApplied =
    customersFilter.customers ||
    searchFilter.value ||
    (searchFilter.id !== -1 && searchFilter.id !== 110) ||
    dateFilter.start ||
    customFilters?.length;

  const filterColumns = (obj: BaseColumnsProps) => {
    if (!obj) return;
    const filteredColumns: { [key: number]: string } = {};
    for (const [key, value] of Object.entries(obj.columns)) {
      if (value === "Name" || value === "AlarmHandling") {
        filteredColumns[Number(key)] = value;
      }
    }
    return filteredColumns;
  };

  return (
    <>
      <PrimeModal
        isOpen={exportModalOpen}
        onClose={() => setExportModalOpen(false)}
      >
        <ExportObjectsSettings
          labels={{
            title: t("Objects:export"),
            description: t("Objects:all"),
          }}
          tableParams={tableParams}
          searchFilter={searchFilter}
          columnInfo={selectedColumns?.columns}
          onClose={() => setExportModalOpen(false)}
        />
      </PrimeModal>
      <PageHeader title={t("Menu:objects")} icon={pageState.pageIcon}>
        {!viserPermission && (
          <>
            {user.config?.show.some((key) =>
              [
                "M:Users/Add",
                "ConfigureCareAlarms",
                "ConfigureOrdinaryHousingAlarms",
                "ConfigurePropertyAlarms",
                "ConfigureResponseAlarms",
                "ConfigureShelteredHousingAlarms",
                "ConfigurePeripheralAlarms",
              ].includes(key)
            ) && (
              <>
                <Button
                  variant="primary"
                  text={t("Objects:addobject")}
                  loading={isLoading}
                  disabled={isLoading}
                  onClick={() => {
                    addObjectModalRef.current?.open();
                  }}
                  dataCy="add_object_btn"
                />
                <AddObject
                  ref={addObjectModalRef}
                  onSubmit={(objectId: number) =>
                    history.push(`/adminportal/objects/${objectId}`)
                  }
                  organisations={treeData}
                />
              </>
            )}
            {user.config?.show.includes("Reports") && (
              <div>
                <Button
                  variant="secondary"
                  text={t("Common:export")}
                  onClick={() => setExportModalOpen(true)}
                  dataCy="export_objects_btn"
                />
              </div>
            )}
          </>
        )}
      </PageHeader>
      <Divider />
      <Column className={styles.wrapper}>
        <Column className={styles.content}>
          <Row className={styles.settingsRow}>
            <TableSearch
              placeholder={
                searchFilter?.id !== -1
                  ? t("Objects:search_placeholder_generic")
                  : t("Objects:search_placeholder")
              }
              dropdownPlaceholder={t("Objects:dropdown_search_placeholder")}
              searchFilter={searchFilter}
              setSearchFilter={setSearchFilter}
              types={([109, 110].includes(toSafeInteger(searchFilter.id))
                ? specialFilters
                : objectsFilters?.filter(
                    (i) =>
                      i.id !== LINKED_OBJECT_ID && i.id !== ABSENCE_ON_OBJECT
                  )
              ) // Remove linked object filter in this dropdown
                ?.map((i) => ({
                  id: i.id,
                  name: t(`Filters:${i.name}`),
                  disabled: !!availableFilters.notAvailable.find(
                    (el) => el.id === i.id
                  ),
                }))}
              disabled={!searchFilter && !availableFilters.amount}
              resetPageFilter={resetTablePage}
              withDefaultSearch={!pageState.isSpecialSearch}
            />
            <RoleFeature requires={[Role.BetaAccess]}>
              <Spacer size={16} />
              <ExactMatchTogller
                searchFilter={searchFilter}
                setSearchFilter={setSearchFilter}
              />
            </RoleFeature>

            {user.config?.show.includes("ObjectsSpecialSearch") && (
              <Switch
                tabs={filterTabs}
                onTabChange={(id: number) => {
                  pageState.setIsSpecialSearch(!!id);
                  onClear();
                  setSearchFilter({
                    id: pageState.isSpecialSearch ? 110 : -1,
                    value: "",
                  });
                }}
              />
            )}
          </Row>
          <Spacer size={16} />
          <Divider />
          <Spacer size={16} />
          <Row className={styles.settingsRow}>
            {treeData.length > 0 ? (
              <>
                <Tree
                  withCheckboxTootip
                  label={t("Common:select_organisation")}
                  placeholder={`--${t("Common:organisation")}--`}
                  hideLabel
                  className={styles.treeFilter}
                  disabled={!organisationFilter && !availableFilters.amount}
                  items={treeData}
                  selectedTreeItem={customersFilter.customers}
                  onSelectItem={(ids: string) => {
                    if (!ids) {
                      resetCustomersFilter();
                    } else {
                      setCustomersFilter({ customers: ids });
                    }
                    // Do not reset page filters if value same as previous
                    if (customersFilter.customers !== ids) resetTablePage();
                  }}
                  selectionMode="multiple"
                  showClearOption={false}
                />
                <Spacer size={8} />
              </>
            ) : null}
            {!viserPermission && (
              <>
                <DateFilterDropdown
                  placeholder={`--${t("Objects:date_filter_placeholder")}--`}
                  dateType={dateType}
                  setDateType={setDateType}
                  dateFilter={dateFilter}
                  setDateFilter={(dateData) => {
                    setDateFilter(dateData);
                    // Do not reset page filters if value same as previous
                    if (
                      dateData.start?.getDate() !==
                        dateFilter.start?.getDate() &&
                      dateData.end?.getDate() !== dateFilter.end?.getDate()
                    ) {
                      resetTablePage();
                    }
                  }}
                  searchState={searchState}
                />
                <Spacer size={8} />
              </>
            )}
            {objectsFilters && (
              <CustomFilters
                filterColumns={objectsFilters.filter(
                  (el) => el.id !== 3 && el.id !== 4 // should be removed from the backend side
                )}
                notAvailableFilters={availableFilters.notAvailable}
                filters={customFilters}
                maxFiltersAmount={availableFilters.amount}
                applyFilters={setCustomFilters}
                translate={(col) => t(`Filters:${col.name}`)}
                tableState={searchState}
                position="bottom left"
                setUnsavedCustomFilters={setUnsavedCustomFilters}
                resetPageFilter={resetTablePage}
                disableDropdowns={pageState.isSpecialSearch}
              />
            )}
            <Spacer size={8} />
            <ClearFilter
              text={t("Common:labels_clear_all_filters")}
              onClearClick={onClear}
              filtersToWatch={[
                customersFilter,
                searchFilter,
                dateFilter,
                customFilters,
              ]}
              propertiesToExclude={{
                id: searchFilter.id === 110 ? 110 : -1,
                exact: searchFilter.exact === "true" ? undefined : "false",
              }}
            />
            {!viserPermission &&
              !isLoading &&
              !isError &&
              !allColumnsLoading &&
              !selectedColumnsLoading &&
              tableData && (
                <TableDataControlButton
                  allTableColumns={
                    !viserPermission
                      ? allObjectsColumns
                      : ({
                          columns: filterColumns(allObjectsColumns),
                        } as BaseColumnsProps)
                  }
                  selectedTableColumns={selectedColumns}
                  onVisibleColumnsChange={onSelectedColumnChange}
                />
              )}
          </Row>
          <Spacer size={16} />
          <Divider />
          <Column className={styles.tableContainer} type="top">
            {(isLoading && !isError) ||
            allColumnsLoading ||
            selectedColumnsLoading ||
            !tableData ? (
              <LoadingSpinner theme="primary" />
            ) : (
              <ObjectsTable
                data={tableData}
                pageSettings={pageFilter}
                isFiltersApplied={!!isFiltersApplied}
                onPageSettingsChange={setPageFilter}
                sortParams={sortParams}
                onSortChange={setSortParams}
                allTableColumns={allObjectsColumns}
                selectedTableColumns={selectedColumns}
                onColumnChange={onSelectedColumnChange}
                onClear={onClear}
                onDataChanged={reFetchData}
                parentClearSelection={clearTableSelection}
                tableParams={{ ...tableParams, searchFilter: searchFilter }}
              />
            )}
            <Spacer size={150} />
          </Column>
        </Column>
      </Column>
    </>
  );
};

export default Objects;
