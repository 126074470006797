/* eslint-disable react/jsx-props-no-spreading */
import _ from "lodash";
import { Controller, FormProvider } from "react-hook-form";
import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import { OrganisationPicker } from "../../../../components/OrganisationPicker";
import { ResponseCenterDetails } from "../../../../core/api/responsecenters/types";
import { validateEmailIfExists } from "../../../../core/helpers/validation";
import useTranslations from "../../../../core/i18n/useTranslations";
import {
  useObjectForm,
  useObjectFormRef,
} from "../../../../core/SaveObjectContext/hooks";
import Checkbox from "../../../../ui-lib/components/Checkbox/Checkbox";
import Divider from "../../../../ui-lib/components/Divider/Divider";
import Dropdown from "../../../../ui-lib/components/Dropdown/Dropdown";
import { IItemProp } from "../../../../ui-lib/components/Dropdown/DropdownItem";
import TextInput from "../../../../ui-lib/components/Inputs/TextInput";
import useUser from "../../../../core/user/useUser";
import { useTimezones } from "../../../../core/api/administrators/administrators";
import ExpandableList from "../../../../ui-lib/components/ExpandableList/ExpandableList";

export const GeneralForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues?: ResponseCenterDetails;
  onSubmit: (values: ResponseCenterDetails) => Promise<void>;
}) => {
  const t = useTranslations();
  const { data: timezones } = useTimezones();

  const user = useUser();
  const editDisabled = !user.config?.show.includes("RcEditDetails");
  const formRef = useObjectFormRef();
  const methods = useObjectForm<ResponseCenterDetails>({
    defaultValues,
    shouldUnregister: false,
  });
  const {
    control,
    formState: { errors },
    setValue,
    handleSubmit,
    reset,
  } = methods;

  return (
    <FormProvider {...methods}>
      <form
        ref={formRef}
        onSubmit={handleSubmit(async (values) => {
          const parsed = _.omit(values, ["organizationId", "public"]);

          await onSubmit(parsed);
          reset(values);
        })}
        style={{ width: "100%" }}
      >
        <Row align="start">
          <Controller
            name="name"
            control={control}
            rules={{
              required: true,
            }}
            render={({ field }) => (
              <TextInput
                required
                inputRef={field.ref}
                label={t("Common:name")}
                description={t(
                  "AlarmReceptions:general_input_name_description"
                )}
                value={field.value}
                onChange={field.onChange}
                validationError={
                  errors.name && t("Errors:input_field_required")
                }
                disabled={editDisabled}
              />
            )}
          />
          <Spacer size={32} />
          <Controller
            name="displayName"
            control={control}
            render={({ field }) => (
              <TextInput
                label={t("AlarmReceptions:general_input_display_name")}
                value={field.value}
                onChange={field.onChange}
                disabled={editDisabled}
              />
            )}
          />
        </Row>

        <Spacer size={16} />

        <Row align="start">
          <Column style={{ width: "100%" }}>
            <OrganisationPicker
              name="organizationId"
              label={t("Common:belongs_to")}
              disabled
            />
          </Column>
          <Spacer size={32} />
          <Column>
            <Controller
              name="public"
              control={control}
              render={({ field }) => {
                const dropdownItems: IItemProp[] = [
                  {
                    id: "public",
                    name: t("AlarmReceptions:general_input_type_public"),
                    icon: "users",
                    isSelected: field.value === true,
                  },
                  {
                    id: "private",
                    name: t("AlarmReceptions:general_input_type_private"),
                    icon: "user",
                    isSelected: field.value === false,
                  },
                ];

                return (
                  <Dropdown
                    title={t("AlarmReceptions:general_input_type")}
                    width="100%"
                    disabled
                    selectedItem={dropdownItems.find((i) => i.isSelected)}
                    items={dropdownItems}
                    onSelectItem={(item) => {
                      const id = item.id as string;
                      setValue(field.name, id === "public");
                    }}
                  />
                );
              }}
            />
          </Column>
        </Row>

        <Spacer size={16} />

        <Row align="start">
          <Column>
            <Controller
              name="timezone"
              control={control}
              render={({ field }) => {
                const dropdownItems = timezones
                  ? timezones.map((timezone) => ({
                      id: timezone.timezoneName,
                      name: timezone.timezoneName,
                      isSelected: timezone.timezoneName === field.value,
                    }))
                  : [];

                return (
                  <Dropdown
                    title={t("AlarmReceptions:general_input_timezone")}
                    width="100%"
                    selectedItem={dropdownItems.find((i) => i.isSelected)}
                    items={dropdownItems}
                    onSelectItem={(item) =>
                      setValue(field.name, item.id as string)
                    }
                    disabled={editDisabled}
                  />
                );
              }}
            />
          </Column>
          <Spacer size={32} />
          <Column>
            <Controller
              name="deactivateAlarmAfterHours"
              control={control}
              render={({ field }) => {
                const dropdownItems = [0, 24].map((value) => ({
                  id: value,
                  name: t(
                    `AlarmReceptions:general_input_deactivate_after_${value}`
                  ),
                  isSelected: value === field.value,
                }));

                return (
                  <Dropdown
                    title={t("AlarmReceptions:general_input_deactivate_after")}
                    width="100%"
                    selectedItem={dropdownItems.find((i) => i.isSelected)}
                    items={dropdownItems}
                    onSelectItem={(item) =>
                      setValue(field.name, item.id as number)
                    }
                    disabled={editDisabled}
                  />
                );
              }}
            />
          </Column>
        </Row>

        <Spacer size={32} />
        <Divider />
        <Spacer size={32} />

        <Row align="start">
          <Controller
            name="contactPerson"
            control={control}
            render={({ field }) => (
              <TextInput
                label={t("Common:contact_person")}
                value={field.value}
                onChange={field.onChange}
                placeholder={t("AlarmReceptions:general_input_contact_person")}
                disabled={editDisabled}
              />
            )}
          />
          <Spacer size={32} />
          <Controller
            name="emailAddress"
            rules={{
              maxLength: 60,
              validate: validateEmailIfExists,
            }}
            control={control}
            render={({ field }) => (
              <TextInput
                inputRef={field.ref}
                label={t("Common:email")}
                value={field.value}
                onChange={field.onChange}
                placeholder={t("Common:placeholder_email")}
                validationError={
                  errors.emailAddress && t("Errors:input_email_format")
                }
                disabled={editDisabled}
              />
            )}
          />
        </Row>

        <Spacer size={32} />
        <Divider />
        <Spacer size={32} />

        <Row type="left" style={{ width: "100%" }}>
          <Controller
            name="handlesConfirms"
            control={control}
            render={({ field }) => (
              <Checkbox
                label={t("AlarmReceptions:general_input_confirms_changes")}
                checked={!!field.value}
                onChange={field.onChange}
                style={{ padding: 0 }}
                disabled={editDisabled}
              />
            )}
          />
        </Row>
        <Spacer size={8} />
        <Row type="left" style={{ width: "100%" }}>
          <Controller
            name="contactGetAuthorizedUrl"
            control={control}
            render={({ field }) => (
              <Checkbox
                label={t("AlarmReceptions:general_input_contact_gets")}
                checked={!!field.value}
                onChange={field.onChange}
                style={{ padding: 0 }}
                disabled={editDisabled}
              />
            )}
          />
        </Row>

        <Spacer size={40} />

        <ExpandableList title={t("AlarmReceptions:general_show_more")}>
          <>
            <Spacer size={32} />
            <Row align="start">
              <Controller
                name="organizationNumber"
                control={control}
                render={({ field }) => (
                  <TextInput
                    label={t(
                      "AlarmReceptions:general_input_organization_number"
                    )}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder={t("Common:digit_number")}
                    disabled={editDisabled}
                  />
                )}
              />
              <Spacer size={32} />
              <Controller
                name="address"
                control={control}
                render={({ field }) => (
                  <TextInput
                    label={t("Common:labels_address")}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder={t("Organizations:address_example")}
                    disabled={editDisabled}
                  />
                )}
              />
            </Row>

            <Spacer size={16} />

            <Row align="start">
              <Controller
                name="city"
                control={control}
                render={({ field }) => (
                  <TextInput
                    label={t("Common:labels_city")}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder={t("Common:enter_city")}
                    disabled={editDisabled}
                  />
                )}
              />
              <Spacer size={32} />
              <Controller
                name="postalCode"
                control={control}
                render={({ field }) => (
                  <TextInput
                    label={t("Common:labels_postal_code")}
                    value={field.value}
                    onChange={field.onChange}
                    placeholder={t("Common:postal_code_placeholder")}
                    disabled={editDisabled}
                  />
                )}
              />
            </Row>

            <Spacer size={16} />

            <Row align="start">
              <Column align="start" style={{ width: "100%" }}>
                <Controller
                  name="country"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={t("Common:labels_country")}
                      value={field.value}
                      onChange={field.onChange}
                      placeholder={t("Common:enter_country")}
                      disabled={editDisabled}
                    />
                  )}
                />
              </Column>
              <Spacer size={32} />
              <Column align="start" style={{ width: "100%" }} />
            </Row>
          </>
        </ExpandableList>
      </form>
    </FormProvider>
  );
};
