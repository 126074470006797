import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useTranslations from "../../../core/i18n/useTranslations";
import Button, {
  ButtonVariant,
} from "../../../ui-lib/components/Button/Button";
import PrimeModal from "../../../ui-lib/components/PrimeModal/PrimeModal";
import { Column, Row, Spacer } from "../../../components/Layout/Layout";
import RadioGroup from "../../../ui-lib/components/Radio/RadioGroup";
import {
  AdministratorDetails,
  PasswordConstraints,
} from "../../../core/api/administrators/types";
import {
  validateEmailIfExists,
  validateForbiddenChars,
  validateLowerCase,
  validateNumberCount,
  validatePhoneNumberIfExists,
  validateUpperCase,
} from "../../../core/helpers/validation";
import TextInput from "../../../ui-lib/components/Inputs/TextInput";
import { ITreeData } from "../../../ui-lib/components/Hierarchy/Tree";
import PasswordInput from "../../../ui-lib/components/Inputs/PasswordInput";
import {
  getPasswordErrorMessage,
  notifyApiErrors,
} from "../../../core/helpers/helpers";
import useUser from "../../../core/user/useUser";
import {
  createAdministrator,
  getPasswordConstraints,
} from "../../../core/api/administrators/administrators";
import InputContainer from "../../../ui-lib/components/Inputs/InputContainer";
import Checkbox from "../../../ui-lib/components/Checkbox/Checkbox";
import OrganisationFields from "./OrganisationFields";
import { getAllOrganizationsRecursive } from "../../../core/utils/organizations";
import { getResponseCenters } from "../../../core/api/responsecenters/responsecenters";

export const AddAdministrator = ({
  variant,
  organisations,
  onSubmit,
  onCopySubmit,
  onCancel,
  onClose,
  image,
  organizationsById,
  organizationId,
  creationType = "new",
  selectedType = "seller",
  showModal = true,
  defaultValues = {},
  isOpen = false,
  extraHeaderText,
  sellers,
  isLoading,
}: {
  variant?: ButtonVariant;
  organisations: ITreeData[];
  onSubmit?: (adminId: number) => void;
  onCopySubmit?: (data: AdministratorDetails) => void;
  onCancel?: () => void;
  onClose?: () => void;
  image?: string;
  organizationsById?: ITreeData | null;
  organizationId?: number;
  creationType?: "copy" | "new";
  selectedType?: string;
  showModal?: boolean;
  defaultValues?: any;
  isOpen?: boolean;
  extraHeaderText?: string;
  sellers?: ITreeData[];
  isLoading?: boolean;
}) => {
  const t = useTranslations();
  const { authenticatedRequest, data: userData, config } = useUser();
  const [isAddAdministratorOpen, setIsAddAdministratorOpen] =
    useState<boolean>(isOpen);
  const [adminType, setAdminType] = useState<string>(selectedType);
  const [customerId, setCustomerId] = useState<number | undefined>(
    defaultValues.organizationId
  );
  const [arcId, setArcId] = useState<number | undefined>(
    defaultValues.responseCenterId
  );
  const [alarmRes, setAlarmRes] = useState<ITreeData[]>([]);
  const [passConstraint, setPassConstraint] = useState<PasswordConstraints>();
  const [sellerId, setSellerId] = useState<number | undefined>(
    defaultValues.sellerId
  );
  const [isArcLoading, setIsArcLoading] = useState(false);
  const superAdminType = config?.show.includes("SkygdSuperAdmin");

  const {
    control,
    formState: { isSubmitting, errors },
    handleSubmit,
    reset,
    setValue,
    trigger,
    getValues,
    clearErrors,
  } = useForm<AdministratorDetails>({
    defaultValues: {
      type: adminType,
      ...defaultValues,
    },
  });

  const handleClear = () => {
    reset();
    setAdminType("seller");
    setCustomerId(undefined);
    setSellerId(undefined);
  };

  const preselectSeller = () => {
    if (sellers?.length && !organizationsById?.key && adminType === "seller") {
      setValue("sellerId", Number(sellers[0].key));
      setSellerId(Number(sellers[0].key));
    }
  };

  const fetchPasswordComplexity = async (dataType: string, idToUse: number) => {
    try {
      const result = await getPasswordConstraints(
        idToUse,
        authenticatedRequest,
        dataType
      );
      setPassConstraint(result.data);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  // This better to redo
  const getPasswordFetchData = () => {
    let dataType = "";
    let idToUse = null;
    switch (adminType) {
      case "admin":
        dataType =
          userData?.type === "customer" ? "customerId" : "organizationId";
        idToUse = customerId;
        break;
      case "seller":
        dataType = "sellerId";
        idToUse = sellerId;
        break;
      case "respondent":
        dataType = "responseCenterId";
        idToUse = getValues("responseCenterId");
        break;
      default:
        break;
    }
    return { dataType, idToUse };
  };

  const fetchAlarmReceptions = async () => {
    const queryData = {
      organizationId: customerId,
      includeSubCustomers: false,
      page: 1,
      pageSize: 10000,
    };
    setIsArcLoading(true);
    try {
      const data = await getResponseCenters(queryData, authenticatedRequest);
      const alarmTreeItems = data.data.map((item) => ({
        key: item.id,
        label: item.name,
        data: item.name,
      }));
      setAlarmRes(alarmTreeItems);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsArcLoading(false);
    }
  };

  useEffect(() => {
    if (customerId) {
      fetchAlarmReceptions();
    }
  }, [customerId, adminType]);

  // set default value for customerId if organizationsById is passed or organisations length is 1
  useEffect(() => {
    if (organizationsById?.key) {
      setSellerId(undefined);
      setValue("organizationId", Number(organizationsById.key));
      setCustomerId(Number(organizationsById.key));
    } else if (organisations.length === 1) {
      const flatOrgs = getAllOrganizationsRecursive()(organisations);
      const orgKey = flatOrgs.find(
        (org) => org.key === defaultValues.organizationId
      );
      setCustomerId(orgKey?.key as number);
      setValue("organizationId", orgKey?.key as number);
    }
    if (adminType === "seller") {
      preselectSeller();
    }
  }, [
    organizationsById?.key,
    adminType,
    isAddAdministratorOpen,
    organisations,
  ]);

  // get password complexity on changing belongings
  useEffect(() => {
    if (isAddAdministratorOpen) {
      const { idToUse, dataType } = getPasswordFetchData();

      if (idToUse) {
        fetchPasswordComplexity(dataType, +idToUse);
      }
    } else {
      setPassConstraint(defaultValues.passwordConstraints);
    }
  }, [arcId, customerId, sellerId, adminType, isAddAdministratorOpen]);

  // set default type "admin" if modal opened from organisotion page
  // or if current user have type "customer"
  useEffect(() => {
    if (
      (organizationId || userData?.type === "customer") &&
      !Object.keys(defaultValues).length
    ) {
      setAdminType("admin");
      setValue("type", "admin");
    }
  }, [isAddAdministratorOpen]);

  const onCreateAdminSubmit = async (formData: AdministratorDetails) => {
    if (formData.type !== "admin") {
      delete formData.organizationId;
    }
    try {
      const responseData = await createAdministrator(
        formData,
        authenticatedRequest
      );
      onSubmit!(responseData.data);
      handleClear();
      setIsAddAdministratorOpen(false);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    }
  };

  const radioAdminType = [
    ...((userData?.type === "seller" || superAdminType) && !organizationId
      ? [
          {
            id: 0,
            value: "seller",
            text: t("Common:seller"),
            description: t("Administrator:seller_type_description"),
            image: "super-user",
          },
        ]
      : []),
    {
      id: 1,
      value: "admin",
      text: t("Administrator:admin_type"),
      description: t("Administrator:admin_type_description"),
      image: "user",
    },
    ...(!organizationId
      ? [
          {
            id: 2,
            value: "respondent",
            text: t("Common:respondent"),
            description: t("Administrator:respondent_description"),
            image: "phone-incoming",
          },
        ]
      : []),
  ];

  const content = (
    <>
      <p>{t("Administrator:add_administrator_description")}</p>
      <Spacer size={32} />
      <Row type="fill" align="fill" style={{ alignSelf: "stretch" }}>
        <Controller
          name="type"
          control={control}
          render={() => (
            <RadioGroup
              name="template-type"
              items={radioAdminType}
              selectedValue={adminType}
              onChange={(value) => {
                setValue("type", value);
                setAdminType(value);
                setValue("password", "");
                clearErrors();
                // if switched to admin clear ResponseCenterId field
                if (value === "admin") {
                  setPassConstraint(undefined);
                  setValue("responseCenterId", undefined);
                  setValue("sellerId", undefined);
                  // need to re-fetch password complexity for customer
                  if (customerId) {
                    setValue("organizationId", customerId);
                  }
                }
                // clear organisationId if it was selected
                // previously and type switched to "seller"
                if (value === "seller") {
                  setValue("organizationId", undefined);
                  setValue("responseCenterId", undefined);
                  preselectSeller();
                }
                // clear sellerId if it was selected
                // previously and type switched to "respondent"
                if (value === "respondent") {
                  setPassConstraint(undefined);
                  setValue("sellerId", undefined);
                }
              }}
              fullWidth
              disabled={!!organizationId || creationType === "copy"}
            />
          )}
        />
      </Row>
      {organizationId && (
        <>
          <Spacer size={16} />
          <p style={{ color: "var(--Grey-600)" }}>
            {t("Administrator:organisation_admin_belongs")}
          </p>
        </>
      )}
      <Spacer size={32} />
      <OrganisationFields
        adminType={adminType}
        organizationId={organizationId}
        organizationsById={organizationsById}
        arcId={arcId}
        sellers={sellers}
        organisations={organisations}
        alarmRes={alarmRes}
        sellerId={sellerId}
        customerId={customerId}
        control={control}
        errors={errors}
        isLoading={isLoading}
        isArcLoading={isArcLoading}
        setValue={setValue}
        trigger={trigger}
        setSellerId={setSellerId}
        setCustomerId={setCustomerId}
        setArcId={setArcId}
      />
      <Spacer size={16} />
      <Row align="start">
        <Controller
          name="username"
          control={control}
          rules={{
            required: true,
            minLength: 1,
            maxLength: 60,
          }}
          render={({ field }) => (
            <TextInput
              required
              inputRef={field.ref}
              label={t("Administrator:general_input_username")}
              placeholder={t("Common:enter_username")}
              value={field.value}
              onChange={(e) => {
                setValue("username", e.target.value);
                trigger(field.name);
              }}
              validationError={
                errors?.username && t("Errors:input_organization_name")
              }
            />
          )}
        />
        <Spacer size={32} />
        <Controller
          name="password"
          control={control}
          rules={{
            required: true,
            minLength: passConstraint?.minLength,
            validate: {
              lowerCase: (v) => validateLowerCase(v, passConstraint!),
              upperCase: (v) => validateUpperCase(v, passConstraint!),
              countNumbers: (v) => validateNumberCount(v, passConstraint!),
              forbiddenChars: (v) => validateForbiddenChars(v, passConstraint!),
            },
          }}
          render={({ field }) => (
            <PasswordInput
              required
              inputRef={field.ref}
              label={t("Common:login_input_password")}
              placeholder={
                passConstraint
                  ? t(
                      "Administrator:password_constraints_minimum_length"
                    ).replace("{0}", `${passConstraint.minLength}`)
                  : ""
              }
              value={field.value}
              onChange={field.onChange}
              disabled={!passConstraint}
              validationError={
                errors?.password &&
                getPasswordErrorMessage(errors.password.type, passConstraint!)
              }
            />
          )}
        />
      </Row>
      <Spacer size={16} />
      <Row>
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextInput
              label={t("Common:name")}
              placeholder={t("Administrator:enter_name")}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Spacer size={32} />
        <Controller
          name="initials"
          control={control}
          render={({ field }) => (
            <TextInput
              label={t("Common:initials")}
              placeholder={t(
                "Administrator:general_input_initials_placeholder"
              )}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </Row>
      {creationType === "new" && (
        <>
          <Spacer size={16} />
          <Row align="start">
            <Column>
              <Controller
                name="email"
                control={control}
                rules={{
                  maxLength: 60,
                  validate: validateEmailIfExists,
                }}
                render={({ field }) => (
                  <TextInput
                    inputRef={field.ref}
                    placeholder={t("Common:placeholder_email")}
                    label={t("Contacts:input_email_address")}
                    value={field.value}
                    onChange={field.onChange}
                    validationError={
                      errors?.email && t("Errors:input_email_format")
                    }
                  />
                )}
              />
            </Column>
            <Spacer size={32} />
            <Column>
              <Controller
                name="telephone"
                control={control}
                rules={{
                  validate: validatePhoneNumberIfExists,
                }}
                render={({ field }) => (
                  <TextInput
                    inputRef={field.ref}
                    placeholder={t("Common:phone_number_placeholder")}
                    label={t("Filters:Telephone")}
                    value={field.value}
                    onChange={field.onChange}
                    validationError={
                      errors?.telephone &&
                      t("Objects:Validation_identifier_format")
                    }
                  />
                )}
              />
            </Column>
          </Row>
        </>
      )}
      {creationType === "copy" && (
        <>
          <Spacer size={32} />

          <InputContainer
            label={
              <p style={{ fontWeight: 600, fontSize: "14px" }}>
                {t("Administrator:copy_settings_label")}
              </p>
            }
            input={
              <>
                <Spacer size={8} />
                <Row>
                  <div style={{ width: "390px" }}>
                    <Controller
                      control={control}
                      name="mainSettings"
                      render={({ field }) => (
                        <Checkbox
                          label={t("Administrator:MainSettings")}
                          checked={!!field.value}
                          onChange={() => {
                            setValue(field.name, !field.value);
                          }}
                          style={{ padding: 0 }}
                        />
                      )}
                    />
                  </div>
                  <Controller
                    control={control}
                    name={
                      adminType === "respondent"
                        ? "adminRespondentGroups"
                        : "adminGUIConfigs"
                    }
                    render={({ field }) => (
                      <Checkbox
                        label={t(
                          `Administrator:${
                            adminType === "respondent"
                              ? "AdminRespondentGroups"
                              : "AdminGUIConfigs"
                          }`
                        )}
                        checked={!!field.value}
                        onChange={() => {
                          setValue(field.name, !field.value);
                        }}
                        style={{ padding: 0 }}
                      />
                    )}
                  />
                </Row>
                <Row>
                  <div style={{ width: "390px" }}>
                    <Controller
                      control={control}
                      name="menuAlternatives"
                      render={({ field }) => (
                        <Checkbox
                          label={t("Administrator:MenuAlternatives")}
                          checked={!!field.value}
                          onChange={() => {
                            setValue(field.name, !field.value);
                          }}
                          style={{ padding: 0 }}
                        />
                      )}
                    />
                  </div>
                  {adminType !== "respondent" && (
                    <Controller
                      control={control}
                      name="adminRoles"
                      render={({ field }) => (
                        <Checkbox
                          label={t("Administrator:AdminRoles")}
                          checked={!!field.value}
                          onChange={() => {
                            setValue(field.name, !field.value);
                          }}
                          style={{ padding: 0 }}
                        />
                      )}
                    />
                  )}
                </Row>
                <Row>
                  <div style={{ width: "390px" }}>
                    <Controller
                      control={control}
                      name="forcePasswordReset"
                      render={({ field }) => (
                        <Checkbox
                          label={t("Administrator:ForcePasswordReset")}
                          checked={!!field.value}
                          onChange={() => {
                            setValue(field.name, !field.value);
                          }}
                          style={{ padding: 0 }}
                        />
                      )}
                    />
                  </div>
                </Row>
              </>
            }
          />

          {!showModal && (
            <>
              <Spacer size={32} />
              <Row align="start" type="left">
                <Button
                  variant="primary"
                  text={t("Administrator:create_administrator")}
                  onClick={() => handleSubmit(onCopySubmit!)()}
                />
                <Spacer size={16} />

                <Button
                  variant="secondary"
                  text={t("Common:cancel")}
                  onClick={onCancel}
                />
              </Row>
              <Spacer size={16} />
            </>
          )}
        </>
      )}
    </>
  );

  if (!showModal) {
    return content;
  }

  return (
    <>
      {variant && (
        <Button
          variant={variant}
          image={image}
          text={t("Administrator:add_administrator")}
          onClick={() => {
            setIsAddAdministratorOpen(true);
          }}
          loading={isArcLoading}
          disabled={isArcLoading}
        />
      )}
      <PrimeModal
        withFooter
        withHeader
        contentStyle={{ width: "836px" }}
        loading={isSubmitting}
        isOpen={isAddAdministratorOpen}
        header={
          <>
            {t("Administrator:add_administrator")}
            {extraHeaderText && (
              <span style={{ opacity: 0.5 }}>{` ${extraHeaderText}`}</span>
            )}
          </>
        }
        onClose={() => {
          onClose?.();
          handleClear();
          setPassConstraint(undefined);
          setIsAddAdministratorOpen(false);
        }}
        submitBtn={{
          text: t("Administrator:create_administrator"),
          onClick: () =>
            onCopySubmit
              ? handleSubmit(onCopySubmit)()
              : handleSubmit(onCreateAdminSubmit)(),
        }}
        cancelBtn={{
          text: t("Common:cancel"),
          onClick: () => {
            onClose?.();
            handleClear();
            setIsAddAdministratorOpen(false);
          },
        }}
      >
        {content}
      </PrimeModal>
    </>
  );
};
