import classnames from "classnames";
import {
  breakpoints,
  getResponsiveStyle,
  isDesktop,
} from "../../core/hooks/dimensionProvider";
import styles from "./Layout.module.css";

type RowType = "left" | "right" | "center" | "fill" | "space";
type ColType = "top" | "bottom" | "center" | "fill";
type AlignType = "start" | "end" | "center" | "fill";
interface ContainerProps {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  className?: string;
  responsive?: boolean;
  breakPoint?: number;
}

export interface RowContainerProps extends ContainerProps {
  type?: RowType;
  align?: AlignType;
}
export interface ColContainerProps extends ContainerProps {
  type?: ColType;
  align?: AlignType;
}

export const Row = ({
  style,
  children,
  className,
  type,
  align,
  breakPoint,
  responsive = false,
}: RowContainerProps) => {
  const desktop = isDesktop({ tablet: breakPoint ?? breakpoints.tablet });
  const justifyContent: any = (() => {
    switch (type) {
      case "left":
        return { justifyContent: "flex-start" };
      case "right":
        return { justifyContent: "flex-end" };
      case "fill":
        return { justifyContent: "stretch" };
      case "space":
        return { justifyContent: "space-between" };
      default:
        return null;
    }
  })();
  const alignItems: any = (() => {
    switch (align) {
      case "start":
        return { alignItems: "flex-start" };
      case "end":
        return { alignItems: "flex-end" };
      case "fill":
        return { alignItems: "stretch", alignSelf: "stretch" };
      default:
        return null;
    }
  })();
  if (!desktop && alignItems && justifyContent) {
    alignItems.alignItems = justifyContent?.justifyContent;
    justifyContent.justifyContent = "flex-start";
  }
  return (
    <div
      className={classnames(
        {
          [styles.row]: desktop || !responsive,
          [styles.column]: !desktop && responsive,
        },
        className
      )}
      style={{ ...style, ...justifyContent, ...alignItems }}
    >
      {children}
    </div>
  );
};

export const Column = ({
  style,
  children,
  className,
  type,
  align,
}: ColContainerProps) => {
  const justifyContent = (() => {
    switch (type) {
      case "top":
        return { justifyContent: "flex-start" };
      case "bottom":
        return { justifyContent: "flex-end" };
      case "fill":
        return { justifyContent: "stretch", alignSelf: "stretch" };
      default:
        return null;
    }
  })();
  const alignItems = (() => {
    switch (align) {
      case "start":
        return { alignItems: "flex-start" };
      case "end":
        return { alignItems: "flex-end" };
      case "fill":
        return { alignItems: "stretch", alignSelf: "stretch" };
      default:
        return null;
    }
  })();
  return (
    <div
      className={classnames(styles.column, className)}
      style={{ ...justifyContent, ...alignItems, ...style }}
    >
      {children}
    </div>
  );
};

export const Spacer = ({ size }: { size?: number }) => (
  <div
    className={styles.spacer}
    style={
      size
        ? {
            width: `${size}px`,
            height: `${size}px`,
            flexGrow: 0,
            flexShrink: 0,
          }
        : { flexGrow: 1, flexShrink: 1 }
    }
  />
);

export interface GridProps {
  style?: React.CSSProperties;
  children?: React.ReactNode;
  className?: string;
  breakPoint?: number;
}
export const TwoColGrid = ({
  style,
  children,
  className,
  breakPoint,
}: GridProps) => (
  <div
    className={getResponsiveStyle(
      {
        tablet: () => classnames(styles.mobileGrid, className),
        desktop: () => classnames(styles.grid, className),
      },
      { tablet: breakPoint ?? breakpoints.tablet }
    )}
    style={{
      ...style,
    }}
  >
    {children}
  </div>
);

export const FillTwoColRow = ({ style, children }: GridProps) => (
  <div style={{ gridColumn: "1 / span 2", ...style }}>{children}</div>
);
