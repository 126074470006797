import {
  ReactNode,
  SyntheticEvent,
  useCallback,
  useEffect,
  useState,
} from "react";
import ReactDOM from "react-dom";
import {
  ARROW_DOWN_KEY,
  ARROW_UP_KEY,
  ENTER_KEY,
  ESCAPE_KEY,
  TAB_KEY,
} from "../../../../core/constants/keyboard_keys";
import {
  IDropdownWithCheckboxes,
  useDropdownWithCheckboxHooks,
} from "../../../utils/dropdown-hook";
import styles from "./style.module.css";
import baseStyles from "../Dropdown.module.css";
import DropdownBody from "../DropdownBody";
import Checkbox, { ICheckbox } from "../../Checkbox/Checkbox";
import {
  getDropdownBodyHeight,
  getRootPopup,
} from "../../../../core/helpers/dropdownHelper";
import Chips, { IChips } from "../../Chips/Chips";
import Icon from "../../Icon";
import useTranslations from "../../../../core/i18n/useTranslations";

export default function DropdownWIthCheckboxes(
  props: Readonly<IDropdownWithCheckboxes>
) {
  const t = useTranslations();
  const {
    items,
    id,
    width,
    containerWidth,
    getSelectedItems,
    title,
    isOpen,
    setIsOpen,
    btnRef,
    setFocusedIndex,
    focusedIndex,
    container,
  } = useDropdownWithCheckboxHooks(props, true);
  const [checkboxItems, setCheckboxItems] = useState([] as ICheckbox[]);
  const [selectedItems, setSelectedItems] = useState([] as ICheckbox[]);

  useEffect(() => {
    setCheckboxItems(items);
    const selectedCheckboxes = items.filter((sO) => sO.checked);
    setSelectedItems(selectedCheckboxes);
  }, []);

  function onChange(item: ICheckbox) {
    const selectedOptions = [...checkboxItems];
    selectedOptions.forEach((i) => {
      if (i.id === item.id) i.checked = !i.checked;
    });
    setCheckboxItems(selectedOptions);
    const selectedCheckboxes = selectedOptions.filter((sO) => sO.checked);
    getSelectedItems(selectedCheckboxes); // return selected items to parent fro api calls for example
    setSelectedItems(selectedCheckboxes); // set items for chips
  }

  function unCheckItem(item: IChips, e: SyntheticEvent) {
    e.stopPropagation();
    e.preventDefault();
    onChange({ id: item.id, label: item.label, checked: true } as ICheckbox);
  }
  function getChipsList(): ReactNode | null {
    return selectedItems.map((selectedItem, index) => {
      if (index > 2) return null;
      return (
        <Chips
          item={{
            id: selectedItem.id,
            label: selectedItem.label,
          }}
          key={selectedItem.id}
          onRemove={unCheckItem}
          width="32%"
        />
      );
    });
  }

  const handleInputKeyDown = useCallback(
    (e: any) => {
      const { keyCode } = e;
      switch (keyCode) {
        case ARROW_UP_KEY:
          setFocusedIndex((prev) => {
            if (prev === 0) return checkboxItems.length - 1;
            return prev - 1;
          });
          break;
        case ARROW_DOWN_KEY:
        case TAB_KEY:
          e.preventDefault();
          setFocusedIndex((p) => (p + 1) % checkboxItems.length);
          break;
        case ESCAPE_KEY:
          setIsOpen(false);
          break;
        case ENTER_KEY:
          if (isOpen) onChange(checkboxItems[focusedIndex]);
          else setIsOpen(true);
          break;
        default:
          break;
      }
    },
    [isOpen, checkboxItems, setIsOpen, setFocusedIndex, focusedIndex]
  );

  const renderItems = () =>
    checkboxItems.map((item: ICheckbox, index: number) => {
      const focused = focusedIndex === index;
      return (
        <Checkbox
          key={item.id}
          label={item.label}
          checked={item.checked}
          focused={focused}
          onChange={() => onChange(item)}
          fullWidth
          isInsideDropdown
        />
      );
    });

  return (
    <div
      id={id}
      className={styles.dropdownContainer}
      style={{ width: containerWidth }}
    >
      <div className={styles.title}>{title}</div>
      <div
        ref={btnRef}
        className={baseStyles.dropdownBtn}
        style={{ width }}
        role="button"
        tabIndex={0}
        onClick={() => setIsOpen(!isOpen)}
        onKeyDown={handleInputKeyDown}
      >
        <div className={styles.dropdownBtnItems}>
          {selectedItems.length === 0 ? (
            <div className={baseStyles.selectOption}>
              {props.placeholder
                ? props.placeholder
                : t("Common:select_option")}
            </div>
          ) : (
            <div className={styles.chipsList}>{getChipsList()}</div>
          )}
          {selectedItems.length > 3 && (
            <div className={styles.moreItems}>+{selectedItems.length - 3}</div>
          )}
        </div>

        {isOpen ? (
          <Icon name="chevron-up" size={14} />
        ) : (
          <Icon name="chevron-down" size={14} />
        )}
      </div>
      {isOpen &&
        ReactDOM.createPortal(
          <DropdownBody
            elem={btnRef.current}
            innerRef={container}
            onClose={() => setIsOpen(false)}
            dropDownHeight={getDropdownBodyHeight(checkboxItems.length)}
            withBtns
          >
            {renderItems()}
          </DropdownBody>,
          getRootPopup()
        )}
    </div>
  );
}
