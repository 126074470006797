import { ReactNode, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import Modal from "../../../ui-lib/components/Modal/Modal";
import TextInput from "../../../ui-lib/components/Inputs/TextInput";
import Button from "../../../ui-lib/components/Button/Button";
import { Column, Spacer } from "../../../components/Layout/Layout";
import {
  ObjectActivationData,
  ObjectDetailsModel,
  ObjectPatch,
} from "../../../core/api/objects/types";

import useTranslations from "../../../core/i18n/useTranslations";
import Dropdown from "../../../ui-lib/components/Dropdown/Dropdown";
import {
  activateSubscription,
  patchUpdateObject,
  removeSubscription,
} from "../../../core/api/objects/objects";
import useUser from "../../../core/user/useUser";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import Divider from "../../../ui-lib/components/Divider/Divider";

import { IDropdownItem } from "../../../ui-lib/components/Dropdown/DropdownItem";

import {
  useObjectForm,
  useObjectFormRef,
} from "../../../core/SaveObjectContext/hooks";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import useSharedObject from "../SharedObjectContext/useSharedObject";
import { useProductCategories } from "../../../core/api/products/products";
import { IProductCategory } from "../../../core/api/products/types";
import InputContainer from "../../../ui-lib/components/Inputs/InputContainer";
import { Role, RoleFeature } from "../../../components/RoleFeature";
import { OrganisationSubscription } from "../../../core/api/subscriptions";
import { notifyApiErrors } from "../../../core/helpers/helpers";

import styles from "./ObjectSubscription.module.css";

const ObjectSubscriptionForm = ({
  objectId,
  objectData,
  subscriptionData,
  fetchObjectSubscription,
  subscriptionUpdateInProgress,
  onSubmit,
  ARCs,
}: {
  objectId: string;
  objectData: ObjectDetailsModel;
  subscriptionData: ObjectActivationData;
  fetchObjectSubscription: () => Promise<void>;
  subscriptionUpdateInProgress: any;
  onSubmit: (values: any) => Promise<void>;
  ARCs: OrganisationSubscription[];
}) => {
  const t = useTranslations();
  const { fetchObject } = useSharedObject();
  const { authenticatedRequest, config } = useUser();
  const [showAlarmCodeModal, setShowAlarmCodeModal] = useState(false);
  const [showCancelSubscriptionModal, setShowCancelSubscriptionModal] =
    useState(false);
  const [activationCode, setActivationCode] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [selectedSubscription, setSelectedSubscription] =
    useState<IDropdownItem>({
      name: subscriptionData.subscription,
      id: subscriptionData.subscriptionId,
      isSelected: true,
    });

  const canActivateSubscription = config?.show.includes("UserActivate");

  const formRef = useObjectFormRef();

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    reset,
    getValues,
  } = useObjectForm<ObjectDetailsModel>({
    defaultValues: objectData,
    shouldUnregister: true,
  });

  const { data: productCategories } = useProductCategories();
  const [categories, setCategories] = useState<IDropdownItem[]>([]);

  useEffect(() => {
    if (productCategories !== null) {
      const newCategories = productCategories.map((r: IProductCategory) => ({
        id: r.productCategoryId,
        name: t(`Common:product_categories_${r.name}`),
        isSelected: false,
      }));
      setCategories(newCategories);
    }
  }, [productCategories]);

  const renderModalBodyWithLoading = (
    isFetching: boolean,
    content: ReactNode
  ) => (isFetching ? <LoadingSpinner theme="primary" /> : content);

  const renderCancelSubscriptionFooter = () => (
    <div className={styles.cancelSubscriptionFooter}>
      <Button
        type="button"
        variant="primary"
        text={t("Objects:label_dont_cancel_subscription")}
        onClick={() => setShowCancelSubscriptionModal(false)}
        disabled={isLoading}
      />
      <Button
        type="button"
        variant="destructive"
        text={t("Objects:label_cancel_subscription")}
        onClick={async () => {
          try {
            setIsLoading(true);
            await removeSubscription(objectId, authenticatedRequest);
            await fetchObject();
            setShowCancelSubscriptionModal(false);
            fetchObjectSubscription();
          } catch (error) {
            notify({
              message: t(`Errors:${error}`),
              variant: "error",
            });
          } finally {
            setIsLoading(false);
          }
        }}
        disabled={isLoading}
      />
    </div>
  );

  const renderCodeActivationFooter = () => (
    <div className={styles.cancelSubscriptionFooter}>
      <Button
        type="button"
        variant="primary"
        text={t("Objects:label_use_code")}
        onClick={async () => {
          setIsLoading(true);
          await activateSubscription(
            objectId,
            authenticatedRequest,
            activationCode
          );
          setIsLoading(false);
          setShowAlarmCodeModal(false);
          fetchObjectSubscription();
        }}
        disabled={isLoading}
      />
      <Button
        type="button"
        variant="secondary"
        text={t("Common:cancel")}
        onClick={() => setShowAlarmCodeModal(false)}
        disabled={isLoading}
      />
    </div>
  );

  const renderCodeModalWindow = () => (
    <Modal
      isOpen={showAlarmCodeModal}
      onClose={() => setShowAlarmCodeModal(false)}
      modalTitle={t("Common:labels_activation_code")}
      closeOnDocumentClick={false}
      customFooter={renderCodeActivationFooter}
      wrapperClass="object-subscription-modals"
    >
      {renderModalBodyWithLoading(
        isLoading,
        <>
          <Spacer size={16} />
          <p>{t("Common:modal_activation_code")}</p>
          <Spacer size={16} />
          <TextInput
            label={t("Objects:labels_subscription_code")}
            value={activationCode}
            onChange={(e) => setActivationCode(e.target.value)}
          />
        </>
      )}
    </Modal>
  );

  const renderCancelSubscriptionModal = () => (
    <Modal
      isOpen={showCancelSubscriptionModal}
      onClose={() => setShowCancelSubscriptionModal(false)}
      modalTitle={t("Common:labels_deactivate_subscription")}
      wrapperClass="object-subscription-modals"
      customFooter={renderCancelSubscriptionFooter}
    >
      {renderModalBodyWithLoading(
        isLoading,
        <>
          <Spacer size={16} />
          <p>{t("Common:modal_cancel_subscription")}</p>
        </>
      )}
    </Modal>
  );

  const isActivateButtonDisabled = () => {
    if (!selectedSubscription.id) {
      return true;
    }
    return !canActivateSubscription;
  };

  const renderSubscriptionActionButton = (id: string) =>
    subscriptionData.subscriptionActive ? (
      <Button
        text={t("Common:labels_deactivate_subscription")}
        variant="destructive"
        onClick={() => setShowCancelSubscriptionModal(true)}
        disabled={!config?.show.find((key) => key === "CancelSubscriptions")}
      />
    ) : (
      <Button
        text={t("Common:labels_activate_subscription")}
        disabled={isActivateButtonDisabled()}
        dataCy="activate_subscription_btn"
        onClick={async () => {
          subscriptionUpdateInProgress(true);
          try {
            await patchUpdateObject(
              id,
              {
                nextSubscriptionId: selectedSubscription.id,
              } as ObjectPatch,
              authenticatedRequest
            );
            await activateSubscription(id, authenticatedRequest);
            await fetchObject();
            await fetchObjectSubscription();
          } catch (error: any) {
            notifyApiErrors(error.response?.data?.errors);
          } finally {
            subscriptionUpdateInProgress(false);
          }
        }}
      />
    );

  return (
    <>
      {renderCodeModalWindow()}
      {renderCancelSubscriptionModal()}

      <Column style={{ width: "48%" }}>
        {subscriptionData.subscriptionActive ? (
          <TextInput
            dataCy="objectSubscription"
            value={subscriptionData.subscription}
            label={t("Objects:subscription")}
            disabled
          />
        ) : (
          <Dropdown
            dataCy="objectSubscriptionDropDown"
            title={t("Objects:subscription")}
            onSelectItem={(item) => {
              setSelectedSubscription(item);
            }}
            items={ARCs.map((arc) => ({
              id: arc.subscriptionId,
              name: arc.subscriptionGroupDescription,
              isSelected: selectedSubscription.id === arc.subscriptionId,
            }))}
            selectedItem={{
              ...selectedSubscription,
              isSelected: true,
            }}
            disabled={!canActivateSubscription}
          />
        )}
      </Column>
      <RoleFeature requires={[Role.ActivateSubscriptions]}>
        <Spacer size={32} />
        <div className={styles.controlButtonsWrapper}>
          {renderSubscriptionActionButton(objectId)}

          <Button
            text={t("Common:labels_use_code")}
            variant="secondary"
            onClick={() => setShowAlarmCodeModal(true)}
            disabled={!subscriptionData.subscriptionNotSet}
          />
          <Button
            image="beaker"
            text={
              !objectData.demoAccount
                ? t("Common:labels_set_demo_object")
                : t("Common:labels_unset_demo_object")
            }
            variant="secondary"
            iconVariant={!objectData.demoAccount ? "secondary" : "link"}
            onClick={async () => {
              subscriptionUpdateInProgress(true);
              try {
                await patchUpdateObject(
                  objectId,
                  {
                    demoAccount: !objectData.demoAccount,
                  } as ObjectPatch,
                  authenticatedRequest
                );
                await fetchObject();
              } catch (error: any) {
                notifyApiErrors(error.response?.data?.errors);
              } finally {
                subscriptionUpdateInProgress(false);
              }
            }}
          />
        </div>
      </RoleFeature>
      {config!.show.includes("ChangeObjectAlarmType") && (
        <>
          <Spacer size={32} />
          <div className={styles.formInputsWrapper}>
            <div className={styles.contentWrapper}>
              <Controller
                name="productCategoryId"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <InputContainer
                    required
                    label={
                      <label htmlFor="addobject_category">
                        {t("Objects:category")}
                      </label>
                    }
                    input={
                      <Dropdown
                        id="addobject_category"
                        items={categories}
                        disabled={!categories.length}
                        placeholder={t("Objects:selectcategory")}
                        selectedItem={categories.find(
                          (r) => getValues(field.name) === r.id
                        )}
                        onSelectItem={(item) => {
                          setValue(field.name, +item.id);
                        }}
                        invalid={errors.productCategoryId !== undefined}
                        validationError={
                          errors.productCategoryId &&
                          t("Objects:Validation_productcategory")
                        }
                      />
                    }
                  />
                )}
              />
            </div>
          </div>
        </>
      )}
      <Spacer size={32} />
      <Divider />
      <Spacer size={32} />
      <div className={styles.contentWrapper}>
        <form
          ref={formRef}
          onSubmit={handleSubmit(async (values: any) => {
            await onSubmit(values);
            reset(values);
          })}
        >
          <Controller
            name="costCenter"
            control={control}
            render={({ field }) => (
              <TextInput
                label={t("Common:labels_cost_centre")}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </form>
        <Spacer size={16} />
      </div>
    </>
  );
};

export default ObjectSubscriptionForm;
