import { useEffect, useState } from "react";
import { getErrorKey } from "../../components/Errors/ErrorAlert";
import { Column, Spacer } from "../../components/Layout/Layout";
import { getPeriodicReports } from "../../core/api/reports/reports";
import { PeriodicReportResponse } from "../../core/api/reports/types";
import useTranslations from "../../core/i18n/useTranslations";
import usePageState from "../../core/pagestate/usePageState";
import useUser from "../../core/user/useUser";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import Divider from "../../ui-lib/components/Divider/Divider";
import PageHeader from "../../ui-lib/components/PageHeader/PageHeader";
import styles from "../Layout.module.css";
import { ReportsTable } from "./ReportsTable";
import { storeTableState } from "../../core/hooks/filters/useStoredTableState";

const PAGE_ID = "adminportal/reports";

const Reports = () => {
  const t = useTranslations();
  const pageState = usePageState();
  const { authenticatedRequest } = useUser();

  const [total, setTotal] = useState<PeriodicReportResponse[]>();

  const fetchReports = async () => {
    try {
      const { data } = await getPeriodicReports(authenticatedRequest);

      setTotal(data);
    } catch (error) {
      setTotal([]);

      notify({
        message: t(`Errors:${getErrorKey(error)}`),
        variant: "error",
      });
    }
  };

  useEffect(() => {
    fetchReports();
  }, []);

  storeTableState(PAGE_ID);

  return (
    <>
      <PageHeader
        title={t("Menu:reports")}
        subTitle={pageState.pageSubTitle}
        icon={pageState.pageIcon}
      />
      <Divider />
      <Column className={styles.wrapper}>
        <Column className={styles.content}>
          <Spacer size={16} />

          <Column className={styles.tableContainer} type="top">
            <ReportsTable
              data={total}
              isLoading={!total}
              onUpdate={fetchReports}
            />
          </Column>
        </Column>
      </Column>
    </>
  );
};

export default Reports;
