import { useState } from "react";
import { useHistory } from "react-router-dom";
import { ObjectDetailsModel } from "../../../core/api/objects/types";
import { RemoveAssociationModal } from "./RemoveAssociationModal";
import { PageFilter } from "../../../core/hooks/filters/usePageFilter";
import useTranslations from "../../../core/i18n/useTranslations";
import Table from "../../../ui-lib/components/Tables/Table";
import TableCell from "../../../ui-lib/components/Tables/TableCell";

const columns = (t: (key: string) => string) => [
  {
    key: "name",
    header: t("Common:name"),
    fieldTemplate: (rowData: ObjectDetailsModel) => (
      <TableCell
        value={rowData.name}
        linkTo={`/adminportal/objects/${rowData.id}`}
      />
    ),
  },
  {
    key: "identifier",
    header: t("Table:TelephoneNumber"),
    fieldTemplate: (rowData: ObjectDetailsModel) => (
      <TableCell value={rowData.identifier} />
    ),
  },
  {
    key: "org",
    header: t("Organizations:organization"),
    fieldTemplate: (rowData: ObjectDetailsModel) => (
      <TableCell
        value={rowData.organizationName}
        linkTo={`/adminportal/organizations/${rowData.organizationId}`}
      />
    ),
  },
  {
    key: "arc",
    header: t("Table:AlarmHandling"),
    fieldTemplate: (rowData: ObjectDetailsModel) => (
      <TableCell value={rowData.alarmHandling} />
    ),
  },
];

export const AssociatedObjectsTable = ({
  contactId,
  tableData,
  totalAmount,
  pageFilter,
  setPageFilter,
  refetch,
}: {
  contactId: number;
  tableData?: ObjectDetailsModel[];
  totalAmount: number;
  pageFilter: PageFilter;
  setPageFilter: (args: PageFilter) => void;
  refetch: () => Promise<void>;
}) => {
  const t = useTranslations();
  const history = useHistory();

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [objectToRemove, setObjectToRemove] = useState<{
    name: string;
    id: string;
  }>();

  return (
    <>
      <Table<ObjectDetailsModel>
        hideEmptyMessage
        rowActionsFixed
        withLazyLoading
        withPagination
        items={tableData}
        rowActionsColWidth={74}
        columns={columns(t)}
        rowActions={[
          {
            icon: "pencil-alt",
            text: t("Common:label_edit"),
            onClick: (rowData) => {
              history.push(`/adminportal/objects/${rowData.id}`);
            },
          },
          {
            icon: "x",
            text: t("Contacts:remove_connection"),
            iconVariant: "secondary",
            onClick: (rowData) => {
              setObjectToRemove({
                name: rowData.name,
                id: rowData.id.toString(),
              });
              setDeleteModalOpen(true);
            },
          },
        ]}
        paginatedItems={{
          items: tableData ?? [],
          pagination: {
            offset: pageFilter.pageSize * (pageFilter.page - 1),
            limit: pageFilter.pageSize,
            total: totalAmount,
          },
        }}
        onPageChange={(nextPage) => {
          const page = Math.floor(nextPage.offset / nextPage.limit);

          if (!Number.isNaN(page) && nextPage.limit) {
            setPageFilter({
              page: page + 1,
              pageSize: nextPage.limit,
            });
          }
        }}
      />
      {deleteModalOpen && (
        <RemoveAssociationModal
          contactId={contactId}
          isOpen={deleteModalOpen}
          objectToRemove={objectToRemove}
          onClose={() => setDeleteModalOpen(false)}
          onSubmit={() => {
            setDeleteModalOpen(false);
            setObjectToRemove(undefined);
            refetch();
          }}
        />
      )}
    </>
  );
};
