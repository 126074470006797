import { ReactNode, useEffect, useState } from "react";
import { observer } from "mobx-react";
import useTranslations from "../../../../core/i18n/useTranslations";
import { Column, Row } from "../../../../components/Layout/Layout";
import PageHeader from "../../../../ui-lib/components/PageHeader/PageHeader";
import Divider from "../../../../ui-lib/components/Divider/Divider";
import styles from "../../../Layout.module.css";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { ProductGeneral } from "./General/ProductGeneral";
import { useSubNav } from "../../../../components/LoggedinPage/SubNavContext";
import { useStoredTableState } from "../../../../core/hooks/filters/useStoredTableState";
import { useSaveObject } from "../../../../core/SaveObjectContext/hooks";
import Button from "../../../../ui-lib/components/Button/Button";
import { SaveObjectProvider } from "../../../../core/SaveObjectContext/SaveObjectContext";
import PopupContextMenu from "../../../../ui-lib/components/Menus/PopupContextMenu/PopupContextMenu";
import { PopupContextMenuLinkButton } from "../../../../ui-lib/components/Menus/PopupContextMenu/PopupContextMenuLinkButton";
import { DeleteProductModal } from "../DeleteProductModal";

export const PAGE_ID = "adminportal/products";

const subMenuItems = (url: string) => [{ key: "Common:products", to: url }];

const ProductsDetailsWrapper = ({
  id,
  children,
}: {
  id: string;
  children: ReactNode;
}) => {
  const history = useHistory();
  const t = useTranslations();
  const { canSave, isDirty, isSaving, onSave } = useSaveObject();
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  return (
    <Row align="start" style={{ width: "100%" }}>
      <Column
        style={{ width: "100%", height: "100vh", overflow: "hidden" }}
        type="top"
      >
        <DeleteProductModal
          onClose={(deleted) => {
            setIsDeleteModalOpen(false);
            if (deleted) {
              history.push(`/${PAGE_ID}`);
            }
          }}
          productId={id}
          isOpen={isDeleteModalOpen}
        />

        <PageHeader title={t("Common:products")}>
          {canSave && (
            <Button
              text={t("Common:save")}
              disabled={!isDirty}
              loading={isSaving}
              onClick={onSave}
            />
          )}
          <PopupContextMenu
            className="mt-8"
            key="product_details_actions"
            position="right bottom"
            nested
            trigger={() => (
              <div>
                <Button
                  type="button"
                  variant="secondary"
                  image="dots-horizontal"
                  text={t("Common:other")}
                />
              </div>
            )}
          >
            <PopupContextMenuLinkButton
              icon="trash"
              text={t("Reseller:delete_product")}
              onClick={async () => {
                setIsDeleteModalOpen(true);
              }}
            />
          </PopupContextMenu>
        </PageHeader>
        <Divider />
        <Column
          className={styles.wrapper}
          style={{
            height: "auto",
            overflowY: "auto",
          }}
        >
          <Column
            className={styles.content}
            type="top"
            align="start"
            style={{ padding: "32px" }}
          >
            {children}
          </Column>
        </Column>
      </Column>
    </Row>
  );
};

const ProductsDetails = () => {
  const { id } = useParams<{ id: string }>();
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={path}>
        <ProductsDetailsWrapper id={id}>
          <ProductGeneral id={id} />
        </ProductsDetailsWrapper>
      </Route>
    </Switch>
  );
};

const ProductDetailsListContainer = () => {
  const t = useTranslations();

  const { url } = useRouteMatch();
  const { setSubNav } = useSubNav();
  const tableState = useStoredTableState(PAGE_ID);
  const backUrl = tableState ? `/${PAGE_ID}${tableState}` : `/${PAGE_ID}`;

  useEffect(() => {
    const nav = {
      menu: subMenuItems(url),
      backUrl,
      backText: t("Common:products"),
    };

    setSubNav(nav);

    return () => {
      setSubNav(undefined);
    };
  }, []);

  return (
    <SaveObjectProvider>
      <ProductsDetails />;
    </SaveObjectProvider>
  );
};

export default observer(ProductDetailsListContainer);
