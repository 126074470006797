import useUser from "../../core/user/useUser";
import Divider from "../../ui-lib/components/Divider/Divider";
import { Spacer } from "../Layout/Layout";
import { ManualSync } from "./ManualSync";
import useTranslations from "../../core/i18n/useTranslations";

export interface ManualSyncTableProps {
  syncStatus?: string;
  latestSyncAttempt?: string;
  lastSync?: string;
}

export const ManualSyncWrapper = ({
  clusterData,
  externalSettingData,
  refetchFunc,
}: {
  clusterData: ManualSyncTableProps;
  externalSettingData?: ManualSyncTableProps;
  refetchFunc: () => Promise<void>;
}) => {
  const t = useTranslations();
  const { config } = useUser();

  return (
    (config?.show.includes("LastClusterSync") ||
      (externalSettingData &&
        externalSettingData.syncStatus !== "NO_INTEGRATION_DEFINED")) && (
      <>
        <Spacer size={32} />
        <Divider />
        <Spacer size={32} />
        <h2>{t("Objects:manual_sync_header")}</h2>
        <p>{t("Objects:manual_sync_description")}</p>

        <Spacer size={16} />

        {config?.show.includes("LastClusterSync") && (
          <>
            <ManualSync
              buttonHidden
              title={t("Objects:sync_with_cluster")}
              data={clusterData}
              fetchFunc={refetchFunc}
            />

            <Spacer size={8} />
          </>
        )}

        {externalSettingData &&
          externalSettingData.syncStatus !== "NO_INTEGRATION_DEFINED" && (
            <ManualSync
              buttonHidden={false}
              title={t("Objects:sync_with_external_system")}
              data={externalSettingData}
              fetchFunc={refetchFunc}
            />
          )}
      </>
    )
  );
};
