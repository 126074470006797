import { action, computed, makeObservable, observable } from "mobx";
import moment from "moment";
import { uniqBy } from "lodash";
import { isEmpty } from "../helpers/helpers";
import RootStore from "../RootStore";
import i18n, { CachedLanguages, Language } from "./config";
import BrandingService from "../branding/BrandingService";
import User from "../user/User";

const LANG_LIST = [
  { Language: "en-GB", Flag: "" },
  { Language: "en-GB-x-care", Flag: "" },
  { Language: "sv-SE", Flag: "" },
  { Language: "sv-SE-x-trygg", Flag: "" },
  { Language: "sv-SE-x-trygg-sbo", Flag: "" },
  { Language: "nb-NO", Flag: "" },
  { Language: "nb-NO-x-care", Flag: "" },
  { Language: "de-DE", Flag: "" },
  { Language: "da-DK", Flag: "" },
  { Language: "da-DK-x-care", Flag: "" },
  { Language: "fi-FI", Flag: "" },
  { Language: "fi-FI-x-care", Flag: "" },
  { Language: "fr-FR", Flag: "" },
  { Language: "fr-FR-x-care", Flag: "" },
  { Language: "es-ES", Flag: "" },
  { Language: "es-ES-x-care", Flag: "" },
];
const LANG_KEY = "i18nextLng";
const USER_LANG_LIST_KEY = "i18nextLngListUser";
export const DEFAULT_LANG = "en-GB";
const FLAG_MAP: Record<string, any> = {
  "en-GB": "GB",
  "sv-SE": "SE",
  "flag-400": "JP",
  "nb-NO": "NO",
  "de-DE": "DE",
  "da-DK": "DK",
  "en-US": "US",
  "fr-FR": "FR",
  "pt-BR": "BR",
  "pt-PT": "PT",
  "fi-FI": "FI",
  "it-IT": "IT",
  "es-ES": "ES",
  "GB-SCT": "GB-SCT",
  "nl-NL": "NL",
  "nl-BE": "BE",
};
// TODO: make sure that localized strings for weekdays, months etc are loaded. right now all
// other languages than swedish displays english locale.
const MOMENT_MAP: Record<string, any> = {
  "en-GB": "en-gb",
  "sv-SE": "sv-se",
  "flag-400": "en-gb",
  "nb-NO": "en-gb",
  "de-DE": "en-gb",
  "da-DK": "en-gb",
  "en-US": "en-gb",
  "fr-FR": "en-gb",
  "pt-BR": "en-gb",
  "pt-PT": "en-gb",
  "fi-FI": "en-gb",
  "it-IT": "en-gb",
};

class LanguageStore {
  lang: string;
  isLanguagesLoading: boolean;
  // Flag icon keys from react-country-flag https://www.npmjs.com/package/react-country-flag
  languages: Array<Language> = [];

  loginLanguages: Array<Language> = [
    {
      id: "en-GB",
      region: "en-GB",
      nativeName: "en-GB",
      flagIconKey: "GB",
      momentKey: "en-gb",
    },
    {
      id: "en-GB",
      region: "en-GB",
      nativeName: "en-GB-x-care",
      flagIconKey: "GB",
      momentKey: "en-gb",
    },
    {
      id: "sv-SE",
      region: "sv-SE",
      nativeName: "sv-SE",
      flagIconKey: "SE",
      momentKey: "sv-se",
    },
    {
      id: "sv-SE",
      region: "sv-SE",
      nativeName: "sv-SE-x-trygg",
      flagIconKey: "SE",
      momentKey: "sv-se",
    },
    {
      id: "sv-SE",
      region: "sv-SE",
      nativeName: "sv-SE-x-iot-caverion",
      flagIconKey: "SE",
      momentKey: "sv-se",
    },
    {
      id: "nb-NO",
      region: "nb-NO",
      nativeName: "nb-NO",
      flagIconKey: "NO",
      momentKey: "nb-no",
    },
    {
      id: "nb-NO",
      region: "nb-NO",
      nativeName: "nb-NO-x-care",
      flagIconKey: "NO",
      momentKey: "nb-no",
    },
    {
      id: "de-DE",
      region: "de-DE",
      nativeName: "de-DE",
      flagIconKey: "DE",
      momentKey: "de-de",
    },
  ];

  userLanguages: Array<Language> = [
    {
      id: "en-GB",
      region: "en-GB",
      nativeName: "en-GB",
      flagIconKey: "GB",
      momentKey: "en-gb",
    },
    {
      id: "sv-SE",
      region: "sv-SE",
      nativeName: "sv-SE",
      flagIconKey: "SE",
      momentKey: "sv-se",
    },
    {
      id: "nb-NO",
      region: "nb-NO",
      nativeName: "nb-NO",
      flagIconKey: "NO",
      momentKey: "nb-no",
    },
  ];

  rootStore: RootStore;

  constructor(rootStore: RootStore) {
    this.rootStore = rootStore;
    const lang = this.getStoredLanguage();
    this.lang = "";
    this.isLanguagesLoading = false;
    this.setCurrentLanguage(lang || undefined);

    makeObservable(this, {
      lang: observable,
      languages: observable,
      userLanguages: observable,
      isLanguagesLoading: observable,
      currentLanguage: computed,
      currentId: computed,
      currentName: computed,
      setCurrentLanguage: action,
      setCurrentLanguageById: action,
      setCurrentLoginLanguageById: action,
      getLanguageList: action,
      setUserLanguages: action,
    });
  }

  getStoredLanguage = () => {
    const value = localStorage.getItem(LANG_KEY);

    return value;
  };

  getLanguageList = async (user: User) => {
    const theLangs: CachedLanguages | undefined = {
      languages: [],
      expires: moment().add(1, "hours").toDate(),
    };
    const langExp = /^([a-z][a-z]-[a-zA-Z][a-zA-Z]).*$/;

    const brandingResponse = await BrandingService.GetBranding();
    const currentBrand = user.config?.show.includes("SkygdSuperAdmin")
      ? "superAdmin"
      : brandingResponse.content.domainsBrandMap[brandingResponse.domain];
    const additionalLanguages: { Language: string; Flag: string }[] = [];
    switch (currentBrand) {
      case "skyresponse":
      case "default":
        additionalLanguages.push({ Language: "de-DE-x-care-curena", Flag: "" });
        additionalLanguages.push({ Language: "sv-SE-x-trygg-sys", Flag: "" });
        additionalLanguages.push({ Language: "nb-NO-x-care-tellu", Flag: "" });
        additionalLanguages.push({
          Language: "sv-SE-x-iot",
          Flag: "",
        });
        additionalLanguages.push({
          Language: "sv-SE-x-iot-caverion",
          Flag: "",
        });
        additionalLanguages.push({ Language: "de-DE", Flag: "" });
        additionalLanguages.push({
          Language: "en-GB-x-care-scottish",
          Flag: "GB-SCT",
        });
        additionalLanguages.push({
          Language: "nl-NL",
          Flag: "",
        });
        additionalLanguages.push({
          Language: "nl-BE",
          Flag: "",
        });
        additionalLanguages.push({
          Language: "nl-NL-x-care",
          Flag: "",
        });
        additionalLanguages.push({
          Language: "nl-BE-x-care",
          Flag: "",
        });
        break;
      case "hepro":
        additionalLanguages.push({ Language: "nb-NO-x-care-hepro", Flag: "" });
        break;
      case "careium":
        additionalLanguages.push({
          Language: "nb-NO-x-care-doro",
          Flag: "",
        });
        break;
      case "telealarm":
        additionalLanguages.push({
          Language: "de-DE-x-care-telealarm",
          Flag: "",
        });
        break;
      case "chubb":
        additionalLanguages.push({
          Language: "en-GB-x-care-falkirk",
          Flag: "GB-SCT",
        });
        additionalLanguages.push({
          Language: "en-GB-x-care-scottish",
          Flag: "GB-SCT",
        });
        break;
      case "superAdmin":
        additionalLanguages.push({ Language: "nb-NO-x-care-hepro", Flag: "" });
        additionalLanguages.push({ Language: "nb-NO-x-care-tellu", Flag: "" });
        additionalLanguages.push({ Language: "nb-NO-x-care-doro", Flag: "" });
        additionalLanguages.push({
          Language: "en-GB-x-care-falkirk",
          Flag: "GB-SCT",
        });
        additionalLanguages.push({
          Language: "en-GB-x-care-scottish",
          Flag: "GB-SCT",
        });
        additionalLanguages.push({
          Language: "sv-SE-x-iot-caverion",
          Flag: "",
        });
        additionalLanguages.push({
          Language: "de-DE-x-care-telealarm",
          Flag: "",
        });
        additionalLanguages.push({
          Language: "nl-NL",
          Flag: "",
        });
        additionalLanguages.push({
          Language: "nl-BE",
          Flag: "",
        });
        additionalLanguages.push({
          Language: "nl-NL-x-care",
          Flag: "",
        });
        additionalLanguages.push({
          Language: "nl-BE-x-care",
          Flag: "",
        });
        break;
      default:
        break;
    }
    if (
      user.data?.firstLanguage &&
      !additionalLanguages.find(
        (lang) => lang.Language === user.data?.firstLanguage
      )
    ) {
      additionalLanguages.push({
        Language: user.data?.firstLanguage!,
        Flag: "",
      });
    }
    if (
      user.data?.secondLanguage &&
      !additionalLanguages.find(
        (lang) => lang.Language === user.data?.secondLanguage
      )
    ) {
      additionalLanguages.push({
        Language: user.data?.secondLanguage,
        Flag: "",
      });
    }

    // using hardcoded list for now, as for now we don't support so many languages in the new admin
    [...uniqBy([...LANG_LIST, ...additionalLanguages], "Language")].forEach(
      (lng) => {
        let flag: string = "";
        const langMatch = lng.Language.match(langExp);
        if (isEmpty(lng.Flag)) {
          if (langMatch && langMatch.length > 1) {
            flag = langMatch[1] || "";
          }
        } else {
          flag = lng.Flag;
        }
        if (langMatch && langMatch.length > 1) {
          theLangs?.languages.push({
            id: lng.Language,
            flagIconKey: FLAG_MAP[flag],
            nativeName: lng.Language,
            region: langMatch[1],
            momentKey: MOMENT_MAP[langMatch[1]],
          });
        }
      }
    );

    theLangs.languages = theLangs.languages.sort((l1, l2) => {
      if (l1.region < l2.region) {
        return 1;
      }
      if (l1.region > l2.region) {
        return -1;
      }
      return l1.nativeName.localeCompare(l2.nativeName);
    });
    this.languages = theLangs.languages;

    const userLangValue = localStorage.getItem(USER_LANG_LIST_KEY);
    if (userLangValue) {
      const theUserLangs: Language[] = JSON.parse(userLangValue);
      if (theUserLangs && theUserLangs.length > 0)
        this.userLanguages = theUserLangs;
    }
  };

  setUserLanguages = (primaryLang: string, secondaryLang: string) => {
    const newLangs: Language[] = [];

    const primaryHit = this.languages.find((lang) => lang.id === primaryLang);
    const secondaryHit = this.languages.find(
      (lang) => lang.id === secondaryLang
    );
    if (primaryHit) newLangs.push({ ...primaryHit });
    if (secondaryHit) newLangs.push({ ...secondaryHit });
    this.userLanguages = newLangs;
    localStorage.setItem(USER_LANG_LIST_KEY, JSON.stringify(newLangs));
  };

  private saveStoredLanguage = (lang: string) => {
    if (!localStorage.getItem(`i18next_res_${lang}-Languages`)) {
      this.isLanguagesLoading = true;
    }
    localStorage.setItem(LANG_KEY, lang);
  };

  setCurrentLanguage(language: string = DEFAULT_LANG) {
    if (language) {
      moment.locale(language.split("-")[0].toLowerCase());
    }
    this.lang = language;
    i18n.changeLanguage(language);
    this.saveStoredLanguage(language);
  }

  setCurrentLoginLanguageById(id: string) {
    const item = this.loginLanguages.find((l) => l.id === id);
    if (item) {
      this.setCurrentLanguage(item.id);
    }
  }

  setCurrentLanguageById(id: string) {
    const item = this.userLanguages.find((l) => l.id === id);
    if (item) {
      this.setCurrentLanguage(item.id);
    }
  }

  get currentLanguage() {
    return this.userLanguages.find((l) => l.id === this.lang) as Language;
  }

  get currentId() {
    return this.userLanguages.find((l) => l.id === this.lang)?.id || -1;
  }

  get currentName() {
    return this.userLanguages.find((l) => l.id === this.lang)?.nativeName;
  }

  get currentMomenLocale() {
    return this.userLanguages.find((l) => l.id === this.lang)?.momentKey;
  }
}

export default LanguageStore;
