import classnames from "classnames";
import {
  Dispatch,
  KeyboardEvent,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import Popup from "reactjs-popup";
import { Spacer } from "../../../components/Layout/Layout";
import {
  BaseFiltersResponse,
  FilterData,
} from "../../../core/api/common_table";
import useTranslations from "../../../core/i18n/useTranslations";
import {
  filterListToMap,
  mapToFilterList,
  validateFilterList,
} from "../../utils/customFilters";
import Button from "../Button/Button";
import Divider from "../Divider/Divider";
import Dropdown from "../Dropdown/Dropdown";
import { IDropdownItem } from "../Dropdown/DropdownItem";
import Icon from "../Icon";
import TextInput from "../Inputs/TextInput";
import { CustomFilterLinkedObjects } from "./CustomFilterLinkedObjects";
import styles from "./Table.module.css";
import {
  getFilterList,
  MoreState,
  toMoreState,
  useMoreFilter,
} from "../../../core/hooks/filters/useMoreFilter";
import { ClearFilter } from "./CleatFilters";
import { AutoDeactivatedAlarmsCheck } from "./AutoDeactivatedAlarmsCheck";
import { AutoDeactivatedFilter } from "../../../core/hooks/filters/useAutoDeactivatedFilter";
import Tooltip from "../Tooltip/Tooltip";
import { CustomAbsenceFilter } from "./CustomAbsenceFilter";
import useUser from "../../../core/user/useUser";

// Filter of this type requires special handling
export const LINKED_OBJECT_ID = 8;
export const ABSENCE_ON_OBJECT = 9;

export declare type CustomFiltersPopupActions = {
  open: () => void;
  close: () => void;
  toggle: () => void;
};

export interface Filter {
  columnId?: number;
  filterConditions?: IDropdownItem;
  value?: any;
  id: string;
  errors?: ("columnName" | "filterConditions" | "value")[];
}

export interface CustomFiltersProps {
  /** PopupPosition from reactjs-popup */
  position?: "top center" | "bottom center" | "bottom left" | "bottom right";
  filterColumns: BaseFiltersResponse;
  filters?: FilterData[];
  notAvailableFilters: FilterData[];
  maxFiltersAmount?: number;
  applyFilters: (data: FilterData[]) => void;
  translate: (col: { keyName: string; name: string }) => string;
  tableState?: any;
  setUnsavedCustomFilters: Dispatch<SetStateAction<MoreState>>;
  autoDeactivatedFilter?: AutoDeactivatedFilter;
  setAutoDeactivatedFilter?: (args: AutoDeactivatedFilter) => void;
  resetPageFilter: () => void;
  disableDropdowns?: boolean;
}

/** Popup that should appear when table rows seleted */
export const CustomFilters = ({
  position = "top center",
  filterColumns,
  filters,
  notAvailableFilters,
  maxFiltersAmount = 3,
  applyFilters,
  translate,
  tableState,
  setUnsavedCustomFilters,
  autoDeactivatedFilter,
  setAutoDeactivatedFilter,
  resetPageFilter,
  disableDropdowns,
}: CustomFiltersProps) => {
  const t = useTranslations();
  const { config } = useUser();
  const { setMoreFilter, resetMoreFilter } = useMoreFilter(tableState);

  const [isOpen, isOpenSet] = useState(false);

  const [filterItems, setFilters] = useState<Map<string, Filter>>(new Map());
  const [initialFilters, setInitialFilters] = useState<Map<string, Filter>>(
    new Map()
  );

  const userAbsencePermission = config?.show.includes("UserAbsence");

  useEffect(() => {
    let preDefinedFilters: Map<string, Filter> = new Map();
    if (filters) {
      preDefinedFilters = filterListToMap(filters);
    }
    if (tableState) {
      preDefinedFilters = filterListToMap(getFilterList(tableState));
    }
    setFilters(preDefinedFilters);
    setInitialFilters(preDefinedFilters);
    setUnsavedCustomFilters(toMoreState(mapToFilterList(preDefinedFilters)));
    applyFilters(mapToFilterList(preDefinedFilters));
  }, []);

  useEffect(() => {
    // Reset when filters are cleared
    if (!filters) {
      setFilters(new Map());
      setUnsavedCustomFilters({});
    }
  }, [filters]);

  const numInlineFilterItems = useMemo(
    () =>
      Array.from(filterItems).filter(
        ([_, item]) =>
          item.columnId &&
          +item.columnId !== LINKED_OBJECT_ID &&
          +item.columnId !== ABSENCE_ON_OBJECT
      ).length,
    [filterItems]
  );

  const filterColumnsItems = useMemo(
    () =>
      filterColumns.map((col) => {
        const notAvailable = !!notAvailableFilters.find(
          (el) => col.id === el.id
        );

        const alreadySelected = !!Array.from(filterItems).find(
          ([, item]) => item.columnId && col.id === +item.columnId
        );

        const isInSelectedFilters = filters?.find((el) => col.id === el.id);

        return {
          name: translate(col),
          id: col.id,
          isSelected: false,
          disabled: (notAvailable && !isInSelectedFilters) || alreadySelected,
        };
      }),
    [filterColumns, notAvailableFilters, filterItems]
  );

  const showRows = () =>
    !!filterItems.size || autoDeactivatedFilter?.hideautodeactivated;

  const addFilter = useCallback(() => {
    const newId = Math.random().toString(36).substr(2, 9);

    const newItem = {
      id: newId,
      columnId: filterColumnsItems.find((i) => !i.disabled)?.id,
    };

    filterItems.set(newId, newItem);
    setUnsavedCustomFilters(toMoreState(mapToFilterList(filterItems)));
    return setFilters(new Map(filterItems));
  }, [setFilters, filterItems, filterColumnsItems]);

  const onApplyFilters = useCallback(() => {
    const { updatedFilters, hasErrors } = validateFilterList(filterItems);
    if (hasErrors) {
      setFilters(updatedFilters);
    } else {
      const filterList = mapToFilterList(filterItems);
      setInitialFilters(filterItems);
      setMoreFilter(filterList);
      applyFilters(filterList);
      setUnsavedCustomFilters(toMoreState(filterList));
      isOpenSet(false);
      resetPageFilter();
    }
  }, [filterItems, applyFilters]);

  const onCancel = () => {
    setFilters(filterListToMap(filters || []));
    setUnsavedCustomFilters(toMoreState(filters || []));
    isOpenSet(false);
  };

  const handleRemoveFilter = (item: Filter) => {
    const updatedFilterMap = new Map(filterItems);
    updatedFilterMap.delete(item.id);

    setFilters(updatedFilterMap);
    setUnsavedCustomFilters(toMoreState(mapToFilterList(updatedFilterMap)));
  };

  const triggerButton = () => (
    <div className={styles.customFilterTrigger}>
      {showRows() ? (
        <Button
          text={`${
            filterItems.size +
            (autoDeactivatedFilter?.hideautodeactivated ? 1 : 0)
          } ${
            filterItems.size +
              (autoDeactivatedFilter?.hideautodeactivated ? 1 : 0) >
            1
              ? t("Common:custom_filters_many")
              : t("Common:custom_filters_single")
          }`}
          image="filter"
          variant="active"
          onClick={() => isOpenSet(!isOpen)}
          dataCy="more_filters_btn"
        />
      ) : (
        <Button
          text={t("Common:custom_filters_more")}
          image="filter"
          variant="secondary"
          onClick={() => isOpenSet(!isOpen)}
          dataCy="more_filters_btn"
        />
      )}
    </div>
  );

  const renderFilterLine = (item: Filter) => {
    if (item.columnId) {
      if (
        +item.columnId === LINKED_OBJECT_ID ||
        +item.columnId === ABSENCE_ON_OBJECT
      ) {
        return null;
      }

      const items = filterColumnsItems.map((i) => ({
        ...i,
        isSelected: i.id === parseInt(String(item?.columnId || -1), 10),
      }));

      const selectedItem = items.find((i) => i.isSelected);

      const handleEnterPress = (event: KeyboardEvent<HTMLElement>) => {
        if (event.key === "Enter") {
          onApplyFilters();
        }
      };

      return (
        <div key={item.id} className={styles.filterRow}>
          <div className={styles.filterColumn}>
            <Dropdown
              width={200}
              items={filterColumnsItems
                .filter((i) => i.id !== 8 && i.id !== 9)
                .map((i) => ({
                  ...i,
                  isSelected: i.id === item?.columnId,
                }))}
              placeholder=""
              dropdownInPopup
              selectedItem={selectedItem}
              invalid={item.errors?.includes("columnName")}
              onSelectItem={({ id }) => {
                setFilters((filtersMap) => {
                  item.columnId = id as number;
                  item.filterConditions = undefined;
                  filtersMap.set(item.id, item);
                  setUnsavedCustomFilters(
                    toMoreState(mapToFilterList(filtersMap))
                  );
                  return new Map(filtersMap);
                });
              }}
            />
          </div>
          <div className={classnames(styles.filterColumn, styles.value)}>
            <TextInput
              value={item.value as string}
              invalid={item.errors?.includes("value")}
              onKeyDown={handleEnterPress}
              onChange={(e) => {
                setFilters((filtersMap) => {
                  item.value = e.target.value;
                  item.errors = undefined;
                  filtersMap.set(item.id, item);
                  setUnsavedCustomFilters(
                    toMoreState(mapToFilterList(filtersMap))
                  );
                  return new Map(filtersMap);
                });
              }}
              validationError={
                item.errors?.includes("value")
                  ? t("Errors:input_field_required")
                  : undefined
              }
            />
          </div>
          <Tooltip
            position="right center"
            variant="light"
            text={t("Objects:remove_from")}
            trigger={() => (
              <div
                role="button"
                aria-label="Remove filter"
                className={classnames(styles.filterColumn, styles.action)}
                onClick={() => handleRemoveFilter(item)}
                onKeyUp={(event: KeyboardEvent) => {
                  if (event.key === " ") {
                    handleRemoveFilter(item);
                  }
                }}
                tabIndex={0}
              >
                <Icon name="x" />
              </div>
            )}
          />
        </div>
      );
    }
    return null;
  };

  return (
    <>
      <Popup
        open={isOpen}
        closeOnDocumentClick={false}
        closeOnEscape
        position={position}
        arrow={false}
        modal={false}
        repositionOnResize={false}
        className={classnames({
          customFilters: true,
        })}
        trigger={triggerButton}
      >
        <div className={styles.popupBody} data-cy="more_filters_popup">
          <div className={styles.customFiltersBlock}>
            {filterColumnsItems.find((i) => +i.id === LINKED_OBJECT_ID) && (
              <>
                <CustomFilterLinkedObjects
                  item={{
                    id: "linked-objects",
                    columnId: filterColumnsItems.find(
                      (i) => +i.id === LINKED_OBJECT_ID
                    )?.id,
                    // @ts-ignore
                    value: mapToFilterList(filterItems).find(
                      (item) => item.id && +item.id === LINKED_OBJECT_ID
                    )?.value,
                  }}
                  onChange={(item) => {
                    setFilters((filtersMap) => {
                      let list = mapToFilterList(filtersMap);
                      list = list.filter(
                        (listItem) => +listItem.id !== LINKED_OBJECT_ID
                      );
                      const newFiltersMap = filterListToMap(list);
                      if (!item.value) {
                        newFiltersMap.delete(item.id);
                      } else {
                        newFiltersMap.set(item.id, item);
                      }
                      setUnsavedCustomFilters(
                        toMoreState(mapToFilterList(newFiltersMap))
                      );
                      return new Map(newFiltersMap);
                    });
                  }}
                />
              </>
            )}
            {userAbsencePermission &&
              filterColumnsItems.find((i) => +i.id === ABSENCE_ON_OBJECT) && (
                <>
                  <Spacer size={16} />
                  <CustomAbsenceFilter
                    item={{
                      id: "object-absence",
                      columnId: filterColumnsItems.find(
                        (i) => +i.id === ABSENCE_ON_OBJECT
                      )?.id,
                      // @ts-ignore
                      value: mapToFilterList(filterItems).find(
                        (item) => item.id && +item.id === ABSENCE_ON_OBJECT
                      )?.value,
                    }}
                    onChange={(item) => {
                      setFilters((filtersMap) => {
                        let list = mapToFilterList(filtersMap);
                        list = list.filter(
                          (listItem) => +listItem.id !== ABSENCE_ON_OBJECT
                        );
                        const newFiltersMap = filterListToMap(list);
                        if (!item.value) {
                          newFiltersMap.delete(item.id);
                        } else {
                          newFiltersMap.set(item.id, item);
                        }
                        setUnsavedCustomFilters(
                          toMoreState(mapToFilterList(newFiltersMap))
                        );
                        return new Map(newFiltersMap);
                      });
                    }}
                  />
                </>
              )}
            {!disableDropdowns && (
              <>
                <Spacer size={16} />
                <Divider />
                <Spacer size={16} />
              </>
            )}
            {autoDeactivatedFilter && setAutoDeactivatedFilter && (
              <>
                <AutoDeactivatedAlarmsCheck
                  autoDeactivatedFilter={autoDeactivatedFilter}
                  setAutoDeactivatedFilter={setAutoDeactivatedFilter}
                />
                <Spacer size={16} />
                <Divider />
                <Spacer size={16} />
              </>
            )}

            {numInlineFilterItems > 0 && (
              <>
                <div className={styles.filterHeader}>
                  <div
                    className={classnames(
                      styles.filterColumn,
                      styles.columnName
                    )}
                  >
                    {t("Common:custom_filters_column_name")}
                  </div>
                  <div
                    className={classnames(styles.filterColumn, styles.value)}
                  >
                    {t("Common:custom_filters_column_value")}
                  </div>
                  <div
                    className={classnames(styles.filterColumn, styles.action)}
                  />
                </div>
                {Array.from(filterItems).map(([_, item]) =>
                  renderFilterLine(item)
                )}
              </>
            )}
            {maxFiltersAmount > filterItems.size && !disableDropdowns && (
              <Button
                text={t("Common:custom_filters_add")}
                variant="link"
                iconVariant="primary"
                image="plus"
                onClick={addFilter}
                className={styles.addFilterButton}
                dataCy="add_more_filters_btn"
              />
            )}
            <div>
              <Button
                variant="primary"
                onClick={onApplyFilters}
                text={t("Common:custom_filters_apply")}
                className={styles.applyFilterButton}
                disabled={
                  filterItems.size === 0 &&
                  filterItems.size === initialFilters.size
                }
                dataCy="apply_more_filters_btn"
              />
              <Button
                variant="secondary"
                onClick={onCancel}
                text={t("Common:cancel")}
                dataCy="cancel_more_filters_btn"
              />
              <ClearFilter
                text={t("Common:labels_clear_filters")}
                onClearClick={() => {
                  resetMoreFilter();
                  setFilters(new Map());
                  setUnsavedCustomFilters({});
                  applyFilters(mapToFilterList(new Map()));
                }}
                filterToWatch={
                  (filters?.length || 0) > 0 ? mapToFilterList(filterItems) : {}
                }
                customStyles={{ float: "right", marginTop: "16px" }}
              />
            </div>
          </div>
        </div>
      </Popup>
    </>
  );
};
