import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import useTranslations from "../../../../core/i18n/useTranslations";
import { MenuAlternativesController } from "../../../../components/MenuAlternatives/MenuAlternativesController";

export const MenuAlternatives = ({ adminId }: { adminId?: number }) => {
  const t = useTranslations();

  return (
    <Column style={{ width: "784px" }} type="top" align="start">
      <h1>{t("Administrator:menu_alternatives_title")}</h1>
      <Spacer size={8} />
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Administrator:menu_alternatives_description")}
      </p>

      <Spacer size={32} />

      <Row style={{ width: "100%", flex: 0 }}>
        {adminId && (
          <MenuAlternativesController
            alternativesType="adminId"
            ownerId={adminId}
          />
        )}
      </Row>
    </Column>
  );
};
