import classnames from "classnames";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { ReactNode, useState } from "react";
import { Spacer } from "../../../components/Layout/Layout";
import { Button } from "../Button/Button";
import Dropdown from "../Dropdown/Dropdown";
import Icon from "../Icon";
import { PopupContextMenu } from "../Menus/PopupContextMenu/PopupContextMenu";
import { PopupContextMenuLinkButton } from "../Menus/PopupContextMenu/PopupContextMenuLinkButton";
import NoItems, { NoItemsProps } from "./NoItems";
import styles from "./Table.module.css";
import { TableCell } from "./TableCell";
import useTranslations from "../../../core/i18n/useTranslations";
import PrimeTooltip from "../PrimeTooltip/PrimeTooltip";
import usePageState from "../../../core/pagestate/usePageState";

export interface ColumnType {
  id?: string;
  name: string;
  selected: boolean;
  subColumns?: Map<string, ColumnType[]>;
}

export interface PaginationResult<TItem> {
  items: TItem[];
  pagination: {
    offset: number;
    limit: number;
    total: number;
  };
}

export interface Pagination {
  offset: number;
  limit: number;
}

export type SortOrderType = 1 | 0 | -1 | undefined | null;

export type PageSizeType = 10 | 20 | 50 | 100;
const pageSizes = [10, 20, 50, 100];

export interface SortOrderParams {
  field: string;
  sortOrder: SortOrderType;
}

export interface TableColumn<TRowData> {
  key?: string;

  /* Column header */
  header?: string;

  editable?: boolean;

  /** Returns field/cell value for specific column */
  field?: (rowData: TRowData, rowIndex: number) => TRowData[keyof TRowData];

  /** Returns custom field/cell template (ReactNode) for specific column */
  fieldTemplate?: (rowData: TRowData, rowIndex: number) => ReactNode;

  /** When set, column becomes sortable by the field value
   * NB: Sorting is supported only for 1st level fields. I.e. nested object fields can't be sorted at the moment.
   */
  sortByField?: keyof TRowData;

  style?: object;
}

export interface RowAction<TRowData> {
  icon: string | ((rowData: TRowData) => string);
  text?: string | ((rowData: TRowData) => string);
  iconVariant?: "primary" | "secondary";
  iconSize?: number;
  onClick?: (
    rowData: TRowData,
    rowIndex?: number,
    e?: any
  ) => void | Promise<void>;
  onHover?: (rowData: TRowData, rowIndex?: number, e?: any) => void;
  onLeave?: (rowData: TRowData, rowIndex?: number, e?: any) => void;
  hideForRow?: (rowData: TRowData) => boolean;
  alwaysShow?: boolean;
}

export interface TableProps<TRowData> {
  tableRef?: any;
  columns: TableColumn<TRowData>[];

  /** Pass items to render simple table with client-side pagination, filtering, sorting (in-memory) and without lazy loading */
  items?: TRowData[];

  /** Enables lazy loading:
   * disabled - table with client-side pagination, filtering, sorting (in-memory)
   * enabled - table with lazy loading (server-side pagination, filtering, sorting)
   */
  withLazyLoading?: boolean;

  /** Enables pagination controls */
  withPagination?: boolean;
  /** Enables pagination through show more button. NB: available only when withLazyLoading=true  */
  withShowMore?: boolean;

  /** Set after how many items scroll must appear */
  showScrollAfter?: number;

  /** Pass paginated items to render table with lazy loading (server-side pagination, filtering, sorting) */
  paginatedItems?: PaginationResult<TRowData>;

  resizableColumns?: boolean;

  /** Pass the name to save and retrieve the state of table between pages (column width, etc.) */
  tableName?: string;

  /** Only for lazy loading */
  onPageChange?: (nextPage: { offset: number; limit: number }) => void;
  /** Only for lazy loading and withShowMore=true */
  onShowMoreClick?: (nextPage: { offset: number; limit: number }) => void;
  isLoading?: boolean;
  withRowSelection?: boolean;
  withRowReorder?: boolean;
  reorderableRows?: boolean;
  reorderableColumns?: boolean;
  onRowReorder?: (result: any) => void;
  onRowClick?: (item: TRowData) => void;
  onRowSelectionChange?: (selectedItems: TRowData[]) => void;
  onAllRowsSelect?: (selectedItems: TRowData[]) => void;
  rowActions?: Array<RowAction<TRowData>>;
  showRowsActionsOnHover?: boolean;
  rowActionsFixed?: boolean;
  rowActionsColWidth?: string | number;
  rowActionContextMenu?: boolean;
  hideHeader?: boolean;
  spaceRows?: boolean;
  hideEmptyMessage?: boolean;
  noRowsMessage?: string;
  /** Fired when sort order changes for any column. */
  sortParams?: SortOrderParams;
  onSortChange?: (sortParams?: SortOrderParams) => void;
  showTooltip?: boolean;

  /** Fired when user hides/shows columns */

  noItemsMessage?: NoItemsProps;

  setClearItemsFunction?(clearFunction: Function): void;

  showRowHover?: boolean;
  totalResultsStyles?: any;
}

export const Table = <TRowData,>({
  tableRef,
  columns,
  tableName,
  items = undefined,
  withLazyLoading = false,
  withPagination = false,
  withShowMore = false,
  paginatedItems = undefined,
  onPageChange = undefined,
  onShowMoreClick = undefined,
  isLoading = undefined,
  onRowClick = undefined,
  withRowSelection = false,
  withRowReorder = false,
  reorderableRows = false,
  reorderableColumns = false,
  onRowReorder = undefined,
  onRowSelectionChange = undefined,
  onAllRowsSelect = undefined,
  rowActions = undefined,
  resizableColumns = true,
  showRowsActionsOnHover = true,
  rowActionsFixed = false,
  rowActionsColWidth = undefined,
  rowActionContextMenu = false,
  hideHeader = false,
  spaceRows = false,
  hideEmptyMessage = false,
  noRowsMessage,
  sortParams = undefined,
  onSortChange = undefined,
  showScrollAfter = undefined,
  showRowHover = false,
  showTooltip = true,
  totalResultsStyles = {},
  noItemsMessage = {
    icon: "eye-off",
    title: "Nothing to see here",
    subTitle: "There’s currently no data to show in the table",
  },
  setClearItemsFunction,
}: TableProps<TRowData>) => {
  const t = useTranslations();
  const pageState = usePageState();
  noRowsMessage = noRowsMessage ?? t("Table:no_records_found");
  const [selectedItems, setSelectedItems] = useState<any[]>([]);
  const clearItems = () => {
    setSelectedItems([]);
  };
  if (setClearItemsFunction) {
    setClearItemsFunction(clearItems);
  }
  // pagination
  const pageSizeOptions = pageSizes.map((size) => ({
    id: size,
    value: size,
    name: `${size}`,
    isSelected: false,
  }));
  let isAllCheckboxClicked = false;
  const selectedPageSize =
    pageSizeOptions.find((x) => x.value === paginatedItems?.pagination.limit) ||
    pageSizeOptions[0];

  const tableRows = withLazyLoading ? paginatedItems?.items || [] : items || [];

  const isEmptyTable = tableRows.length === 0;
  const showNoItemsMessage = isEmptyTable && !isLoading && !hideEmptyMessage;
  const showShowMoreButton =
    withShowMore &&
    withLazyLoading &&
    paginatedItems &&
    paginatedItems.pagination.offset + paginatedItems.pagination.limit <
      paginatedItems.pagination.total;

  const tableTexts = {
    rowsPerPage: t("Table:rows_per_page"),
    showing: t("Table:showing"),
    of: t("Table:of"),
    results: t("Table:results"),
    showMore: t("Table:show_more"),
  };

  return (
    <div className={styles.tableContainer}>
      {!!withShowMore && paginatedItems ? (
        <span
          data-cy="total_result"
          className={styles.totalResult}
          style={totalResultsStyles}
        >
          {tableTexts.showing} {paginatedItems.items.length}{" "}
          {tableTexts.results}
        </span>
      ) : null}

      {/* Table */}
      <DataTable
        ref={tableRef}
        stateStorage="custom"
        onColumnResizeEnd={(e) => {
          pageState.setColumnSize(e.delta);
        }}
        onStateSave={(state: any) => {
          if (tableName) {
            // Add rest params from state if needed
            const updatedState = {
              columnWidths: state.columnWidths,
              multiSortMeta: state.multiSortMeta,
              selection: state.selection,
            };
            let columnWidthArray: string[] =
              updatedState.columnWidths.split(",");
            // set column width for checkboxes
            if (withRowSelection) {
              columnWidthArray[0] = "48";
            }
            // set column width for arrow icon on organisations land page
            if (tableName === "table-organisations") {
              columnWidthArray[1] = "48";
            }
            // This is necessary due to the state of the table
            // which interrupts inline styles of row actions.
            // Therefore, the column width is set immediately to the table state
            if (rowActions && rowActionsColWidth) {
              columnWidthArray[columnWidthArray.length - 1] =
                rowActionsColWidth.toString();
            }
            columnWidthArray = columnWidthArray.map((column: string) => {
              if (column === "0") {
                return "64";
              }
              return column;
            });
            updatedState.columnWidths = columnWidthArray.join(",");
            window.localStorage.setItem(
              tableName,
              JSON.stringify(updatedState)
            );
          }
        }}
        customRestoreState={() =>
          tableName
            ? JSON.parse(window.localStorage.getItem(tableName) ?? "{}")
            : "{}"
        }
        value={tableRows}
        selection={selectedItems}
        emptyMessage={noRowsMessage}
        onRowClick={(e) => {
          const { target } = e.originalEvent;
          const targetClass = (target as any).className;

          if (!onRowClick || targetClass?.includes?.("p-selection-column")) {
            return;
          }
          onRowClick(e.data as TRowData);
        }}
        // handle row selection
        selectionMode={withRowSelection ? "checkbox" : undefined}
        onAllRowsSelect={() => {
          if (onAllRowsSelect) {
            isAllCheckboxClicked = true;
          }
        }}
        onSelectionChange={(e: any) => {
          setSelectedItems(e.value);
          if (isAllCheckboxClicked) {
            onAllRowsSelect?.(e.value as TRowData[]);
          } else {
            onRowSelectionChange?.(e.value as TRowData[]);
          }
        }}
        className={classnames("customDatatableContainer", {
          [styles.hideHeader]: hideHeader,
          hideSelection: onRowClick === undefined && !showRowHover,
        })}
        tableClassName={`customDatatable${spaceRows ? " spaceRows" : ""}`}
        removableSort
        rowHover
        // NB: to enable scroll you must set width for th/td
        scrollable
        scrollHeight={showScrollAfter ? `${showScrollAfter * 67}px` : ""}
        resizableColumns={resizableColumns}
        columnResizeMode="expand"
        // sort
        sortMode="single"
        sortField={sortParams?.field}
        sortOrder={sortParams?.sortOrder}
        onSort={(e) => {
          if (!onSortChange) {
            return;
          }

          if (
            e.sortField === sortParams?.field &&
            e.sortOrder === sortParams?.sortOrder
          ) {
            return;
          }

          if (!e.sortField || e.sortOrder === 0) {
            onSortChange(undefined);

            return;
          }

          onSortChange({
            field: e.sortField,
            sortOrder: e.sortOrder,
          });
        }}
        reorderableColumns={reorderableColumns}
        reorderableRows={reorderableRows}
        onRowReorder={onRowReorder}
        // lazy loadig and pagination
        lazy={withLazyLoading} // means that loading is happened each time pagination, sort, filters change
        loading={isLoading}
        paginator={withPagination}
        first={paginatedItems?.pagination?.offset ?? 0} // index of the first record
        rows={withShowMore ? items?.length : selectedPageSize.id} // how many rows to display
        totalRecords={
          withLazyLoading ? paginatedItems?.pagination.total ?? 0 : undefined
        }
        // if not set - pagination is handled in-memory
        onPage={
          withLazyLoading
            ? (e) => {
                const { first: offset, rows: limit } = e;

                if (withPagination && onPageChange) {
                  onPageChange({ offset, limit });
                }
              }
            : undefined
        }
        paginatorTemplate={{
          layout:
            "CurrentPageReport RowsPerPageDropdown PrevPageLink NextPageLink",
          FirstPageLink: null,
          PrevPageLink: (options) => (
            <Button
              ariaLabel="previous page"
              dataCy="previous page"
              variant="secondary"
              image="chevron-left"
              className="ml-32"
              disabled={options.disabled}
              onClick={(e) => options.onClick(e)}
            />
          ),
          PageLinks: null,
          NextPageLink: (options) => (
            <Button
              ariaLabel="next page"
              dataCy="next page"
              variant="secondary"
              image="chevron-right"
              className="ml-8"
              disabled={options.disabled}
              onClick={(e) => options.onClick(e)}
            />
          ),
          LastPageLink: null,
          RowsPerPageDropdown: () => (
            <span className="rowsPerPageDropdown">
              <span data-cy="rowsPerPageText" className="mr-8">
                {tableTexts.rowsPerPage}
              </span>
              <Dropdown
                dataCy="rowsPerPageDropdown"
                width={70}
                items={pageSizeOptions}
                onSelectItem={(item) => {
                  onPageChange?.({
                    offset: 0,
                    limit: item.id as number,
                  });
                }}
                selectedItem={selectedPageSize}
                placeholder=""
              />
            </span>
          ),
          CurrentPageReport: (options) => (
            <span data-cy={"table_results"}>
              {tableTexts.showing} {options.first}-{options.last}{" "}
              {tableTexts.of} {options.totalRecords} {tableTexts.results}
            </span>
          ),
        }}
      >
        {onRowReorder && <Column rowReorder style={{ width: "3em" }} />}
        {/* Select checkbox column */}
        {withRowSelection ? (
          <Column selectionMode="multiple" style={{ width: "48px" }} frozen />
        ) : null}

        {/* Row reorder column */}
        {withRowReorder ? (
          <Column
            rowReorder
            rowReorderIcon="pi pi-pause"
            className={styles.sortableRowCol}
            style={{
              width: "48px",
            }}
          />
        ) : null}

        {/* Data columns */}
        {columns.map((col, index) => (
          <Column
            pt={{
              sortIcon: {
                style: {
                  color:
                    col?.sortByField === sortParams?.field &&
                    !!sortParams?.sortOrder
                      ? "var(--Primary-700)"
                      : "var(--Grey-700)",
                },
              },
            }}
            key={`column_${col.field}`}
            // column needs to have the default min width
            // to be displayed properly
            style={{ minWidth: "64px", ...col.style }}
            headerClassName={classnames({
              [styles.sortActive]:
                col?.sortByField === sortParams?.field &&
                !!sortParams?.sortOrder,
            })}
            header={
              <span className={styles.sortableHeader}>
                {col.header && (
                  <>
                    <PrimeTooltip target={`#header${index}`} position="top" />
                    <span data-pr-tooltip={col.header} id={`header${index}`}>
                      {col.header}
                    </span>
                  </>
                )}
              </span>
            }
            // NB: to enable sortable, field or sortField must be specified
            sortable={col.sortByField !== undefined}
            sortField={col.sortByField?.toString()}
            body={(rowData: TRowData, props) => {
              const rowIndex: number = (props as any).rowIndex || 0;
              let fieldValue: ReactNode | null = null;
              if (col.field) {
                fieldValue = <TableCell value={col.field(rowData, rowIndex)} />;
              } else if (col.fieldTemplate) {
                fieldValue = col.fieldTemplate(rowData, rowIndex);
              }

              return fieldValue;
            }}
            className={classnames({ editableCell: col.editable })}
          />
        ))}

        {/* Actions columns */}
        {rowActions && rowActions.length !== 0 ? (
          <Column
            frozen={rowActionsFixed}
            alignFrozen="right"
            className={classnames({
              [styles.tableActionsCol]: true,
              ...(!rowActionsColWidth && rowActionContextMenu
                ? {
                    [styles.tableActionsCol_N_1]: true,
                  }
                : {}),
              ...(!rowActionsColWidth && !rowActionContextMenu
                ? {
                    [styles.tableActionsCol_N_1]: rowActions.length === 1,
                    [styles.tableActionsCol_N_2]: rowActions.length === 2,
                    [styles.tableActionsCol_N_3]: rowActions.length === 3,
                    [styles.tableActionsCol_N_4]: rowActions.length === 4,
                    [styles.tableActionsCol_N_5]: rowActions.length === 5,
                  }
                : {}),
            })}
            body={(rowData: TRowData, props) => {
              const rowIndex: number = props.rowIndex || 0;
              const tooltipText = (action: RowAction<TRowData>) =>
                typeof action.text === "string"
                  ? action.text
                  : action?.text?.(rowData);
              const btnElement = (action: RowAction<TRowData>) => {
                const iconName =
                  typeof action.icon === "string"
                    ? action.icon
                    : action.icon(rowData);
                return (
                  <>
                    {showTooltip && (
                      <PrimeTooltip target={`#${iconName}_${rowIndex}`} />
                    )}
                    <button
                      id={`${iconName}_${rowIndex}`}
                      data-cy={action.text}
                      data-pr-at="center top-6"
                      data-pr-tooltip={tooltipText(action)}
                      data-pr-position="top"
                      key={`action_${action.text || action.icon}`}
                      type="button"
                      className={classnames({
                        [styles.tableRowActionButton]: true,
                        customDatatableRowActionsOnlyOnHover: action.alwaysShow
                          ? false
                          : showRowsActionsOnHover,
                      })}
                      onClick={(event) => {
                        event.stopPropagation(); // Prevents table row selection

                        if (action.onClick)
                          action.onClick(rowData, rowIndex, event);
                      }}
                      onFocus={() => {}} // Needed for mouse events
                      onMouseOver={(event) => {
                        if (action.onHover)
                          action.onHover(rowData, rowIndex, event);
                      }}
                      onMouseLeave={(event) => {
                        if (action.onLeave)
                          action.onLeave(rowData, rowIndex, event);
                      }}
                      aria-label={tooltipText(action) ?? iconName}
                    >
                      <Icon
                        name={iconName}
                        size={action.iconSize ?? 24}
                        color={
                          action.iconVariant === "secondary"
                            ? "Grey-400"
                            : "Primary-700"
                        }
                      />
                    </button>
                  </>
                );
              };
              return (
                (!rowActionContextMenu && (
                  <span
                    className={classnames({
                      [styles.tableRowActions]: true,
                    })}
                  >
                    {rowActions.map((action) => {
                      if (action?.hideForRow?.(rowData)) {
                        return null;
                      }
                      return btnElement(action);
                    })}
                  </span>
                )) ||
                (rowActionContextMenu && (
                  <PopupContextMenu
                    className="autoWidth"
                    position="bottom left"
                    trigger={() => (
                      <button
                        type="button"
                        className={styles.rowActionContextMenuBtn}
                      >
                        <Icon
                          name="dots-horizontal"
                          size={24}
                          color="Grey-400"
                        />
                      </button>
                    )}
                  >
                    {rowActions.map((action) => {
                      if (action?.hideForRow?.(rowData)) {
                        return null;
                      }
                      const iconName =
                        typeof action.icon === "string"
                          ? action.icon
                          : action.icon(rowData);
                      return (
                        <PopupContextMenuLinkButton
                          key={tooltipText(action) ?? iconName}
                          icon={iconName}
                          text={action.text || ""}
                          onClick={(e) => {
                            e?.stopPropagation(); // prevents table row selection
                            if (action.onClick)
                              action.onClick(rowData, rowIndex);
                          }}
                        />
                      );
                    })}
                  </PopupContextMenu>
                ))
              );
            }}
          />
        ) : null}
      </DataTable>

      {/* No items message */}
      {showNoItemsMessage ? (
        <NoItems
          icon={noItemsMessage.icon}
          title={noItemsMessage.title}
          subTitle={noItemsMessage.subTitle}
        />
      ) : null}

      {/* Show more */}
      {showShowMoreButton ? (
        <div className={styles.tableBottomCotrolsContainer}>
          <Button
            dataCy="show_more"
            variant="secondary"
            text={tableTexts.showMore}
            onClick={() => {
              if (withLazyLoading && onShowMoreClick) {
                const limit = paginatedItems?.pagination?.limit || 10;
                const offset = paginatedItems?.pagination?.offset || 0;
                onShowMoreClick({
                  offset: offset + limit,
                  limit,
                });
              }
            }}
            disabled={isLoading}
          />
        </div>
      ) : (
        <Spacer size={32} />
      )}
    </div>
  );
};
export default Table;
