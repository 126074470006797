import { TFunction } from "i18next";
import { Control, Controller, UseFormTrigger } from "react-hook-form";
import { NewRoleTemplate } from "../../core/api/roleTemplates/types";
import Tree, { ITreeData } from "../../ui-lib/components/Hierarchy/Tree";
import { Column } from "../Layout/Layout";

export const SelectOrganization = ({
  organisations,
  control,
  errors,
  t,
  setValue,
  trigger,
  setSelectedOrganization,
  disable,
  respondent,
}: {
  control: Control<NewRoleTemplate>;
  errors: any;
  t: TFunction;
  setValue: Function;
  organisations: ITreeData[];
  trigger: UseFormTrigger<NewRoleTemplate>;
  setSelectedOrganization: any;
  disable: boolean;
  respondent: boolean;
}) => (
  <>
    <Column>
      <Controller
        name="organizationId"
        control={control}
        rules={{ required: true }}
        render={({ field }) => (
          <Tree
            required
            treeRef={field.ref}
            selectedTreeItem={field.value}
            label={
              respondent ? t("Common:organisation") : t("Common:belongs_to")
            }
            placeholder={t("Common:select_organisation")}
            items={organisations}
            disabled={!organisations.length || disable}
            onSelectItem={(selectedOrg?: ITreeData) => {
              setValue(field.name, selectedOrg?.key as number);
              setSelectedOrganization(selectedOrg?.key);
              trigger(field.name);
            }}
            validationError={
              errors.organizationId && t("Errors:input_field_required")
            }
          />
        )}
      />
    </Column>
  </>
);
