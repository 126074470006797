import { Controller } from "react-hook-form";
import { useEffect, useRef } from "react";
import he from "he";
import { IOrganizationDetails } from "../../../core/api/organizations/types";
import useTranslations from "../../../core/i18n/useTranslations";
import {
  useObjectForm,
  useObjectFormRef,
} from "../../../core/SaveObjectContext/hooks";
import TextInput from "../../../ui-lib/components/Inputs/TextInput";
import { Row, Spacer } from "../../../components/Layout/Layout";
import Divider from "../../../ui-lib/components/Divider/Divider";
import ExpandableList from "../../../ui-lib/components/ExpandableList/ExpandableList";
import Checkbox from "../../../ui-lib/components/Checkbox/Checkbox";
import styles from "./OrganizationsGeneral.module.css";
import {
  validateEmailIfExists,
  validateEmergencyPhoneNumberIfExists,
  validatePhoneNumberIfExists,
} from "../../../core/helpers/validation";
import useUser from "../../../core/user/useUser";
import TextArea from "../../../ui-lib/components/Inputs/Textarea";

export const OrganizationGeneralForm = ({
  defaultValues,
  onSubmit,
}: {
  defaultValues?: IOrganizationDetails;
  onSubmit: (values: IOrganizationDetails) => Promise<void>;
}) => {
  const t = useTranslations();
  const formRef = useObjectFormRef();
  const expandedAnchorRef = useRef<any>();
  const user = useUser();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    trigger,
    setValue,
  } = useObjectForm<IOrganizationDetails>({
    defaultValues,
    shouldUnregister: true,
  });

  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues]);

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit(async (values) => {
        await onSubmit(values);
        reset(values);
      })}
      style={{ width: "100%", maxWidth: "768px" }}
    >
      <Row className={styles.rowWithValidation}>
        <Controller
          name="name"
          control={control}
          rules={{
            required: true,
            minLength: 1,
            maxLength: 60,
          }}
          render={({ field }) => (
            <TextInput
              required
              inputRef={field.ref}
              label={t("Common:name_label")}
              placeholder={t("Organizations:org_col_name")}
              value={field.value}
              onChange={(e) => {
                setValue(field.name, e.target.value);
                trigger(field.name);
              }}
              validationError={
                errors?.name && t("Errors:input_organization_name")
              }
            />
          )}
        />
        <Spacer size={32} />
        <Controller
          name="organizationNumber"
          control={control}
          render={({ field }) => (
            <TextInput
              label={t("Organizations:organization_number")}
              placeholder={t("Common:digit_number")}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </Row>

      <Spacer size={16} />

      <Row style={{ width: "100%" }} className={styles.rowWithValidation}>
        <Controller
          name="customerNumber"
          control={control}
          rules={{
            validate: (value) => {
              if (value && value.length <= 5) {
                return validateEmergencyPhoneNumberIfExists(value);
              }
              return validatePhoneNumberIfExists(value);
            },
          }}
          render={({ field }) => (
            <TextInput
              inputRef={field.ref}
              label={t("Common:labels_phone_number")}
              placeholder="+46123456789"
              description={
                !errors.customerNumber
                  ? t("Common:telephone_placeholder")
                  : undefined
              }
              value={field.value}
              onChange={field.onChange}
              validationError={
                errors?.customerNumber &&
                t("Errors:ValidationHint_TelephoneNumber")
              }
            />
          )}
        />

        <Spacer size={32} />
        <Controller
          name="name"
          control={control}
          render={() => (
            <TextInput
              disabled
              label={
                defaultValues?.parentOrganizationName
                  ? t("Common:belongs_to")
                  : t("Organizations:reseller_name")
              }
              value={
                defaultValues?.parentOrganizationName ||
                defaultValues?.sellerName
              }
            />
          )}
        />
      </Row>
      <Spacer size={16} />

      <Row align="start" className={styles.rowWithValidation}>
        <Controller
          name="address"
          control={control}
          render={({ field }) => (
            <TextInput
              label={t("Common:labels_address")}
              description={t("Organizations:address_example")}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Spacer size={32} />
        <Controller
          name="postalCode"
          control={control}
          render={({ field }) => (
            <TextInput
              label={t("Common:labels_postal_code")}
              placeholder="000 00"
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </Row>

      <Spacer size={16} />

      <Row className={styles.rowWithValidation}>
        <Controller
          name="city"
          control={control}
          render={({ field }) => (
            <TextInput
              label={t("Common:labels_city")}
              placeholder={t("Common:enter_city")}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Spacer size={32} />
        <Controller
          name="country"
          control={control}
          render={({ field }) => (
            <TextInput
              label={t("Common:labels_country")}
              placeholder={t("Common:enter_country")}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </Row>

      <Spacer size={32} />
      <Row>
        <Controller
          name="telefax"
          control={control}
          rules={{
            validate: (input: string | undefined) =>
              !input || input.length < 2048,
          }}
          render={({ field }) => (
            <TextArea
              inputRef={field.ref}
              label={t("Organizations:organization_info")}
              placeholder={t("Organizations:organization_info_placeholder")}
              value={he.decode(field.value || "")}
              onChange={field.onChange}
              validationError={
                errors?.telefax && t("Errors:ValidationHint_OrganizationInfo")
              }
            />
          )}
        />
      </Row>

      <Spacer size={32} />

      <Divider />

      <Spacer size={32} />

      <h2>{t("Organizations:contact_person_detail")}</h2>

      <Spacer size={16} />

      <Row className={styles.rowWithValidation}>
        <Controller
          name="contactPerson"
          control={control}
          render={({ field }) => (
            <TextInput
              label={t("Common:name_label")}
              placeholder={t("Common:contact_person_name")}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Spacer size={32} />
        <Controller
          name="emailAddress"
          control={control}
          rules={{
            maxLength: 60,
            validate: validateEmailIfExists,
          }}
          render={({ field }) => (
            <TextInput
              inputRef={field.ref}
              label={t("Common:email")}
              placeholder={t("Common:placeholder_email")}
              value={field.value}
              onChange={field.onChange}
              validationError={
                errors?.emailAddress && t("Errors:input_email_format")
              }
            />
          )}
        />
      </Row>

      <Spacer size={16} />

      <Row style={{ width: "48%" }} className={styles.rowWithValidation}>
        <Controller
          name="contactPersonAccessCode"
          control={control}
          render={({ field }) => (
            <TextInput
              label={t("Organizations:contact_person_access_code")}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </Row>

      <Spacer size={16} />

      <Row className={styles.rowWithValidation}>
        <Controller
          name="telephoneExchange"
          control={control}
          rules={{
            validate: (value) =>
              validateEmergencyPhoneNumberIfExists(value) ||
              validatePhoneNumberIfExists(value),
          }}
          render={({ field }) => (
            <TextInput
              inputRef={field.ref}
              label={t("Organizations:telephone_exchange_number")}
              placeholder="+46123456789"
              description={
                !errors.telephoneExchange
                  ? t("Common:telephone_placeholder")
                  : undefined
              }
              value={field.value}
              onChange={field.onChange}
              validationError={
                errors?.telephoneExchange &&
                t("Errors:ValidationHint_TelephoneNumber")
              }
            />
          )}
        />
        <Spacer size={32} />
        <Controller
          name="telephoneDirect"
          control={control}
          rules={{
            validate: (value) =>
              validateEmergencyPhoneNumberIfExists(value) ||
              validatePhoneNumberIfExists(value),
          }}
          render={({ field }) => (
            <TextInput
              inputRef={field.ref}
              label={t("Organizations:direct_telephone_number")}
              placeholder="+46123456789"
              description={
                !errors.telephoneDirect
                  ? t("Common:telephone_placeholder")
                  : undefined
              }
              value={field.value}
              onChange={field.onChange}
              validationError={
                errors?.telephoneDirect &&
                t("Errors:ValidationHint_TelephoneNumber")
              }
            />
          )}
        />
      </Row>

      <Spacer size={16} />

      <Row style={{ width: "48%" }} className={styles.rowWithValidation}>
        <Controller
          name="telephoneMobile"
          control={control}
          rules={{
            validate: (value) =>
              validateEmergencyPhoneNumberIfExists(value) ||
              validatePhoneNumberIfExists(value),
          }}
          render={({ field }) => (
            <TextInput
              inputRef={field.ref}
              label={t("Organizations:mobile_telephone_number")}
              placeholder="+46123456789"
              description={
                !errors.telephoneMobile
                  ? t("Common:telephone_placeholder")
                  : undefined
              }
              value={field.value}
              onChange={field.onChange}
              validationError={
                errors?.telephoneMobile &&
                t("Errors:ValidationHint_TelephoneNumber")
              }
            />
          )}
        />
      </Row>

      <Spacer size={32} />

      <Divider />

      <Spacer size={32} />

      <ExpandableList title="Advanced option">
        <>
          <Spacer size={16} />

          <Row type="left" className={styles.rowWithValidation}>
            {user.hasAccess("UserPrice") && (
              <>
                <Controller
                  name="defaultPrice"
                  control={control}
                  render={({ field }) => (
                    <TextInput
                      label={t("Organizations:default_price")}
                      type="number"
                      value={field.value}
                      onChange={field.onChange}
                      renderIcon={() => <span className={styles.priceIcon} />}
                    />
                  )}
                />
                <Spacer size={32} />
              </>
            )}
          </Row>

          <Spacer size={16} />

          <Row className={styles.rowWithValidation}>
            <Controller
              name="integrationSnsArn"
              control={control}
              render={({ field }) => (
                <TextInput
                  label={t("Common:labels_sns_arn")}
                  value={field.value}
                  onChange={field.onChange}
                  placeholder={t("Organizations:sns_arn_placeholder")}
                />
              )}
            />
            <Spacer size={32} />
            <Controller
              name="integrationAdditionalInfo"
              control={control}
              render={({ field }) => (
                <TextInput
                  label={t("Common:labels_sns_arn_additional_info")}
                  value={field.value}
                  onChange={field.onChange}
                />
              )}
            />
          </Row>
          <Spacer size={16} />
          <Row>
            <div className={styles.organizationsCheckbox}>
              <Controller
                name="blockUserPages"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    label={t("Organizations:block_user_pages")}
                    checked={!!field.value}
                    onChange={field.onChange}
                    style={{ padding: 0 }}
                  />
                )}
              />
            </div>
            <Spacer size={32} />
            <div
              ref={expandedAnchorRef}
              className={styles.organizationsCheckbox}
            >
              <Controller
                name="requireCostCenter"
                control={control}
                render={({ field }) => (
                  <Checkbox
                    label={t("Organizations:require_cost_center")}
                    checked={!!field.value}
                    onChange={field.onChange}
                    style={{ padding: 0 }}
                  />
                )}
              />
            </div>
          </Row>
        </>
      </ExpandableList>
    </form>
  );
};
