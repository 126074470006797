import { MedicalTechnicalNotes } from "../../../core/api/objects/types";
import StyledModal from "../../../ui-lib/components/Modal/Modal";
import useTranslations from "../../../core/i18n/useTranslations";
import { Row, Spacer } from "../../../components/Layout/Layout";
import Button from "../../../ui-lib/components/Button/Button";
import DateTimeHelper from "../../../core/helpers/dateTimeHelper";

export const MedicalTechnicalDetails = ({
  details,
  onClose,
  isMedical = true,
}: {
  details?: MedicalTechnicalNotes;
  onClose: () => void;
  isMedical?: boolean;
}) => {
  const t = useTranslations();

  return (
    <StyledModal
      isOpen={!!details}
      onClose={onClose}
      modalTitle={t(
        `Objects:${isMedical ? "medical" : "technical"}_note_details`
      )}
      closeOnDocumentClick={false}
      customFooter={() => (
        <Row type="left">
          <Button
            variant="secondary"
            text={t("Common:close")}
            onClick={onClose}
          />
        </Row>
      )}
      width={"576px"}
    >
      <Spacer size={16} />

      {details ? (
        <>
          <p>{DateTimeHelper.formatDateTimeString(details.created)}</p>

          <Spacer size={16} />

          <p className="fw-600">{t("Objects:note_text")}</p>
          {details.note.split("\n").map((item) => {
            return <p key={self.crypto.randomUUID()}>{item}</p>;
          })}

          <Spacer size={16} />

          <p className="fw-600">{t("Objects:added_by")}</p>
          <p>{details.adminName || details.adminId}</p>
        </>
      ) : null}
    </StyledModal>
  );
};
