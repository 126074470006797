import { AxiosInstance } from "axios";
import API_HOST from "../apiHost";
import { IProduct, IProductCategory, IProductDetails } from "./types";
import useAuthenticatedFetch from "../useAuthenticatedFetch";

const useProductCategories = () => {
  const { data, isLoading, isError } = useAuthenticatedFetch<
    IProductCategory[]
  >(`${API_HOST}api/v2/products`);

  return { data, isLoading, isError };
};

const getProducts = (authenticatedRequest: AxiosInstance) =>
  authenticatedRequest.get<IProductCategory[]>(`${API_HOST}api/v2/products`);

const getProductsAll = (authenticatedRequest: AxiosInstance) =>
  authenticatedRequest.get<IProduct[]>(`${API_HOST}api/v2/products/all`);

const getProductDetails = (authenticatedRequest: AxiosInstance, id: string) =>
  authenticatedRequest.get<IProductDetails>(`${API_HOST}api/v2/products/${id}`);

const createProduct = (
  authenticatedRequest: AxiosInstance,
  data: IProductDetails
) =>
  authenticatedRequest.post<IProductDetails>(
    `${API_HOST}api/v2/products`,
    JSON.stringify(data),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const updateProduct = (
  authenticatedRequest: AxiosInstance,
  id: string,
  data: IProductDetails
) =>
  authenticatedRequest.put<IProductDetails>(
    `${API_HOST}api/v2/products/${id}`,
    JSON.stringify(data),
    {
      headers: { "Content-Type": "application/json" },
    }
  );

const deleteProduct = (
  authenticatedRequest: AxiosInstance,
  id: string | number
) => authenticatedRequest.delete(`${API_HOST}api/v2/products/${id}`);

export {
  useProductCategories,
  getProducts,
  getProductsAll,
  getProductDetails,
  createProduct,
  updateProduct,
  deleteProduct,
};
