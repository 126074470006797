import { uniqBy } from "lodash";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import classNames from "classnames";
import { Column, Row, Spacer } from "../../components/Layout/Layout";
import { Role, RoleFeature } from "../../components/RoleFeature";
import {
  getResponseCenters,
  useResponseCenters,
} from "../../core/api/responsecenters/responsecenters";
import { ResponseCenterResponse } from "../../core/api/responsecenters/types";
import { useCustomerFilter } from "../../core/hooks/filters/useCustomerFilter";
import { useQueryState } from "../../core/hooks/filters/useQueryState";
import {
  useStoredTableState,
  storeTableState,
} from "../../core/hooks/filters/useStoredTableState";
import useTranslations from "../../core/i18n/useTranslations";
import usePageState from "../../core/pagestate/usePageState";
import useUser from "../../core/user/useUser";
import Divider from "../../ui-lib/components/Divider/Divider";
import SearchInput from "../../ui-lib/components/Inputs/SearchInput";
import PageHeader from "../../ui-lib/components/PageHeader/PageHeader";
import Modal, { ModalActions } from "../../ui-lib/components/Popup/Modal";
import styles from "../Layout.module.css";
import { AlarmReceptionsTable } from "./AlarmReceptionsTable";
import CopyAlarmReceptionModal from "./CreateAlarmReception/CopyAlarmReceptionModal";
import CustomerPicker from "./CustomerPicker";
import { DeleteAlarmReceptionModal } from "./DeleteAlarmReceptionModal";
import TypePicker from "./TypePicker";
import {
  searchStringParser,
  urlSearchParser,
} from "../../ui-lib/utils/urlSearchParser";
import { ClearFilter } from "../../ui-lib/components/Tables/CleatFilters";
import { useOrganizationsTree } from "../../core/api/organizations/organizations";
import { findNodeById } from "../../core/utils/organizations";
import { AddAlarmReceptionButton } from "./AddAlarmReceptionButton";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import NoItems from "../../ui-lib/components/Tables/NoItems";

export const PAGE_ID = "adminportal/alarm-receptions";

const INITIAL_PAGE_SETTINGS = {
  page: 1,
  pageSize: 10,
};

const AlarmReceptions = ({ organizationId }: { organizationId?: number }) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const pageState = usePageState();
  const { data: organizationTree } = useOrganizationsTree();
  const organizationsById = findNodeById(organizationTree, organizationId);

  const [selected, setSelected] = useState<ResponseCenterResponse>();
  const [copyModalOpen, setCopyModalOpen] = useState(false);

  const deleteModalRef = useRef<ModalActions>(null);

  const history = useHistory();
  if (!organizationId) storeTableState(PAGE_ID);
  const tableState = useStoredTableState(PAGE_ID);
  const searchState = !organizationId ? urlSearchParser(tableState) : "";

  const [queryParams, setQueryParams] = useQueryState<{
    searchText: string;
    order: string;
    privateOnly: string;
    publicOnly: string;
  }>({
    searchText: searchStringParser(searchState?.searchText) ?? "",
    order: searchState?.order || "name",
    privateOnly: searchState?.privateOnly || "false",
    publicOnly: searchState?.publicOnly || "false",
  });
  const { customerFilter, setCustomerFilter, resetCustomerFilter } =
    useCustomerFilter(searchState);
  const initialPageSettings = {
    page: searchState?.page || INITIAL_PAGE_SETTINGS.page,
    pageSize: searchState?.pageSize || INITIAL_PAGE_SETTINGS.pageSize,
  };
  const [pageSettings, setPageSettings] = useState(initialPageSettings);
  const [total, setTotal] = useState<ResponseCenterResponse[]>([]);
  const { data, isLoading } = useResponseCenters({
    ...queryParams,
    ...pageSettings,
    organizationId: customerFilter.organizationId ?? organizationId,
    includeSubCustomers: !organizationId,
  });

  // Append new data to list once updated
  useLayoutEffect(() => {
    if (data) {
      setTotal((prev) => {
        const updated =
          pageSettings.page === 1 ? [...data] : [...prev, ...data];
        return uniqBy(updated, "id");
      });
    }
  }, [data]);

  // This is called when table settings are updated
  const resetPageSettings = () => {
    setPageSettings(INITIAL_PAGE_SETTINGS);
  };

  const onClear = () => {
    resetCustomerFilter();
    setQueryParams({
      searchText: "",
      order: "name",
      privateOnly: "false",
      publicOnly: "false",
    });
  };

  // This is called when a new alarm reception is added
  const refetchData = async () => {
    setPageSettings(INITIAL_PAGE_SETTINGS);
    const { data: updated } = await getResponseCenters(
      {
        ...queryParams,
        ...INITIAL_PAGE_SETTINGS,
        organizationId: customerFilter.organizationId ?? organizationId,
      },
      authenticatedRequest
    );
    setTotal(updated);
  };

  useEffect(() => {
    sessionStorage.setItem(
      "prevPath",
      JSON.stringify({
        path: history.location.pathname,
        pathName: t("Menu:alarmreceptions"),
      })
    );
  }, []);

  const isFiltersApplied =
    customerFilter.organizationId ??
    queryParams.searchText.length ??
    (queryParams.privateOnly !== "false" || queryParams.publicOnly !== "false");

  return (
    <>
      {!organizationId ? (
        <>
          <PageHeader
            title={t("Menu:alarmreceptions")}
            icon={pageState.pageIcon}
          >
            <AddAlarmReceptionButton
              refetch={refetchData}
              buttonColor="primary"
              treeData={organizationTree}
            />
          </PageHeader>
          <Divider />
        </>
      ) : (
        <Row style={{ justifyContent: "space-between", width: "100%" }}>
          <div>
            <h1>{t("Menu:alarmreceptions")}</h1>
            <Spacer size={8} />
            <p>{t("Organizations:organisations_alarm_receptions_desc")}</p>
          </div>
          <AddAlarmReceptionButton
            refetch={refetchData}
            organizationId={organizationId}
            treeData={organizationTree}
            organizationById={organizationsById}
            buttonColor="secondary"
            icon="alarm-reception"
          />
        </Row>
      )}
      <Column className={styles.wrapper}>
        <Column
          className={classNames({
            [styles.content]: !organizationId,
            [styles.organizationContent]: organizationId,
          })}
        >
          <Row className={styles.settingsRow}>
            <SearchInput
              limit={1}
              value={queryParams.searchText}
              onChange={(value) => {
                setQueryParams((prev) => ({ ...prev, searchText: value }));
                resetPageSettings();
              }}
              placeholder={t("AlarmReceptions:search_placeholder")}
            />
          </Row>
          <Spacer size={16} />
          <Divider />
          <Spacer size={16} />
          <Row style={{ flex: 0, alignSelf: "flex-start" }}>
            <RoleFeature requires={[Role.OrganizationsList]}>
              <CustomerPicker
                treeData={organizationTree}
                organizationsById={organizationsById}
                settings={customerFilter}
                setSettings={(settings) => {
                  setCustomerFilter(settings);
                  resetPageSettings();
                }}
                organizationId={organizationId}
              />
              <Spacer size={8} />
            </RoleFeature>

            <TypePicker
              settings={queryParams}
              setSettings={(settings) => {
                setQueryParams((prev) => ({ ...prev, ...settings }));
                resetPageSettings();
              }}
            />

            <Spacer size={8} />

            <ClearFilter
              text={t("Common:labels_clear_all_filters")}
              onClearClick={onClear}
              filtersToWatch={[customerFilter, queryParams]}
              propertiesToExclude={{
                order: queryParams.order,
                privateOnly: "false",
                publicOnly: "false",
              }}
            />
          </Row>
          <Spacer size={16} />
          <Divider />
          <Column className={styles.tableContainer} type="top">
            {isLoading ? (
              <LoadingSpinner theme="primary" />
            ) : (
              <>
                {!total.length ? (
                  <NoItems
                    title={
                      !isFiltersApplied
                        ? t("Table:noresult_title")
                        : t("Table:no_results_found")
                    }
                    icon={!isFiltersApplied ? "eye-off" : undefined}
                    subTitle={
                      !isFiltersApplied
                        ? t("Table:noresult_subtitle")
                        : t("Table:adjust_filter_description")
                    }
                    clear={
                      isFiltersApplied && onClear
                        ? {
                            text: t("Table:clear_filters"),
                            onClick: onClear,
                          }
                        : undefined
                    }
                  />
                ) : (
                  <AlarmReceptionsTable
                    data={total}
                    isLoading={isLoading}
                    pageSettings={pageSettings}
                    organizationId={organizationId}
                    onPageSettingsChange={setPageSettings}
                    order={queryParams.order}
                    setOrder={(order) => {
                      if (order !== queryParams.order) {
                        setQueryParams((prev) => ({ ...prev, order }));
                        resetPageSettings();
                      }
                    }}
                    onStartCopy={(responseCenter) => {
                      setSelected(responseCenter);
                      setCopyModalOpen(true);
                    }}
                    onStartDelete={(responseCenter) => {
                      setSelected(responseCenter);
                      deleteModalRef?.current?.open();
                    }}
                  />
                )}
              </>
            )}
          </Column>
        </Column>
      </Column>

      {copyModalOpen && (
        <CopyAlarmReceptionModal
          isOpen={copyModalOpen}
          treeData={organizationTree}
          organizationsById={organizationsById}
          organizationId={organizationId}
          copyObject={selected}
          onClose={() => setCopyModalOpen(false)}
          onCopy={async () => {
            setCopyModalOpen(false);
            await refetchData();
          }}
        />
      )}

      <Modal modalRef={deleteModalRef}>
        {(close) => (
          <DeleteAlarmReceptionModal
            responseCenterId={selected?.id}
            onClose={close}
            onDelete={() => {
              close();
              refetchData();
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default AlarmReceptions;
