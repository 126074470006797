import { observer } from "mobx-react";
import { ReactNode, useEffect, useState } from "react";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import he from "he";
import { Column, Row } from "../../components/Layout/Layout";
import { useSubNav } from "../../components/LoggedinPage/SubNavContext";
import { ObjectAccessLog } from "../../components/Object/ObjectAccessLog";
import ObjectAccessories from "../../components/Object/ObjectAccessories";
import { useStoredTableState } from "../../core/hooks/filters/useStoredTableState";
import useTranslations from "../../core/i18n/useTranslations";
import { SaveObjectProvider } from "../../core/SaveObjectContext/SaveObjectContext";
import useUser from "../../core/user/useUser";
import Divider from "../../ui-lib/components/Divider/Divider";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import PageHeader from "../../ui-lib/components/PageHeader/PageHeader";
import styles from "../Layout.module.css";
import ObjectActions from "./ObjectActions/ObjectActions";
import ObjectAdvanced from "./ObjectAdvanced/ObjectAdvanced";
import ObjectAlarmHistory from "./ObjectAlarmHistory/ObjectAlarmHistory";
import ObjectGeneral from "./ObjectGeneral/ObjectGeneral";
import ObjectResponse from "./ObjectResponse/ObjectResponse";
import { PAGE_ID as parentId } from "./Objects";
import ObjectSubscription from "./ObjectSubscription/ObjectSubscription";
import { SharedObjectProvider } from "./SharedObjectContext/SharedObjectContext";
import useSharedObject from "./SharedObjectContext/useSharedObject";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import { getErrorKey } from "../../components/Errors/ErrorAlert";
import {
  IObjectAbsenceModel,
  ObjectActivationData,
  ObjectDetailsModel,
} from "../../core/api/objects/types";
import { getSubscription } from "../../core/api/objects/objects";
import { PlanningOverview } from "./Planning/PlanningOverview/PlanningOverview";
import { PlanningHistory } from "./Planning/PlanningHistory/PlanningHistory";

const parentPath = "/adminportal/objects";

export const SubMenuItems = (
  url: string,
  baseUrl?: string,
  subscriptionData?: ObjectActivationData
) => {
  const user = useUser();
  const show = user.config?.show ?? [];
  const access = {
    General: show.includes("Users"),
    Transmitters:
      show.includes("User/Transmitters") &&
      !show.includes("ShowOnlyBasicContactInfo"),
    Response: show.includes("User/Contacts"),
    Subscription:
      show.includes("User/Subscription") &&
      !show.includes("ShowObjectDetailsVariant1"),
    Alarms: show.includes("Alarms"),
    Access: show.includes("UserUpdateLogs"),
    Beta: show.includes("NewAdminBeta"),
    viserStandart: show.includes("SensioHideFields"),
  };
  const sub = [];
  if (access.General) {
    sub.push({ key: "Menu:general", to: baseUrl ?? url });
  }
  if (access.Transmitters) {
    sub.push({
      key: "Menu:Objects_Accessories",
      to: `${baseUrl ?? url}/accessories`,
    });
  }
  if (access.Response) {
    sub.push({
      key: "Menu:Objects_Response",
      to: `${baseUrl ?? url}/response`,
    });
  }
  if (access.Subscription) {
    sub.push({
      key: "Menu:Objects_Subscription",
      to: `${baseUrl ?? url}/subscription`,
      icon: `${
        subscriptionData?.subscriptionActive === false
          ? "exclamation-circle"
          : ""
      }`,
    } as any);
  }
  sub.push({
    key: "Common:additional_object_information",
    to: `${baseUrl ?? url}/advanced-settings`,
  });
  if (access.Alarms) {
    sub.push({
      key: "Menu:alarmhistory",
      to: `${baseUrl ?? url}/alarm-history`,
    });
  }
  if (access.Beta) {
    sub.push({
      key: "Menu:planning",
      subItems: [
        {
          //  Key for highlight dropdown submenu item
          watchKey: "planning",
          key: "Menu:overview",
          to: `${url}/planning-overview`,
        },
        {
          //  Key for highlight dropdown submenu item
          watchKey: "planning",
          key: "Menu:history",
          to: `${url}/planning-history`,
        },
      ],
    });
  }
  if (access.Access) {
    sub.push({ key: "Menu:Objects_AccessLog", to: `${url}/accesslog` });
  }
  return {
    sub,
    access,
  };
};

const ObjectDetailsWrapper = ({
  data,
  absenceData,
  children,
}: {
  data?: ObjectDetailsModel;
  absenceData: IObjectAbsenceModel;
  children: ReactNode;
}) => {
  const t = useTranslations();

  return (
    <Row align="start" style={{ width: "100%" }}>
      <Column
        style={{ width: "100%", height: "100vh", overflow: "hidden" }}
        type="top"
      >
        <PageHeader
          title={
            <>
              <span>{he.decode(data?.name ?? "")}</span>
              {!!absenceData?.ongoing?.length && (
                <span style={{ color: "#B78A00", marginLeft: "8px" }}>
                  ({t("Objects:absent")})
                </span>
              )}
            </>
          }
          status={data?.dateOfDeath ? "Objects:deceased" : undefined}
          icon="cube"
        >
          <ObjectActions active={data!.active || false} />
        </PageHeader>
        <Divider />
        <div
          className={styles.wrapper}
          style={{
            height: "auto",
            overflowY: "auto",
          }}
        >
          <Column
            className={styles.content}
            type="top"
            align="start"
            style={{ padding: "32px" }}
          >
            {children}
          </Column>
        </div>
      </Column>
    </Row>
  );
};
const ObjectDetails = ({
  subscriptionDataObject,
  access,
  setCheckChanges,
}: {
  subscriptionDataObject: {
    fetchObjectSubscription: () => Promise<void>;
    subscription: ObjectActivationData;
  };
  access: {
    General: boolean;
    Transmitters: boolean;
    Response: boolean;
    Subscription: boolean;
    Alarms: boolean;
    Access: boolean;
  };
  setCheckChanges: (state: boolean) => void;
}) => {
  const { id } = useParams<{ id: string }>();
  const { path } = useRouteMatch();

  const { data, absenceData } = useSharedObject();

  return (
    <Switch>
      {access.Transmitters && (
        <Route path={`${path}/accessories`}>
          <ObjectDetailsWrapper data={data} absenceData={absenceData!}>
            <ObjectAccessories
              objectId={id}
              customerId={data!.organizationId || -1}
              setCheckChanges={setCheckChanges}
            />
          </ObjectDetailsWrapper>
        </Route>
      )}
      {access.Response && (
        <Route path={`${path}/response`}>
          <ObjectDetailsWrapper data={data} absenceData={absenceData!}>
            <ObjectResponse objectId={id} />
          </ObjectDetailsWrapper>
        </Route>
      )}
      {access.Subscription && (
        <Route path={`${path}/subscription`}>
          <ObjectDetailsWrapper data={data} absenceData={absenceData!}>
            <ObjectSubscription
              objectId={id}
              subscriptionDataObject={subscriptionDataObject}
            />
          </ObjectDetailsWrapper>
        </Route>
      )}
      <Route path={`${path}/advanced-settings`}>
        <ObjectDetailsWrapper data={data} absenceData={absenceData!}>
          <ObjectAdvanced objectId={id} />
        </ObjectDetailsWrapper>
      </Route>
      {access.Alarms && (
        <Route path={`${path}/alarm-history`}>
          <ObjectDetailsWrapper data={data} absenceData={absenceData!}>
            <ObjectAlarmHistory objectId={id} />
          </ObjectDetailsWrapper>
        </Route>
      )}
      <Route path={`${path}/planning-overview`}>
        <ObjectDetailsWrapper data={data} absenceData={absenceData!}>
          <PlanningOverview objectId={data?.id!} />
        </ObjectDetailsWrapper>
      </Route>
      <Route path={`${path}/planning-history`}>
        <ObjectDetailsWrapper data={data} absenceData={absenceData!}>
          <PlanningHistory />
        </ObjectDetailsWrapper>
      </Route>
      {access.Access && (
        <Route path={`${path}/accesslog`}>
          <ObjectDetailsWrapper data={data} absenceData={absenceData!}>
            <ObjectAccessLog objectId={id} />
          </ObjectDetailsWrapper>
        </Route>
      )}
      {access.General && (
        <Route path={path}>
          <ObjectDetailsWrapper data={data} absenceData={absenceData!}>
            <ObjectGeneral objectId={id} />
          </ObjectDetailsWrapper>
        </Route>
      )}
    </Switch>
  );
};

const ObjectDetailsLoader = ({
  setCheckChanges,
}: {
  setCheckChanges: (state: boolean) => void;
}) => {
  const { id } = useParams<{ id: string }>();
  const { authenticatedRequest } = useUser();
  const history = useHistory();
  const t = useTranslations();
  const { data, isError } = useSharedObject();
  const [subscriptionData, setSubscriptionData] =
    useState<ObjectActivationData>();

  const { url } = useRouteMatch();
  const { sub, access } = SubMenuItems(url, undefined, subscriptionData);
  const { setSubNav } = useSubNav();
  const tableState = useStoredTableState(parentId);

  useEffect(() => {
    const nav = {
      menu: !access.viserStandart
        ? sub
        : sub.filter(
            (i) =>
              i.key === "Menu:alarmhistory" ||
              i.key === "Menu:Objects_Accessories"
          ),
      backUrl: tableState ? `${parentPath}${tableState}` : parentPath,
      backText: t("Menu:objects"),
    };
    setSubNav(nav);

    return () => {
      setSubNav(undefined);
    };
  }, [subscriptionData]);

  const fetchObjectSubscription = async () => {
    try {
      const { data: subscriptionObject } = await getSubscription(
        id,
        authenticatedRequest
      );

      setSubscriptionData(subscriptionObject);
    } catch (error: any) {
      setSubscriptionData({} as ObjectActivationData);

      notify({
        message: t(`Errors:${getErrorKey(error)}`),
        variant: "error",
      });
    }
  };

  useEffect(() => {
    fetchObjectSubscription();
  }, []);

  useEffect(() => {
    if (isError) {
      history.replace(parentPath);
    }
  }, [isError]);

  if (!(data && subscriptionData)) {
    return <LoadingSpinner theme="primary" />;
  }

  return (
    <ObjectDetails
      subscriptionDataObject={{
        fetchObjectSubscription,
        subscription: subscriptionData || ({} as ObjectActivationData),
      }}
      access={access}
      setCheckChanges={setCheckChanges}
    />
  );
};

const ObjectDetailsContainer = () => {
  const { id } = useParams<{ id: string }>();
  const [checkChanges, setCheckChanges] = useState(true);

  return (
    <SharedObjectProvider objectId={id}>
      <SaveObjectProvider checkChanges={checkChanges}>
        <ObjectDetailsLoader setCheckChanges={setCheckChanges} />
      </SaveObjectProvider>
    </SharedObjectProvider>
  );
};

export default observer(ObjectDetailsContainer);
