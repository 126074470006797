import classnames from "classnames";

import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import Panel from "../../ui-lib/components/Panel/Panel";
import { Column, Row } from "../Layout/Layout";
import styles from "./HomeSearchbar.module.css";
import useUser from "../../core/user/useUser";
import FilterSearch from "../FilterSearch/FilterSearch";
import useTranslations from "../../core/i18n/useTranslations";
import { homeDataRetriever } from "../../ui-lib/utils/homeDataHelper";

interface HomeFilterType {
  id: number;
  key: string;
  to: string;
  param: string;
  placeholder: string;
  disabled?: boolean;
  defaultSearch?: string;
  defaultId?: number;
}

const filterTypes = (access: { [key: string]: boolean }): HomeFilterType[] => [
  {
    id: 1,
    key: "Filters:homefilter1",
    to: `/adminportal/objects`,
    param: "search",
    placeholder: "Filters:homefilter1_placeholder",
    defaultSearch: "searchType",
    defaultId: -1,
  },
  {
    id: 2,
    key: "Filters:homefilter2",
    to: "/adminportal/alarms",
    param: "search",
    placeholder: "Filters:homefilter2_placeholder",
    defaultSearch: "searchType",
    defaultId: -1,
  },
  ...(access.Admins
    ? [
        {
          id: 3,
          key: "Filters:homefilter3",
          to: "/adminportal/administrators",
          param: "q",
          placeholder: "Filters:homefilter3_placeholder",
        },
      ]
    : []),
  ...(access.Organisations
    ? [
        {
          id: 4,
          key: "Filters:homefilter5",
          to: "/adminportal/organizations",
          param: "q",
          placeholder: "Filters:homefilter5_placeholder",
        },
      ]
    : []),
  ...(access.AlarmReception
    ? [
        {
          id: 5,
          key: "Filters:homefilter4",
          to: "/adminportal/alarm-receptions",
          param: "searchText",
          placeholder: "Filters:homefilter4_placeholder",
        },
      ]
    : []),
];

const HomeSearchbar = () => {
  const history = useHistory();
  const t = useTranslations();
  const user = useUser();
  const lastUsedFilter = homeDataRetriever(user);

  const access = {
    Admins: !!user.config?.show.some((e) =>
      ["AdminEditPermissions", "ChangeAdmins"].includes(e)
    ),
    Organisations:
      !!user.config?.show.some((e) =>
        [
          "EditOrganizations",
          "OrganizationRead",
          "Organizations",
          "OrganizationDetails",
        ].includes(e)
      ) && !user.config?.show.includes("SensioHideFields"),
    AlarmReception: !!user.config?.show.some((e) =>
      [
        "RcEditDetails",
        "ResponseCenterAllowViewOnlyEditable",
        "ResponseCenterRead",
        "ResponseCenter",
        "ResponseCenterViewList",
        "ResponseCenterViewDetails",
      ].includes(e)
    ),
  };

  return (
    <Column className={styles.container}>
      <Panel className={styles.plate}>
        <Column type="top" align="start">
          <h1>{`${t("Common:welcome")}, ${user.data?.name ?? ""}!`}</h1>
          <p className={classnames(styles.getStarted, "subTitle")}>
            {t("Home:getstarted")}
          </p>
          <Row
            type="fill"
            style={{ alignSelf: "stretch" }}
            className={styles.rowGutters}
          >
            <FilterSearch
              minSearchLength={2}
              types={filterTypes(access).map((f) => ({
                id: f.id,
                name: t(f.key),
                placeholder: t(f.placeholder),
              }))}
              placeholder={t("Filters:placeholder_search")}
              initialSearch={{
                id: lastUsedFilter.searchBar
                  ? lastUsedFilter.searchBar
                  : filterTypes(access)[0].id,
                value: "",
              }}
              searchText={t("Filters:search")}
              onSubmit={({ id, value }) => {
                const current = filterTypes(access).find((f) => f.id === id);

                if (current) {
                  history.push(
                    `${current.to}?${current.param}=${value}${
                      current.defaultSearch
                        ? `&${current.defaultSearch}=${current.defaultId}`
                        : ""
                    }`
                  );
                }
              }}
            />
          </Row>
        </Column>
      </Panel>
    </Column>
  );
};
export default observer(HomeSearchbar);
