import useTranslations from "../../core/i18n/useTranslations";
import TableCell from "../../ui-lib/components/Tables/TableCell";
import DateTimeHelper from "../../core/helpers/dateTimeHelper";
import Table from "../../ui-lib/components/Tables/Table";
import Icon from "../../ui-lib/components/Icon";
import Badge from "../../ui-lib/components/Badges/Badge";
import { Row, Spacer } from "../Layout/Layout";
import { ManualSyncTableProps } from "./ManualSyncWrapper";

export const ManualSyncTable = ({ data }: { data: ManualSyncTableProps }) => {
  const t = useTranslations();
  const isDataPresent = data.latestSyncAttempt ?? data.lastSync;

  const getSyncStatus = () => {
    const values = { title: "", variant: "" };
    switch (data.syncStatus) {
      case "ONGOING":
        values.title = t("Common:sync_ongoing");
        values.variant = "secondary";
        break;
      case "FAILED":
        values.title = t("Common:failed");
        values.variant = "error";
        break;
      case "SUCCESSFULL":
        values.title = t("Common:success");
        values.variant = "success";
        break;
      default:
        break;
    }
    return <Badge title={values.title} variant={values.variant as any} />;
  };

  return (
    <Table
      columns={[
        {
          header: t("Objects:last_sync_started"),
          fieldTemplate: (rowData) => (
            <TableCell
              value={
                <Row type="left">
                  <Icon name="clock" />
                  <Spacer size={8} />
                  {DateTimeHelper.formatDateTimeString(
                    rowData.latestSyncAttempt!
                  )}
                  <Spacer size={8} />
                  {rowData.syncStatus && getSyncStatus()}
                </Row>
              }
            />
          ),
        },
        {
          header: t("Objects:last_sync"),
          fieldTemplate: (rowData) =>
            rowData.lastSync ? (
              <TableCell
                value={
                  <Row type="left">
                    <Icon name="clock" />
                    <Spacer size={8} />
                    {DateTimeHelper.formatDateTimeString(rowData.lastSync)}
                  </Row>
                }
              />
            ) : null,
        },
      ]}
      items={isDataPresent ? [data] : []}
      hideEmptyMessage
      noRowsMessage={t("Objects:no_sync_before")}
      hideHeader={!isDataPresent}
      resizableColumns={false}
      showRowHover
      withShowMore
    />
  );
};
