import { useEffect, useRef, useState } from "react";
import { Column, Row, Spacer } from "../../components/Layout/Layout";
import { CommonContactResponse } from "../../core/api/contacts/types";
import { useQueryState } from "../../core/hooks/filters/useQueryState";
import useTranslations from "../../core/i18n/useTranslations";
import usePageState from "../../core/pagestate/usePageState";
import Button from "../../ui-lib/components/Button/Button";
import Divider from "../../ui-lib/components/Divider/Divider";
import SearchInput from "../../ui-lib/components/Inputs/SearchInput";
import PageHeader from "../../ui-lib/components/PageHeader/PageHeader";
import Modal, { ModalActions } from "../../ui-lib/components/Popup/Modal";
import styles from "../Layout.module.css";
import CreateCommonContactModal from "../Objects/ObjectResponse/Contacts/CreateCommonContactModal";
import DeleteContactModal from "../Objects/ObjectResponse/Contacts/DeleteContactModal";
import { CommonContactsTable } from "./CommonContactsTable";
import useUser from "../../core/user/useUser";
import {
  useStoredTableState,
  storeTableState,
} from "../../core/hooks/filters/useStoredTableState";
import {
  searchStringParser,
  urlSearchParser,
} from "../../ui-lib/utils/urlSearchParser";
import Tree from "../../ui-lib/components/Hierarchy/Tree";
import { useOrganizationsTree } from "../../core/api/organizations/organizations";
import { ClearFilter } from "../../ui-lib/components/Tables/CleatFilters";
import { useCustomersFilter } from "../../core/hooks/filters/useCustomersFilter";
import { usePageFilter } from "../../core/hooks/filters/usePageFilter";
import { getCommonContacts } from "../../core/api/contacts/contacts";
import { notifyApiErrors } from "../../core/helpers/helpers";

export const PAGE_ID = "adminportal/common-contacts";

const CommonContacts = () => {
  const t = useTranslations();
  const pageState = usePageState();
  const user = useUser();
  const { data: organisations } = useOrganizationsTree();

  storeTableState(PAGE_ID);
  const tableState = useStoredTableState(PAGE_ID);
  const searchState = urlSearchParser(tableState);
  const deleteModalRef = useRef<ModalActions>(null);

  const { customersFilter, setCustomersFilter, resetCustomersFilter } =
    useCustomersFilter(searchState);
  const { pageFilter, setPageFilter, resetPageFilter } =
    usePageFilter(searchState);
  const [tableSettings, setTableSettings] = useQueryState<{ search: string }>({
    search: searchStringParser(searchState?.search) ?? "",
  });

  const [total, setTotal] = useState<number>(0);
  const [tableData, setTableData] = useState<CommonContactResponse[]>([]);
  const [upForDelete, setUpForDelete] = useState<CommonContactResponse>();
  const [createCommonContactModalOpen, setCreateCommonContactModalOpen] =
    useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchCommonContacts = async () => {
    setIsLoading(true);
    try {
      const result = await getCommonContacts(
        {
          page: pageFilter.page,
          pageSize: pageFilter.pageSize,
          search: tableSettings.search,
          organizationIds: customersFilter.customers,
        },
        user.authenticatedRequest
      );
      setTableData(result.data.contacts);
      setTotal(result.data.total);
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  const onClear = () => {
    resetCustomersFilter();
    resetPageFilter();
    setTableSettings({ search: "" });
  };

  const isFiltersApplied =
    !!customersFilter.customers || !!tableSettings.search;

  useEffect(() => {
    fetchCommonContacts();
  }, [
    pageFilter.page,
    pageFilter.pageSize,
    customersFilter.customers,
    tableSettings.search,
  ]);

  return (
    <>
      <PageHeader title={t("Menu:commoncontacts")} icon={pageState.pageIcon}>
        <Button
          variant="primary"
          text={t("Contacts:add_common_contact")}
          onClick={() => setCreateCommonContactModalOpen(true)}
        />
      </PageHeader>
      <Divider />
      <Column className={styles.wrapper}>
        <Column className={styles.content}>
          <Row className={styles.settingsRow}>
            <SearchInput
              limit={1}
              value={tableSettings.search}
              onChange={(value) => {
                setTableSettings((prev) => ({ ...prev, search: value }));
                resetPageFilter();
              }}
              placeholder={t("Contacts:common_contacts_search_placeholder")}
            />
          </Row>
          <Spacer size={16} />
          <Divider />
          <Spacer size={16} />

          <Row className={styles.settingsRow}>
            <Tree
              hideLabel
              showClearOption={false}
              label={t("Common:select_organisation")}
              placeholder={`--${t("Common:organisation")}--`}
              className={styles.treeFilter}
              items={organisations}
              selectedTreeItem={customersFilter.customers}
              selectionMode="multiple"
              onSelectItem={(treeItem: string) => {
                setCustomersFilter({
                  customers: treeItem,
                });
                resetPageFilter();
              }}
              disabled={!organisations.length}
            />
            <Spacer size={8} />

            <ClearFilter
              text={t("Common:labels_clear_all_filters")}
              onClearClick={onClear}
              filtersToWatch={[customersFilter, tableSettings.search]}
            />
          </Row>
          <Spacer size={16} />
          <Column className={styles.tableContainer} type="top">
            <CommonContactsTable
              data={tableData}
              isLoading={!tableData || isLoading}
              pageSettings={pageFilter}
              onPageSettingsChange={setPageFilter}
              onStartDelete={(contact) => {
                setUpForDelete(contact);
                deleteModalRef?.current?.open();
              }}
              isFiltersApplied={isFiltersApplied}
              onClear={onClear}
              totalItems={total}
              fetchCommonContacts={fetchCommonContacts}
            />
          </Column>
        </Column>
      </Column>

      <CreateCommonContactModal
        createCommonContactModalOpen={createCommonContactModalOpen}
        setCreateCommonContactModalOpen={setCreateCommonContactModalOpen}
      />

      <Modal modalRef={deleteModalRef}>
        {(close) => (
          <DeleteContactModal
            contact={upForDelete}
            onClose={close}
            onDelete={() => {
              close();
              fetchCommonContacts();
            }}
          />
        )}
      </Modal>
    </>
  );
};

export default CommonContacts;
