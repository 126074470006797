import { useHistory } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import useTranslations from "../../../core/i18n/useTranslations";
import { useSaveObject } from "../../../core/SaveObjectContext/hooks";
import Button from "../../../ui-lib/components/Button/Button";
import { PopupContextMenuLinkButton } from "../../../ui-lib/components/Menus/PopupContextMenu/PopupContextMenuLinkButton";
import Modal from "../../../ui-lib/components/Popup/Modal";
import { DeleteAdministratorModal } from "./DeleteAdministratorModal";
import {
  impersonateAlarmReception,
  makeCentralAdmin,
  makeOperator,
  requestDeviceLog,
} from "../../../core/api/administrators/administrators";
import { getSellers } from "../../../core/api/sellers/sellers";
import { notify } from "../../../ui-lib/components/Alerts/Toast";
import { notifyApiErrors } from "../../../core/helpers/helpers";
import useUser from "../../../core/user/useUser";
import RequestLogModal from "../../Organizations/RequestOrganizationLogModal";
import { CopyAdministratorModal } from "./CopyAdministrator/CopyAdministratorModal";
import { AdministratorDetails } from "../../../core/api/administrators/types";
import { ImpersonateModal } from "./ImpersonateModal";
import useSharedAdministrator from "./SharedAdministratorContext/useSharedAdministrator";
import { ITreeData } from "../../../ui-lib/components/Hierarchy/Tree";
import PrimeModal from "../../../ui-lib/components/PrimeModal/PrimeModal";
import { OverlayPanel } from "primereact/overlaypanel";

const AdministratorActions = ({
  administratorData,
  identity,
}: {
  administratorData?: AdministratorDetails;
  identity: boolean;
}) => {
  const t = useTranslations();
  const history = useHistory();
  const { canSave, isDirty, isSaving, onSave } = useSaveObject();
  const { reload } = useSharedAdministrator();
  const { authenticatedRequest, accountId, config } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const [sellers, setSellers] = useState<ITreeData[]>([]);
  const [requestLogModalOpen, setRequestLogModalOpen] = useState(false);
  const [copyModalOpen, setCopyModalOpen] = useState(false);

  const contextMenuRef = useRef<any>(null);

  const viserPermission = config?.show.includes("SensioHideFields");

  const fetchSellers = async () => {
    setIsLoading(true);
    try {
      const res = await getSellers(authenticatedRequest);
      if (res.data) {
        const sellersItems = res.data.map((seller: any) => ({
          key: seller.id,
          label: seller.name,
          data: seller.name,
        }));
        setSellers(sellersItems);
      }
    } catch (error: any) {
      // ignore errors
    } finally {
      setIsLoading(false);
    }
  };

  const impersonateReception = async () => {
    try {
      setIsLoading(true);
      const result = await impersonateAlarmReception(
        administratorData?.id!,
        authenticatedRequest
      );

      window.open(result.data.data.url, "_blank");
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  const changeAdminType = async (respondentToAdmin = true) => {
    try {
      setIsLoading(true);
      respondentToAdmin
        ? await makeCentralAdmin(administratorData?.id!, authenticatedRequest)
        : await makeOperator(administratorData?.id!, authenticatedRequest);
      await reload();
      notify({
        message: t(
          `Administrator:to_${
            respondentToAdmin ? "admin" : "respondent"
          }_success`
        ),
      });
    } catch (error: any) {
      notifyApiErrors(error.response?.data?.errors);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (administratorData?.type === "seller") {
      fetchSellers();
    }
  }, []);

  return (
    <>
      {canSave && (
        <Button
          text={t("Common:save")}
          disabled={!isDirty}
          loading={isSaving}
          onClick={onSave}
        />
      )}
      <Button
        type="button"
        variant="secondary"
        image="dots-horizontal"
        text={t("Common:other")}
        onClick={(e) => contextMenuRef.current?.toggle(e)}
      />
      <OverlayPanel ref={contextMenuRef} className="actions-overlay">
        {!viserPermission && (
          <PopupContextMenuLinkButton
            icon="cloud-download"
            text={t("Objects:request_logs")}
            onClick={async () => {
              const historyPath = history.location.pathname.split("/");
              const id = historyPath[historyPath.length - 1];
              try {
                await requestDeviceLog(id, authenticatedRequest);

                notify({
                  message: t("Objects:request_successfully_send"),
                });
              } catch (error: any) {
                notifyApiErrors(
                  error.response?.data?.errors,
                  error.response?.status
                );
              }
            }}
          />
        )}

        {administratorData?.type === "respondent" && !viserPermission && (
          <PopupContextMenuLinkButton
            icon="status-online"
            text={t("Administrator:operator_alarms")}
            onClick={async () => {
              const id = administratorData?.id;
              history.push({
                pathname: "/adminportal/alarms",
                search: `?searchType=-1&page=1&pageSize=100&filterType=7&filterValue=${id}`,
              });
            }}
          />
        )}

        {config?.show.includes("NewAdminBeta") &&
          config?.show.includes("ChangeAdminsMakeCenterAdmin") &&
          (administratorData?.type === "respondent" ||
            administratorData?.type === "responscenter") && (
            <PopupContextMenuLinkButton
              text={t(`Administrator:from_${administratorData?.type}_convert`)}
              icon={
                administratorData?.type === "respondent"
                  ? "arrow-up"
                  : "arrow-down"
              }
              onClick={async () => {
                changeAdminType(administratorData?.type === "respondent");
              }}
            />
          )}

        {config?.show.includes("DownloadAdminEvents") && (
          <PopupContextMenuLinkButton
            icon="download"
            text={t("Administrator:request_log")}
            onClick={() => setRequestLogModalOpen(true)}
          />
        )}

        {administratorData?.type !== "responscenter" &&
          config?.show.includes("CreateAdminByCopy") && (
            <PopupContextMenuLinkButton
              icon="document-add"
              text={t("Administrator:copy_admin_menu")}
              onClick={() => setCopyModalOpen(true)}
            />
          )}

        {administratorData?.canBeImpersonated &&
          administratorData.type === "respondent" && (
            <PopupContextMenuLinkButton
              icon="switch-horizontal"
              text={t("Administrator:impersonate_alarm_reception")}
              onClick={impersonateReception}
              disabled={isLoading}
            />
          )}

        {administratorData?.canBeImpersonated && (
          <Modal
            trigger={() => (
              <PopupContextMenuLinkButton
                icon="switch-horizontal"
                text={t("Administrator:impersonate")}
              />
            )}
          >
            {(close) => (
              <ImpersonateModal adminData={administratorData} onClose={close} />
            )}
          </Modal>
        )}

        {accountId !== administratorData?.id && (
          <Modal
            trigger={() => (
              <PopupContextMenuLinkButton
                icon="trash"
                text={t("Administrator:delete")}
              />
            )}
          >
            {(close) => (
              <DeleteAdministratorModal
                administratorId={administratorData?.id}
                identity={identity}
                onClose={close}
                onDelete={() => history.push("/adminportal/administrators")}
              />
            )}
          </Modal>
        )}
      </OverlayPanel>
      <PrimeModal
        withHeader
        header={t("Administrator:request_log")}
        isOpen={requestLogModalOpen}
        onClose={() => setRequestLogModalOpen(false)}
      >
        <RequestLogModal
          onClose={() => setRequestLogModalOpen(false)}
          logTarget="Admin"
        />
      </PrimeModal>
      {copyModalOpen && (
        <CopyAdministratorModal
          copyModalOpen={copyModalOpen}
          onClose={() => setCopyModalOpen(false)}
          adminData={administratorData!}
          sellers={sellers}
        />
      )}
    </>
  );
};

export default AdministratorActions;
