import { IProductDetails } from "../../../../../core/api/products/types";
import {
  useObjectForm,
  useObjectFormRef,
} from "../../../../../core/SaveObjectContext/hooks";
import useTranslations from "../../../../../core/i18n/useTranslations";
import { Column, Row, Spacer } from "../../../../../components/Layout/Layout";
import TextInput from "../../../../../ui-lib/components/Inputs/TextInput";
import { Controller } from "react-hook-form";
import Tree, {
  ITreeData,
} from "../../../../../ui-lib/components/Hierarchy/Tree";
import { IResellerType } from "../../../../../core/api/sellers/types";
import { useEffect, useState } from "react";
import { IItemProp } from "../../../../../ui-lib/components/Dropdown/DropdownItem";
import Dropdown from "../../../../../ui-lib/components/Dropdown/Dropdown";
import Divider from "../../../../../ui-lib/components/Divider/Divider";
import NumberInput from "../../../../../ui-lib/components/Inputs/NumberInput";
import Checkbox from "../../../../../ui-lib/components/Checkbox/Checkbox";

export const ProductForm = ({
  productDetails,
  organizations,
  resellers,
  onSubmit,
  isNew = false,
}: {
  productDetails?: IProductDetails;
  organizations: ITreeData[];
  resellers: IResellerType[];
  onSubmit: (values: IProductDetails) => Promise<void>;
  isNew?: boolean;
}) => {
  const t = useTranslations();
  const formRef = useObjectFormRef();

  const [resellersDropdownItems, setResellersDropdownItems] = useState<
    IItemProp[]
  >([]);

  useEffect(() => {
    if (resellers.length) {
      const resellersItems = resellers.map((reseller) => ({
        id: reseller.id,
        name: reseller.name,
        isSelected: reseller.id === productDetails?.sellerId,
      }));
      setResellersDropdownItems(resellersItems);
    }
  }, [resellers]);

  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useObjectForm<IProductDetails>({ defaultValues: productDetails });

  return (
    <form
      id="product-form"
      ref={formRef}
      onSubmit={handleSubmit(async (values) => {
        await onSubmit(values);
      })}
      style={{ width: "100%", maxWidth: "768px" }}
    >
      {!isNew && (
        <>
          <Row>
            <TextInput
              label={t("Common:id")}
              value={`${productDetails?.id}`}
              disabled
            />
          </Row>
          <Spacer size={16} />
        </>
      )}
      <Row align="start">
        <Controller
          name="name"
          control={control}
          rules={{
            required: true,
            minLength: 2,
            maxLength: 60,
          }}
          render={({ field }) => (
            <TextInput
              required
              inputRef={field.ref}
              dataCy="productName"
              label={t("Common:name")}
              value={field.value}
              onChange={field.onChange}
              validationError={errors?.name && t("Errors:input_object_name")}
            />
          )}
        />
        <Spacer size={32} />
        <Controller
          name="identifierRegex"
          control={control}
          rules={{
            required: isNew,
          }}
          render={({ field }) => (
            <TextInput
              required={isNew}
              inputRef={field.ref}
              dataCy="productUdentifierRegex"
              label={t("Reseller:identifier_regex")}
              value={field.value}
              onChange={field.onChange}
              description={t("Reseller:identifier_regex_description")}
              validationError={
                errors?.identifierRegex && t("Errors:input_field_required")
              }
            />
          )}
        />
      </Row>
      <Spacer size={16} />
      <Row>
        <Column align="fill">
          <Controller
            name="sellerId"
            control={control}
            render={() => (
              <Dropdown
                dataCy="sellerSelector"
                onSelectItem={(item) => {
                  const updatedResellersItems = resellersDropdownItems.map(
                    (reseller) => ({
                      ...reseller,
                      isSelected: reseller.id === item.id,
                    })
                  );
                  setResellersDropdownItems(updatedResellersItems);
                  setValue("sellerId", item.id as number);
                }}
                items={resellersDropdownItems}
                title={t("Reseller:reseller")}
                selectedItem={resellersDropdownItems.find(
                  (reseller) => reseller.isSelected
                )}
              />
            )}
          />
        </Column>
        <Spacer size={32} />
        <Column align="fill">
          <Controller
            name="organizationId"
            control={control}
            render={({ field }) => (
              <div>
                <div>
                  <label htmlFor="addobject_organisation">
                    {t("Objects:organisation")}
                  </label>
                </div>
                <Tree
                  placeholder={t("Common:select_organisation")}
                  validationError={
                    errors.organizationId && t("Objects:organisationvalidation")
                  }
                  items={organizations}
                  selectedTreeItem={field.value}
                  onSelectItem={(data: ITreeData) => {
                    if (data?.key !== undefined) {
                      setValue(field.name, data.key as number);
                    }
                  }}
                />
              </div>
            )}
          />
        </Column>
      </Row>
      <Spacer size={16} />
      <Row>
        <Column align="fill">
          <Controller
            name="salesname"
            control={control}
            render={({ field }) => (
              <TextInput
                inputRef={field.ref}
                dataCy="salesname"
                label={t("Reseller:sales_names")}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Column>
        <Spacer size={32} />
        <Column align="fill" />
      </Row>
      <Spacer size={32} />
      <Divider />
      <Spacer size={32} />
      <Controller
        name="requireAction"
        control={control}
        render={({ field }) => (
          <TextInput
            inputRef={field.ref}
            dataCy="requireAction"
            label={t("Reseller:require_action")}
            value={field.value}
            onChange={field.onChange}
          />
        )}
      />
      <Spacer size={16} />
      <Row align="start">
        <Controller
          name="identifierPrefix"
          control={control}
          rules={{
            required: isNew,
          }}
          render={({ field }) => (
            <TextInput
              required={isNew}
              inputRef={field.ref}
              dataCy="identifierPrefix"
              label={t("Reseller:identifier_prefix")}
              value={field.value}
              onChange={field.onChange}
              validationError={
                errors?.identifierPrefix && t("Errors:input_field_required")
              }
            />
          )}
        />
        <Spacer size={32} />
        <Controller
          name="identifierTranslationKey"
          control={control}
          rules={{
            required: isNew,
          }}
          render={({ field }) => (
            <TextInput
              required={isNew}
              inputRef={field.ref}
              dataCy="identifierTranslationKey"
              label={t("Reseller:identifier_translation_key")}
              value={field.value}
              onChange={field.onChange}
              validationError={
                errors?.identifierTranslationKey &&
                t("Errors:input_field_required")
              }
            />
          )}
        />
      </Row>
      <Spacer size={16} />
      <Row>
        <Column align="fill">
          <Controller
            name="defaultPin"
            control={control}
            render={({ field }) => (
              <TextInput
                inputRef={field.ref}
                dataCy="identifierSuffix"
                label={t("Reseller:default_pin")}
                value={field.value}
                onChange={field.onChange}
              />
            )}
          />
        </Column>
        <Spacer size={32} />
        <Column align="fill" />
      </Row>
      <Spacer size={32} />
      <Divider />
      <Spacer size={32} />
      <Row>
        <Controller
          name="geoFenceType"
          control={control}
          render={({ field }) => (
            <NumberInput
              inputRef={field.ref}
              dataCy="geoFenceType"
              label={t("Reseller:geofence_type")}
              value={`${field.value}`}
              onChange={field.onChange}
            />
          )}
        />
        <Spacer size={32} />
        <Controller
          name="geoFenceMaxCount"
          control={control}
          render={({ field }) => (
            <NumberInput
              inputRef={field.ref}
              dataCy="geoFenceType"
              label={t("Reseller:geofence_max_count")}
              value={`${field.value}`}
              onChange={field.onChange}
            />
          )}
        />
      </Row>
      <Spacer size={16} />
      <Row>
        <Controller
          name="geoFenceConfigFunction"
          control={control}
          render={({ field }) => (
            <TextInput
              inputRef={field.ref}
              dataCy="geoFenceConfigFunction"
              label={t("Reseller:geofence_config_function")}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Spacer size={32} />
        <Controller
          name="clearClientUpdatesAfterTime"
          control={control}
          render={({ field }) => (
            <NumberInput
              inputRef={field.ref}
              dataCy="clearClientUpdatesAfterTime"
              label={t("Reseller:clear_client_updates_after_time")}
              value={`${field.value}`}
              onChange={field.onChange}
            />
          )}
        />
      </Row>
      <Spacer size={16} />
      <Row>
        <Controller
          name="lambdaFunction"
          control={control}
          render={({ field }) => (
            <TextInput
              inputRef={field.ref}
              dataCy="lambdaFunction"
              label={t("Reseller:lamba_function")}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Spacer size={32} />
        <Controller
          name="lifecycleEventsLambdaFunction"
          control={control}
          render={({ field }) => (
            <TextInput
              inputRef={field.ref}
              dataCy="lifecycleEventsLambdaFunction"
              label={t("Reseller:life_cycle_events_lambda_function")}
              value={field.value}
              onChange={field.onChange}
            />
          )}
        />
      </Row>
      <Spacer size={32} />
      <Row>
        <Column type="fill" align="start">
          <Controller
            name="responseType"
            control={control}
            render={({ field }) => (
              <Checkbox
                label={t("Reseller:request_type")}
                checked={field.value}
                onChange={field.onChange}
                style={{ paddingLeft: 0 }}
              />
            )}
          />
        </Column>
        <Spacer size={32} />
        <Column type="fill" align="start">
          <Controller
            name="careType"
            control={control}
            render={({ field }) => (
              <Checkbox
                label={t("Reseller:care_type")}
                checked={field.value}
                onChange={field.onChange}
                style={{ paddingLeft: 0 }}
              />
            )}
          />
        </Column>
      </Row>
      <Spacer size={16} />
      <Row>
        <Column type="fill" align="start">
          <Controller
            name="propertyType"
            control={control}
            render={({ field }) => (
              <Checkbox
                label={t("Reseller:property_type")}
                checked={field.value}
                onChange={field.onChange}
                style={{ paddingLeft: 0 }}
              />
            )}
          />
        </Column>
        <Spacer size={32} />
        <Column type="fill" align="start">
          <Controller
            name="requireIdentifier"
            control={control}
            render={({ field }) => (
              <Checkbox
                label={t("Reseller:request_identifier")}
                checked={field.value}
                onChange={field.onChange}
                style={{ paddingLeft: 0 }}
              />
            )}
          />
        </Column>
      </Row>
      <Spacer size={16} />
      <Row>
        <Column type="fill" align="start">
          <Controller
            name="requireImei"
            control={control}
            render={({ field }) => (
              <Checkbox
                label={t("Reseller:request_imei")}
                checked={field.value}
                onChange={field.onChange}
                style={{ paddingLeft: 0 }}
              />
            )}
          />
        </Column>
        <Spacer size={32} />
        <Column type="fill" align="start">
          <Controller
            name="requirePin"
            control={control}
            render={({ field }) => (
              <Checkbox
                label={t("Reseller:require_pin")}
                checked={field.value}
                onChange={field.onChange}
                style={{ paddingLeft: 0 }}
              />
            )}
          />
        </Column>
      </Row>
      <Spacer size={16} />
      <Row>
        <Column type="fill" align="start">
          <Controller
            name="reconfigurable"
            control={control}
            render={({ field }) => (
              <Checkbox
                label={t("Reseller:reconfigurable")}
                checked={field.value}
                onChange={field.onChange}
                style={{ paddingLeft: 0 }}
              />
            )}
          />
        </Column>
        <Spacer size={32} />
        <Column type="fill" align="start">
          <Controller
            name="canBeInStock"
            control={control}
            render={({ field }) => (
              <Checkbox
                label={t("Reseller:can_be_in_stock")}
                checked={field.value}
                onChange={field.onChange}
                style={{ paddingLeft: 0 }}
              />
            )}
          />
        </Column>
      </Row>
      <Spacer size={16} />
      <Row>
        <Column type="fill" align="start">
          <Controller
            name="deletenWhenDisassociate"
            control={control}
            render={({ field }) => (
              <Checkbox
                label={t("Reseller:delete_when_disassociate")}
                checked={field.value}
                onChange={field.onChange}
                style={{ paddingLeft: 0 }}
              />
            )}
          />
        </Column>
        <Spacer size={32} />
        <Column type="fill" align="start">
          <Controller
            name="canRequestLog"
            control={control}
            render={({ field }) => (
              <Checkbox
                label={t("Reseller:can_request_log")}
                checked={field.value}
                onChange={field.onChange}
                style={{ paddingLeft: 0 }}
              />
            )}
          />
        </Column>
      </Row>
      <Spacer size={16} />
      <Row>
        <Column type="fill" align="start">
          <Controller
            name="canDownloadApp"
            control={control}
            render={({ field }) => (
              <Checkbox
                label={t("Reseller:can_download_app")}
                checked={field.value}
                onChange={field.onChange}
                style={{ paddingLeft: 0 }}
              />
            )}
          />
        </Column>
        <Spacer size={32} />
        <Column type="fill" align="start">
          <Controller
            name="canFirmwareUpdate"
            control={control}
            render={({ field }) => (
              <Checkbox
                label={t("Reseller:can_firmware_update")}
                checked={field.value}
                onChange={field.onChange}
                style={{ paddingLeft: 0 }}
              />
            )}
          />
        </Column>
      </Row>
      <Spacer size={16} />
      <Row>
        <Column type="fill" align="start">
          <Controller
            name="canDownload"
            control={control}
            render={({ field }) => (
              <Checkbox
                label={t("Reseller:can_download")}
                checked={field.value}
                onChange={field.onChange}
                style={{ paddingLeft: 0 }}
              />
            )}
          />
        </Column>
        <Spacer size={32} />
        <Column type="fill" align="start">
          <Controller
            name="imeiFromPhonenumber"
            control={control}
            render={({ field }) => (
              <Checkbox
                label={t("Reseller:imei_from_phone_number")}
                checked={field.value}
                onChange={field.onChange}
                style={{ paddingLeft: 0 }}
              />
            )}
          />
        </Column>
      </Row>
      <Spacer size={16} />
      <Row>
        <Column type="fill" align="start">
          <Controller
            name="supportMultipleTransmitters"
            control={control}
            render={({ field }) => (
              <Checkbox
                label={t("Reseller:support_multiple_transmitters")}
                checked={field.value}
                onChange={field.onChange}
                style={{ paddingLeft: 0 }}
              />
            )}
          />
        </Column>
        <Spacer size={32} />
        <Column type="fill" align="start">
          <Controller
            name="usingCoreApi"
            control={control}
            render={({ field }) => (
              <Checkbox
                label={t("Reseller:using_core_api")}
                checked={field.value}
                onChange={field.onChange}
                style={{ paddingLeft: 0 }}
              />
            )}
          />
        </Column>
      </Row>
      <Spacer size={16} />
      <Row>
        <Column type="fill" align="start">
          <Controller
            name="webHook"
            control={control}
            render={({ field }) => (
              <Checkbox
                label={t("Reseller:using_webhook")}
                checked={field.value}
                onChange={field.onChange}
                style={{ paddingLeft: 0 }}
              />
            )}
          />
        </Column>
        <Spacer size={32} />
        <Column type="fill" />
      </Row>
    </form>
  );
};
