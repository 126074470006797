import {
  Dispatch,
  MutableRefObject,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { Column, Row } from "../../components/Layout/Layout";
import { useSubNav } from "../../components/LoggedinPage/SubNavContext";
import DeleteTransmitterModal from "../../components/Transmitters/DeleteTransmitterModal";
import { TransmitterModel } from "../../core/api/transmitters/types";
import { useStoredTableState } from "../../core/hooks/filters/useStoredTableState";
import useTranslations from "../../core/i18n/useTranslations";
import { useSaveObject } from "../../core/SaveObjectContext/hooks";
import { SaveObjectProvider } from "../../core/SaveObjectContext/SaveObjectContext";
import useUser from "../../core/user/useUser";
import Button from "../../ui-lib/components/Button/Button";
import Divider from "../../ui-lib/components/Divider/Divider";
import LoadingSpinner from "../../ui-lib/components/Loading/LoadingSpinner";
import PopupContextMenu from "../../ui-lib/components/Menus/PopupContextMenu/PopupContextMenu";
import { PopupContextMenuLinkButton } from "../../ui-lib/components/Menus/PopupContextMenu/PopupContextMenuLinkButton";
import PageHeader from "../../ui-lib/components/PageHeader/PageHeader";
import styles from "../Layout.module.css";
import { PAGE_ID as parentId } from "./Transmitters";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import { SharedTransmitterProvider } from "./SharedTransmitterContext/SharedTransmitterContext";
import useSharedTransmitter from "./SharedTransmitterContext/useSharedTransmitter";
import { TransmitterGeneral } from "./TransmitterDetails/TransmitterGeneral";
import { TransmitterLogs } from "./TransmitterDetails/TransmitterLogs";

const parentPath = "/adminportal/transmitters";

const SubItems = (url: string, baseUrl?: string) => {
  const user = useUser();
  const show = user.config?.show ?? [];

  const sub = [
    {
      key: "Menu:Transmitter_details",
      to: baseUrl ?? url,
    },
  ];

  const access = {
    Logs: show.includes("TransmitterUpdateLogs"),
  };

  if (access.Logs) {
    sub.push({
      key: "Menu:Transmitter_logs",
      to: `${baseUrl ?? url}/logs`,
    });
  }

  return {
    sub,
    access,
  };
};

const TransmitterDetailsWrapper = ({
  data,
  children,
  setCheckChanges,
  editTransmitterRef,
}: {
  data?: TransmitterModel;
  children: ReactNode;
  setCheckChanges: Dispatch<boolean>;
  editTransmitterRef?: MutableRefObject<any>;
}) => {
  const t = useTranslations();
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const user = useUser();
  const { transmitterData, fetchTransmitter } = useSharedTransmitter();
  const { canSave, isDirty, isSaving, onSave } = useSaveObject();
  const tableState = useStoredTableState(parentId);
  const backUrl = tableState ? `${parentPath}${tableState}` : parentPath;

  const [deletionModalOpen, setDeletionModalOpen] = useState<boolean>(false);

  return (
    <Row align="start" style={{ width: "100%" }}>
      <Divider vertical />
      <Column
        style={{ width: "100%", height: "100vh", overflow: "hidden" }}
        type="top"
      >
        <PageHeader title={data?.identifier} icon="rss">
          {canSave && (
            <Button
              text={t("Common:save")}
              onClick={async () => {
                onSave();
                const res = await editTransmitterRef?.current?.submitForm();
                if (res?.status) {
                  notify({
                    message: t("Transmitters:transmitter_edit_success"),
                  });
                  fetchTransmitter();
                }
              }}
              disabled={!isDirty || isSaving}
            />
          )}
          {user.config?.show.includes("DeleteTransmitters") &&
            !transmitterData?.userId && (
              <PopupContextMenu
                className="mt-8"
                key="object_details_actions"
                position="right bottom"
                nested
                trigger={() => (
                  <div>
                    <Button
                      type="button"
                      variant="secondary"
                      image="dots-horizontal"
                      text={t("Common:other")}
                    />
                  </div>
                )}
              >
                <PopupContextMenuLinkButton
                  icon="trash"
                  text={t("Transmitters:button_delete_transmitter")}
                  onClick={() => {
                    setDeletionModalOpen(true);
                  }}
                />
                <DeleteTransmitterModal
                  onClose={() => {
                    setDeletionModalOpen(false);
                  }}
                  text={t("Transmitters:delete_transmitter_confirm")}
                  isOpen={deletionModalOpen}
                  modalTitle={t("Transmitters:delete_transmitter")}
                  name={transmitterData?.identifier ?? ""}
                  onDelete={(status: boolean) => {
                    setCheckChanges(false);
                    if (status) {
                      history.replace(backUrl);
                    }
                  }}
                  transmitterIds={[id]}
                />
              </PopupContextMenu>
            )}
        </PageHeader>
        <Divider />
        <div
          className={styles.wrapper}
          style={{
            height: "auto",
            overflowY: "auto",
          }}
        >
          <Column
            className={styles.content}
            align="fill"
            style={{ padding: "32px", position: "relative" }}
          >
            {children}
          </Column>
        </div>
      </Column>
    </Row>
  );
};

const TransmitterDetails = ({
  setCheckChanges,
  access,
}: {
  setCheckChanges: Dispatch<boolean>;
  access: {
    Logs: boolean;
  };
}) => {
  const { path } = useRouteMatch();
  const { transmitterData } = useSharedTransmitter();
  const { id } = useParams<{ id: string }>();
  const editTransmitterRef = useRef<any>(null);

  return (
    <Switch>
      {access.Logs && (
        <Route path={`${path}/logs`}>
          <TransmitterDetailsWrapper
            data={transmitterData}
            setCheckChanges={setCheckChanges}
          >
            <TransmitterLogs transmitterId={id} />
          </TransmitterDetailsWrapper>
        </Route>
      )}
      <Route path={path}>
        <TransmitterDetailsWrapper
          data={transmitterData}
          setCheckChanges={setCheckChanges}
          editTransmitterRef={editTransmitterRef}
        >
          <TransmitterGeneral editTransmitterRef={editTransmitterRef} />
        </TransmitterDetailsWrapper>
      </Route>
    </Switch>
  );
};

const TransmitterDetailsLoader = ({
  setCheckChanges,
}: {
  setCheckChanges: (state: boolean) => void;
}) => {
  const t = useTranslations();
  const history = useHistory();
  const { url } = useRouteMatch();
  const { setSubNav } = useSubNav();
  const tableState = useStoredTableState(parentId);
  const { isLoading, isError } = useSharedTransmitter();

  const { sub, access } = SubItems(url);

  useEffect(() => {
    if (isError) {
      history.replace(parentPath);
    }
  }, [isError]);

  useEffect(() => {
    const nav = {
      menu: sub,
      backUrl: tableState ? `${parentPath}${tableState}` : parentPath,
      backText: t("Menu:transmitter"),
    };
    setSubNav(nav);

    return () => {
      setSubNav(undefined);
    };
  }, []);

  if (isLoading) {
    return <LoadingSpinner theme="primary" />;
  }

  return (
    <TransmitterDetails access={access} setCheckChanges={setCheckChanges} />
  );
};

const TransmitterDetailsContainer = () => {
  const { id } = useParams<{ id: string }>();
  const [checkChanges, setCheckChanges] = useState(true);
  return (
    <SharedTransmitterProvider transmitterId={id}>
      <SaveObjectProvider checkChanges={checkChanges}>
        <TransmitterDetailsLoader setCheckChanges={setCheckChanges} />
      </SaveObjectProvider>
    </SharedTransmitterProvider>
  );
};
export default TransmitterDetailsContainer;
