import { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import useTranslations from "../../core/i18n/useTranslations";
import { notify } from "../../ui-lib/components/Alerts/Toast";
import { notifyError } from "../../components/Errors/ErrorAlert";
import { AlertItemProps } from "../../ui-lib/components/Alerts/AlertItem";
import { format } from "../../core/utils/strings";
import { Column, Row, Spacer } from "../../components/Layout/Layout";
import Alert from "../../ui-lib/components/Alerts/Alert";
import PasswordInput from "../../ui-lib/components/Inputs/PasswordInput";
import { PasswordFormValues } from "../Administrators/AdministratorDetails/General/ChangePasswordModal";
import { PasswordConstraints } from "../../core/api/administrators/types";
import Button from "../../ui-lib/components/Button/Button";
import authApi from "../../core/api/auth";
import {
  validateForbiddenChars,
  validateLowerCase,
  validateNumberCount,
  validateUpperCase,
} from "../../core/helpers/validation";
import { getPasswordErrorMessage } from "../../core/helpers/helpers";

export const ResetPasswordContent = ({
  onPasswordReset,
  data,
  resetPasswordToken,
}: {
  onPasswordReset: () => void;
  data: PasswordConstraints;
  resetPasswordToken?: string;
}) => {
  const t = useTranslations();

  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<PasswordFormValues>();

  const onSubmit = async (values: PasswordFormValues) => {
    setLoading(true);
    try {
      const dataToSend = {
        token: resetPasswordToken!,
        password: values.password,
      };
      await authApi.resetPassword(dataToSend);

      notify({
        message: t("Administrator:change_password_success"),
      });

      onPasswordReset();
    } catch (error: any) {
      notifyError({ error, t });
    }
    setLoading(false);
  };

  const passwordConstrains: AlertItemProps[] = [];
  if (data) {
    if (data.minLength !== 0)
      passwordConstrains.push({
        title: format(
          t("Administrator:password_constraints_minimum_length"),
          data.minLength
        ),
        key: "min-length",
      });
    if (data.minNumbers !== 0)
      passwordConstrains.push({
        title: format(
          t("Administrator:password_constraints_minimum_digits"),
          data.minNumbers
        ),
        key: "min-number",
      });
    if (data.minUpperCase !== 0)
      passwordConstrains.push({
        title: format(
          t("Administrator:password_constraints_minimum_uppercase"),
          data.minUpperCase
        ),
        key: "min-uppercase",
      });

    if (data.minLowerCase !== 0)
      passwordConstrains.push({
        title: format(
          t("Administrator:password_constraints_minimum_lowercase"),
          data.minLowerCase
        ),
        key: "min-lowercase",
      });

    if (data.forbiddenChars !== "")
      passwordConstrains.push({
        title: format(
          t("Administrator:password_constraints_forbidden_chars"),
          data.forbiddenChars
        ),
        key: "forbidden-chars",
      });
  }
  return (
    <form
      onSubmit={(e) => {
        // To stop main form to submit
        e.preventDefault();
        e.stopPropagation();

        handleSubmit(onSubmit)(e);
      }}
      style={{ width: "100%" }}
    >
      <p style={{ color: "var(--Grey-600)" }}>
        {t("Administrator:change_password_description")}
      </p>
      <Alert
        title={t("Administrator:input_password_constraints")}
        variant="info"
        withDefaultIcon
        items={passwordConstrains}
      />

      <Spacer size={16} />

      <Row align="start">
        <Column style={{ width: "100%" }}>
          <Controller
            name="password"
            control={control}
            rules={{
              required: true,
              minLength: data.minLength,
              validate: {
                lowerCase: (v) => validateLowerCase(v, data),
                upperCase: (v) => validateUpperCase(v, data),
                countNumbers: (v) => validateNumberCount(v, data),
                forbiddenChars: (v) => validateForbiddenChars(v, data),
              },
            }}
            render={({ field }) => (
              <PasswordInput
                required
                inputRef={field.ref}
                label={t("Administrator:change_password_input_new")}
                placeholder={t(
                  "Administrator:change_password_input_new_placeholder"
                )}
                value={field.value}
                onChange={field.onChange}
                validationError={
                  errors.password &&
                  getPasswordErrorMessage(errors.password.type, data)
                }
              />
            )}
          />
        </Column>
        <Spacer size={32} />
        <Column style={{ width: "100%" }}>
          <Controller
            name="passwordRepeat"
            control={control}
            rules={{
              required: true,
              validate: (v) => watch("password") === v,
            }}
            render={({ field }) => (
              <PasswordInput
                required
                inputRef={field.ref}
                label={t("Administrator:change_password_input_new_repeat")}
                placeholder={t(
                  "Administrator:change_password_input_new_placeholder"
                )}
                value={field.value}
                onChange={field.onChange}
                validationError={
                  errors.passwordRepeat && t("Errors:password_mismatch")
                }
              />
            )}
          />
        </Column>
      </Row>

      <Spacer size={32} />

      <Row type="left">
        <Button
          type="submit"
          loading={loading}
          variant="primary"
          text={t("Administrator:change_password")}
        />
      </Row>

      <Spacer size={16} />
    </form>
  );
};
