import {
  Route,
  Switch,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { ReactNode, useEffect } from "react";
import { observer } from "mobx-react";
import useUser from "../../../core/user/useUser";
import { Column, Row } from "../../../components/Layout/Layout";
import PageHeader from "../../../ui-lib/components/PageHeader/PageHeader";
import Divider from "../../../ui-lib/components/Divider/Divider";
import styles from "../../Layout.module.css";
import LoadingSpinner from "../../../ui-lib/components/Loading/LoadingSpinner";
import useTranslations from "../../../core/i18n/useTranslations";
import { useSubNav } from "../../../components/LoggedinPage/SubNavContext";
import { useStoredTableState } from "../../../core/hooks/filters/useStoredTableState";
import { SaveObjectProvider } from "../../../core/SaveObjectContext/SaveObjectContext";
import useSharedIdentity from "./SharedIdentityContext/useSharedIdentity";
import { SharedIdentityProvider } from "./SharedIdentityContext/SharedIdentityContext";
import { IIdentity } from "../../../core/api/identities/types";
import { IdentityGeneral } from "./General/IdentityGeneral";
import { RolesPermissions } from "./RolesPermissions/RolesPermissions";
import IdentityActions from "./IdentityActions";

const parentPath = "/adminportal/administrators";

const subMenuItems = (url: string) => [
  { key: "Menu:general", to: url },
  { key: "Menu:Roles_Permissions", to: `${url}/permissions` },
];

const IdentityDetailsWrapper = ({
  data,
  children,
}: {
  data: IIdentity;
  children: ReactNode;
}) => {
  const user = useUser();
  return (
    <Row align="start" style={{ width: "100%" }}>
      <Column
        style={{ width: "100%", height: "100vh", overflow: "hidden" }}
        type="top"
      >
        <PageHeader title={data.upn} icon="user">
          {user.config?.show.includes("DownloadAdminEvents") && (
            <IdentityActions />
          )}
        </PageHeader>
        <Divider />
        <Column
          className={styles.wrapper}
          style={{
            height: "auto",
            overflowY: "auto",
          }}
        >
          <Column
            className={styles.content}
            type="top"
            align="start"
            style={{ padding: "32px" }}
          >
            {children}
          </Column>
        </Column>
      </Column>
    </Row>
  );
};

const IdentityDetails = ({ data }: { data: IIdentity }) => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route path={`${path}/permissions`}>
        <IdentityDetailsWrapper data={data}>
          <RolesPermissions data={data} />
        </IdentityDetailsWrapper>
      </Route>
      <Route path={path}>
        <IdentityDetailsWrapper data={data}>
          <IdentityGeneral data={data} />
        </IdentityDetailsWrapper>
      </Route>
    </Switch>
  );
};

const IdentityDetailsLoader = () => {
  const history = useHistory();
  const { data, isError } = useSharedIdentity();

  useEffect(() => {
    if (isError) {
      history.replace(parentPath);
    }
  }, [isError]);

  if (!data) {
    return <LoadingSpinner theme="primary" />;
  }

  return <IdentityDetails data={data} />;
};

const IdentityDetailsContainer = () => {
  const t = useTranslations();

  const { id } = useParams<{ id: string }>();
  const { url } = useRouteMatch();
  const { setSubNav } = useSubNav();
  const tableState = useStoredTableState(parentPath);
  const backUrl = tableState ? `${parentPath}${tableState}` : parentPath;

  useEffect(() => {
    const nav = {
      menu: subMenuItems(url),
      backUrl,
      backText: t("Menu:administrators"),
    };

    setSubNav(nav);

    return () => {
      setSubNav(undefined);
    };
  }, []);

  return (
    <SharedIdentityProvider id={id}>
      <SaveObjectProvider>
        <IdentityDetailsLoader />
      </SaveObjectProvider>
    </SharedIdentityProvider>
  );
};

export default observer(IdentityDetailsContainer);
