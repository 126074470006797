import React, { ReactNode } from "react";
import { Dialog } from "primereact/dialog";
import { Row, Spacer } from "../../../components/Layout/Layout";
import Icon from "../Icon";
import Button, { ButtonType } from "../Button/Button";

import styles from "./PrimeModal.module.css";

interface FooterButton {
  text: string;
  onClick?: () => Promise<void> | void;
  variant?: "primary" | "destructive" | "secondary";
  disabled?: boolean;
  image?: string;
  hidden?: boolean;
  type?: ButtonType;
  form?: string;
}

const PrimeModal = ({
  children,
  isOpen,
  onClose,
  withHeader,
  header,
  withFooter,
  style,
  contentStyle,
  modal = true,
  submitBtn,
  cancelBtn,
  loading,
  customFooter,
  className,
  contentClassName,
  position,
}: {
  children: ReactNode;
  isOpen: boolean;
  onClose(): void;
  withHeader?: boolean;
  header?: string | ReactNode;
  style?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  modal?: boolean;
  withFooter?: boolean;
  submitBtn?: FooterButton;
  cancelBtn?: FooterButton;
  loading?: boolean;
  customFooter?: ReactNode;
  className?: string;
  contentClassName?: string;
  position?:
    | "center"
    | "top"
    | "bottom"
    | "left"
    | "right"
    | "top-left"
    | "top-right"
    | "bottom-left"
    | "bottom-right";
}) => {
  const renderFooter = () => {
    if (customFooter) return customFooter;
    if (withFooter) {
      return (
        <Row type="left">
          {!submitBtn?.hidden && (
            <Button
              type={submitBtn?.type}
              form={submitBtn?.form}
              text={submitBtn?.text}
              image={submitBtn?.image}
              variant={submitBtn?.variant ?? "primary"}
              onClick={submitBtn?.onClick}
              disabled={loading || submitBtn?.disabled}
              loading={loading}
            />
          )}
          {cancelBtn && (
            <>
              {!submitBtn?.hidden && <Spacer size={8} />}
              <Button
                text={cancelBtn?.text}
                variant={cancelBtn?.variant ?? "secondary"}
                onClick={cancelBtn?.onClick}
                disabled={loading}
                image={cancelBtn.image}
              />
            </>
          )}
        </Row>
      );
    }
  };

  return (
    <Dialog
      closeOnEscape={false}
      draggable={false}
      visible={isOpen}
      onHide={onClose}
      showHeader={withHeader}
      header={header}
      modal={modal}
      contentStyle={{ padding: 0 }}
      contentClassName={contentClassName}
      style={style}
      resizable={false}
      footer={renderFooter()}
      closeIcon={<Icon name="x" />}
      className={className}
      position={position}
    >
      <div className={styles.primeWrapper} style={contentStyle}>
        {children}
      </div>
    </Dialog>
  );
};

export default PrimeModal;
