import { useEffect, useRef, useState } from "react";
import classNames from "classnames";
import { Column, Row, Spacer } from "../../../../components/Layout/Layout";
import { ResponseCenterAlarmCodeResponse as RowData } from "../../../../core/api/responsecenters/types";
import useTranslations from "../../../../core/i18n/useTranslations";
import Button from "../../../../ui-lib/components/Button/Button";
import Table, { TableColumn } from "../../../../ui-lib/components/Tables/Table";
import TableCell from "../../../../ui-lib/components/Tables/TableCell";
import TableRowSelectionPopup, {
  TableRowSelectionPopupActions,
} from "../../../../ui-lib/components/Tables/TableRowSelectionPopup";
import { DeleteAlarmCodeModal } from "./DeleteAlarmCodeModal";
import layoutStyles from "../../../Layout.module.css";
import { getResponseCenterAlarmCodes } from "../../../../core/api/responsecenters/alarmcodes";
import { notifyError } from "../../../../components/Errors/ErrorAlert";
import useUser from "../../../../core/user/useUser";
import LoadingSpinner from "../../../../ui-lib/components/Loading/LoadingSpinner";

import tableStyles from "../../../../ui-lib/components/Tables/Table.module.css";

const tableId = "arc-table-codes";

const columns = (
  t: (key: string | string[]) => string,
  onStartEdit?: (rowData: RowData) => void
): TableColumn<RowData>[] => [
  {
    key: "alarm-code",
    header: t("AlarmReceptions:alarm_code_table_column_alarm_code"),
    fieldTemplate: (rowData) => (
      <button
        type="button"
        onClick={() => onStartEdit?.(rowData)}
        className={classNames([tableStyles.tableLink, tableStyles.linkHover])}
      >
        {rowData.alarmCode}
      </button>
    ),
  },
  {
    key: "text",
    header: t("AlarmReceptions:alarm_code_table_column_text"),
    fieldTemplate: (rowData) => (
      <TableCell
        value={t([
          `CommonEnum:AlarmCode${rowData.alarmCode}`,
          `Common:no_value`,
        ])}
      />
    ),
  },
  {
    key: "forced-text",
    header: t("AlarmReceptions:alarm_code_table_column_forced_text"),
    fieldTemplate: (rowData) => <TableCell value={rowData.text ?? ""} />,
  },
  {
    key: "priority",
    header: t("AlarmReceptions:alarm_code_table_column_priority"),
    fieldTemplate: (rowData) => <TableCell value={`${rowData.priority}`} />,
  },
];

export const AlarmCodeTable = ({
  responseCenterId,
  onStartEdit,
  fetchFlag,
}: {
  responseCenterId: string | number;
  onStartEdit: (alarmHandling: RowData) => void;
  fetchFlag: boolean;
}) => {
  const t = useTranslations();
  const { authenticatedRequest } = useUser();
  const selectionPopupRef =
    useRef<TableRowSelectionPopupActions<RowData>>(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [upForDelete, setUpForDelete] = useState<number[]>([]);
  const [pageFilter, setPageFilter] = useState({
    page: 1,
    pageSize: parseInt(localStorage.getItem(tableId) || "", 10) || 10,
  });
  const [tableData, setTableData] = useState<any[]>([]);
  const [items, setItems] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchAlarmCodes = async () => {
    try {
      setIsLoading(true);
      const result = await getResponseCenterAlarmCodes(
        responseCenterId,
        authenticatedRequest
      );
      setTableData(result.data);
    } catch (error: any) {
      notifyError({
        error,
        t,
      });
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAlarmCodes();
    setPageFilter({
      page: 1,
      pageSize: parseInt(localStorage.getItem(tableId) || "", 10) || 10,
    });
  }, [fetchFlag]);

  useEffect(() => {
    if (tableData.length) {
      setItems(
        tableData.slice(
          (pageFilter.page - 1) * pageFilter.pageSize,
          (pageFilter.page - 1) * pageFilter.pageSize + pageFilter.pageSize
        )
      );
    } else {
      setItems([]);
    }
  }, [tableData, pageFilter]);

  return isLoading ? (
    <LoadingSpinner theme="primary" />
  ) : (
    <>
      <Column className={layoutStyles.tableContainer} type="top">
        <Table<RowData>
          columns={columns(t, onStartEdit)}
          items={items}
          withRowSelection
          showRowHover
          rowActionsFixed
          withPagination
          withLazyLoading
          hideEmptyMessage
          paginatedItems={{
            items,
            pagination: {
              offset: pageFilter.pageSize * (pageFilter.page - 1),
              limit: pageFilter.pageSize,
              total: tableData.length,
            },
          }}
          onPageChange={(nextPage) => {
            const page = Math.floor(nextPage.offset / nextPage.limit);

            if (!Number.isNaN(page) && nextPage.limit) {
              if (pageFilter.pageSize !== nextPage.limit) {
                localStorage.setItem(tableId, `${nextPage.limit}`);
              }
              setPageFilter({
                page: page + 1,
                pageSize: nextPage.limit,
              });
            }
          }}
          rowActions={[
            {
              icon: "pencil-alt",
              text: t("Common:label_edit"),
              onClick: (rowData) => onStartEdit(rowData),
            },
            {
              icon: "trash",
              text: t("Common:delete"),
              iconVariant: "secondary",
              onClick: (rowData) => {
                setUpForDelete([rowData.id]);
                setDeleteOpen(true);
              },
            },
          ]}
          rowActionsColWidth={80}
          onRowSelectionChange={(selectedItems) => {
            const filtered = selectedItems.filter(
              (i) => !!tableData.find((row) => row.id === i.id)
            );
            selectionPopupRef.current?.open(filtered);
          }}
        />
      </Column>

      <TableRowSelectionPopup
        ref={selectionPopupRef}
        renderBody={(selectedItems) => (
          <Row>
            <Spacer size={16} />
            <Button
              variant="destructive"
              text={`${t("Common:delete_selection")} (${selectedItems.length})`}
              onClick={() => {
                setUpForDelete(selectedItems.map((i) => i.id));
                setDeleteOpen(true);
              }}
            />
          </Row>
        )}
      />

      <DeleteAlarmCodeModal
        responseCenterId={responseCenterId}
        isOpen={deleteOpen}
        ids={upForDelete}
        onClose={() => setDeleteOpen(false)}
        onDelete={() => {
          setDeleteOpen(false);
          selectionPopupRef.current?.close(true);
          fetchAlarmCodes();
        }}
      />
    </>
  );
};
