import { RespondentGroupResponse } from "../../../../../core/api/responsecenters/types";
import useTranslations from "../../../../../core/i18n/useTranslations";
import Dropdown from "../../../../../ui-lib/components/Dropdown/Dropdown";

export const RespondentGroupControl = ({
  title,
  selected,
  onSelect,
  respondentGroups,
  validationError,
  withClearItem,
  disabled,
  required,
}: {
  title?: string;
  selected?: number | null;
  onSelect: (id?: number | null) => void;
  respondentGroups: RespondentGroupResponse[];
  validationError?: string;
  withClearItem?: boolean;
  disabled?: boolean;
  required?: boolean;
}) => {
  const t = useTranslations();

  const dropdownItems = respondentGroups.map((respondentGroup) => ({
    id: respondentGroup.id,
    name: respondentGroup.description,
    isSelected: respondentGroup.id === selected,
  }));

  return (
    <Dropdown
      required={required}
      title={title}
      disabled={disabled}
      placeholder={t(
        "AlarmReceptions:alarm_handling_input_respondent_group_placeholder"
      )}
      selectedItem={dropdownItems.find((i) => i.isSelected)}
      items={dropdownItems}
      onSelectItem={(item) => onSelect(item.id as number)}
      validationError={validationError}
      withClearItem={withClearItem}
      bodyWidth={350}
    />
  );
};
